import {ReviewManual} from "../model/ReviewManual";
import {
    action,
    computed,
    makeObservable,
    observable,
    runInAction,
} from "mobx";
import {ReviewSM2Store} from "./ReviewSM2Store";
import {ReviewManualStore} from "./ReviewManualStore";
import {ActionType, IUIError, NewUIErrorV2} from "service/cartaError";
import {ReviewSM2} from "model/ReviewSM2";
import {
    EnumReviewCardKindDTO,
    ListOptionsRequestDTO,
    UUID_DTO,
} from "proto/utils_pb";
import {NewUUID} from "utils/utils";
import {IReview, IReviewCard} from "model/Review";
import {Card} from "model/Card";
import {ICard} from "model/CardComposite";
import {ReviewSM2FilterConfigDTO} from "../proto/reviewSM2_pb";
import {ReviewKindEnumDTO, ReviewManualOptionsDTO} from "../proto/reviewManual_pb";
import {CardStore} from "./CardStore";
import {EntityKind} from "../model/BaseModel";
import {Err, Ok, Result} from "../utils/result";
import {ReviewSM2Card} from "../model/ReviewSM2Card";
import {getUserId} from "../service/AuthService";
import {DEFAULT_LIMIT} from "consts";

export function DefaultSM2FilterConfig(): ReviewSM2FilterConfigDTO {
    let x = new ReviewSM2FilterConfigDTO();
    x.setId(new UUID_DTO().setValue(NewUUID()));
    x.setUserId(new UUID_DTO().setValue(getUserId()));
    x.setLimit(10);
    x.setNewnessfactor(20);
    x.setIstemplate(false);
    x.setCardkind(EnumReviewCardKindDTO.REVIEWCARDKIND_STANDARD);
    
    return x;
}

export class OngoingReviewCardMetadata {
    reviewId: string;
    reviewCard: IReviewCard;
    currIndex: number;
    finalIndex: number;
    
    constructor(
        reviewId: string,
        reviewCard: IReviewCard,
        currIndex: number,
        finalIndex: number
    ) {
        this.reviewId = reviewId;
        this.reviewCard = reviewCard;
        this.currIndex = currIndex;
        this.finalIndex = finalIndex;
    }
    
    get isFinalCard(): boolean {
        return this.currIndex === this.finalIndex;
    }
    
    get isComplete(): boolean {
        return this.currIndex >= this.finalIndex;
    }
}

export class ReviewPageStore {
    sm2Store: ReviewSM2Store;
    manualStore: ReviewManualStore;
    cardStore: CardStore;
    
    selectedReview?: [id: string, kind: ReviewKindEnumDTO];
    ongoingReview?: [id: string, kind: ReviewKindEnumDTO];
    ongoingReviewCardIndex: number = -1;
    
    // public reviews: ObservableMap<string, IReview> = new ObservableMap<
    // 	string,
    // 	IReview
    // >();
    // ongoingReviewSM2CardsMap: ObservableMap<string, ReviewSM2Card[]> =
    // 	new ObservableMap<string, ReviewSM2Card[]>();
    // ongoingReviewManualCardsMap: ObservableMap<string, ReviewManualCard[]> =
    // 	new ObservableMap<string, ReviewManualCard[]>();
    // reviewSM2StatsMap: ObservableMap<string, ReviewSM2Stat> =
    // 	new ObservableMap<string, ReviewSM2Card[]>();
    // reviewManualStatsMap: ObservableMap<string, ReviewManualStat> =
    // 	new ObservableMap<string, ReviewManualCard[]>();
    
    constructor(
        sm2Store: ReviewSM2Store,
        manualStore: ReviewManualStore,
        cardStore: CardStore
    ) {
        makeObservable(this, {
            // reviews: observable,
            selectedReview: observable,
            ongoingReview: observable,
            ongoingReviewCardIndex: observable,
            // ongoingReviewSM2CardsMap: observable,
            // ongoingReviewManualCardsMap: observable,
            
            SetOngoingReview: action,
            SetSelectedReview: action,
            SetSelectedManualReviewAndFetchItsCards: action,
            SetSelectedSM2ReviewAndFetchItsCards: action,
            FetchCardsForReview: action,
            StartReview: action,
            ResumeManualReview: action,
            ResumeSM2Review: action,
            GetOrFetchReview: action,
            FetchReviews: action,
            UpdateCard: action,
            DeleteReview: action,
            
            GetOngoingReviewCards: computed,
            SelectedReview: computed,
            OngoingReview: computed,
            SM2Reviews: computed,
            ManualReviews: computed,
        });
        
        this.sm2Store = sm2Store;
        this.manualStore = manualStore;
        this.cardStore = cardStore;
    }
    
    get SM2Reviews(): ReviewSM2[] {
        return this.sm2Store.GetList;
    }
    
    get ManualReviews(): ReviewManual[] {
        return this.manualStore.GetList;
    }
    
    get GetOngoingReviewCards(): IReviewCard[] | undefined {
        const ongoing = this.OngoingReview;
        if (ongoing === undefined) {
            return undefined;
        }
        
        const kind = ongoing.kind;
        
        if (kind === ReviewKindEnumDTO.MANUAL) {
            if (!this.ongoingReview) {
                return undefined;
            }
            
            const [id, _] = this.ongoingReview;
            return this.manualStore.reviewCardMap.get(id);
        }
        
        if (kind === ReviewKindEnumDTO.SM2) {
            if (!this.ongoingReview) {
                return undefined;
            }
            
            return this.sm2Store.tempCreatedReviewCards;
        }
    }
    
    // SetOngoingReviewCardIndex = (index: number) => {
    // 	const ongoing = this.OngoingReview;
    // 	if (ongoing === undefined) {
    // 		return undefined
    // 	}
    //
    // 	const kind = ongoing.kind
    // 	switch (kind) {
    // 		case ReviewKindEnumDTO.MANUAL:
    // 			let ans = this.manualStore.reviewCardMap.get(ongoing.id)
    // 			if (ans) {
    // 				if (index >= 0 && index < ans.length) {
    // 					this.ongoingReviewCardIndex = index;
    // 				}
    // 			}
    // 			return;
    // 		case ReviewKindEnumDTO.SM2:
    // 			this.ongoingReviewCardIndex = index;
    // 			return;
    //
    // 	}
    // }
    
    public GetSM2CardsNextBatchAndSetOngoing = async (): Promise<
        Result<ReviewSM2Card[], IUIError>
    > => {
        const ongoing = this.OngoingReview;
        if (ongoing === undefined) {
            return Promise.reject(
                NewUIErrorV2(
                    ActionType.SetOngoingReview,
                    EntityKind.ReviewSM2,
                    `cannot batch next sm2 cards as there is no ongoing review`
                )
            );
        }
        
        const config = DefaultSM2FilterConfig();
        if (this.sm2Store.tempCreatedConfig === undefined) {
            this.sm2Store.tempCreatedConfig = config;
        }
        
        
        return this.sm2Store
            .ListCardsForNewReview(ongoing.id, config)
            .then((res) => {
                if (res.ok) {
                    
                    this.sm2Store.tempCreatedReviewCards = res.value;
                    return res;
                }
                return res;
            });
    };
    
    public GetOngoingReviewCardByIndex = (
        index: number
    ): OngoingReviewCardMetadata | undefined => {
        if (!this.ongoingReview) {
            return undefined;
        }
        
        const [id, kind] = this.ongoingReview;
        
        if (kind === ReviewKindEnumDTO.MANUAL) {
            const reviewCards = this.manualStore.reviewCardMap.get(id);
            
            
            
            if (reviewCards) {
                if (index >= 0 && index < reviewCards.length) {
                    return new OngoingReviewCardMetadata(
                        id,
                        reviewCards[index],
                        index,
                        reviewCards.length - 1
                    );
                }
            }
            
            return;
        }
        
        if (kind === ReviewKindEnumDTO.SM2) {
            if (this.sm2Store.tempCreatedReviewCards === undefined) {
                return undefined;
            }
            
            const reviewCards = this.sm2Store.tempCreatedReviewCards;
            if (reviewCards) {
                if (index >= 0 && index < reviewCards.length) {
                    return new OngoingReviewCardMetadata(
                        id,
                        reviewCards[index],
                        index,
                        reviewCards.length - 1
                    );
                }
            }
        }
    };
    
    public IsSessionComplete = (
        index: number,
        kind: ReviewKindEnumDTO
    ): boolean | undefined => {
        if (!this.ongoingReview) {
            return undefined;
        }
        
        const [id, _] = this.ongoingReview;
        
        if (kind === ReviewKindEnumDTO.MANUAL) {
            const reviewCards = this.manualStore.reviewCardMap.get(id);
            
            if (reviewCards) {
                if (index >= 0 && index < reviewCards.length) {
                    return false;
                }
            }
        }
        
        if (kind === ReviewKindEnumDTO.SM2) {
            if (this.sm2Store.tempCreatedReviewCards === undefined) {
                return undefined;
            }
            const reviewCards = this.sm2Store.tempCreatedReviewCards;
            
            if (reviewCards) {
                if (index >= 0 && index < reviewCards.length) {
                    return false;
                }
            }
        }
        
        return true;
    };
    
    public GetOngoingReviewNextCard = (
        currentIndex: number
    ): OngoingReviewCardMetadata | undefined => {
        const nextIndex = currentIndex + 1;
        if (!this.ongoingReview) {
            return undefined;
        }
        
        const [id, kind] = this.ongoingReview;
        
        if (kind === ReviewKindEnumDTO.MANUAL) {
            const reviewCards = this.manualStore.reviewCardMap.get(id);
            
            
            
            if (reviewCards) {
                if (nextIndex >= 0 && nextIndex < reviewCards.length) {
                    reviewCards[nextIndex].startAt = new Date();
                    
                    return new OngoingReviewCardMetadata(
                        id,
                        reviewCards[nextIndex],
                        nextIndex,
                        reviewCards.length - 1
                    );
                }
            }
            
            // TODO: What to do here?
            return;
        }
        
        if (kind === ReviewKindEnumDTO.SM2) {
            const reviewCards = this.sm2Store.tempCreatedReviewCards;
            
            if (reviewCards) {
                if (nextIndex >= 0 && nextIndex < reviewCards.length) {
                    reviewCards[nextIndex].startAt = new Date();
                    
                    return new OngoingReviewCardMetadata(
                        id,
                        reviewCards[nextIndex],
                        nextIndex,
                        reviewCards.length - 1
                    );
                }
            }
        }
    };
    
    public SetOngoingCardByIndex = (
        card: ICard,
        reviewKind: ReviewKindEnumDTO
    ) => {
        if (!this.ongoingReview) {
            return undefined;
        }
        
        const [id, _] = this.ongoingReview;
        
        if (reviewKind === ReviewKindEnumDTO.MANUAL) {
            const reviewCards = this.manualStore.reviewCardMap.get(id);
            
            // find indexes of card
            let foundIndexes: number[] = [];
            
            if (reviewCards) {
                reviewCards.forEach((x, i) => {
                    if (x.cardId === card.id) {
                        foundIndexes.push(i);
                    }
                });
                
                foundIndexes.forEach((cardIndex) => {
                    if (cardIndex >= 0) {
                        const reviewCard = reviewCards[cardIndex];
                        reviewCard.composite.card = card;
                        
                        reviewCards[cardIndex] = reviewCard;
                        
                        this.manualStore.reviewCardMap.set(id, reviewCards);
                    }
                });
            }
            
            return;
        }
        
        if (reviewKind === ReviewKindEnumDTO.SM2) {
            const reviewCards = this.sm2Store.reviewCardMap.get(id);
            
            // find indexes of card
            let foundIndexes: number[] = [];
            
            if (reviewCards) {
                reviewCards.forEach((x, i) => {
                    if (x.cardId === card.id) {
                        foundIndexes.push(i);
                    }
                });
                
                foundIndexes.forEach((cardIndex) => {
                    if (cardIndex >= 0) {
                        const reviewCard = reviewCards[cardIndex];
                        reviewCard.composite.card = card;
                        
                        reviewCards[cardIndex] = reviewCard;
                        
                        this.sm2Store.reviewCardMap.set(id, reviewCards);
                    }
                });
            }
            
            return;
        }
    };
    
    // This sets the ongoing reviews and will inherently set the current review too.
    public SetOngoingReview = async (
        id: string,
        kind: ReviewKindEnumDTO
    ): Promise<Result<IReview, IUIError>> => {
        switch (kind) {
            case ReviewKindEnumDTO.MANUAL:
                const res = await this.manualStore.GetOneOrFetch(id);
                if (res.ok && res.value !== undefined) {
                    this.ongoingReview = [id, ReviewKindEnumDTO.MANUAL];
                } else {
                    return Err(
                        NewUIErrorV2(
                            ActionType.SetOngoingReview,
                            EntityKind.ReviewManual,
                            `failed to set ongoing review for id: ${id}`
                        )
                    );
                }
                
                const retCards = await this.manualStore.ListCards(id);
                if (!retCards.ok) {
                    return Err(
                        NewUIErrorV2(
                            ActionType.ListReviewCards,
                            EntityKind.ReviewManualCard,
                            `failed to retrieve manual cards for id: ${id} - ${JSON.stringify(
                                retCards.error
                            )}`
                        )
                    );
                }
                
                return Ok(res.value as IReview);
            
            case ReviewKindEnumDTO.SM2:
                
                
                if (this.sm2Store.tempCreatedReview === undefined) {
                    return Err(
                        NewUIErrorV2(
                            ActionType.SetOngoingReview,
                            EntityKind.ReviewSM2,
                            `failed to set ongoing review for id: ${id}`
                        )
                    );
                }
                
                this.ongoingReview = [this.sm2Store.tempCreatedReview.id, kind];
                
                // Fetch cards
                if (this.sm2Store.tempCreatedReviewCards === undefined) {
                    return Err(
                        NewUIErrorV2(
                            ActionType.SetOngoingReview,
                            EntityKind.ReviewSM2,
                            `failed to set ongoing review for id: ${id}`
                        )
                    );
                }
                
                // console.log("retCardsSM2: ", retCardsSM2.value)
                return Ok(this.sm2Store.tempCreatedReview as IReview);
        }
    };
    
    // This awkwardly named function is used to set the sm2 review. Since we dont persist SM2 reviews until the first card is reviewed
    // we need to temporarily create the review and then set it as ongoing.
    public SetSM2OngoingReviewAfterTemporarilyCreatingReview = (
        review: ReviewSM2,
        cards: ReviewSM2Card[]
    ) => {
        this.sm2Store.tempCreatedReview = review;
        this.sm2Store.tempCreatedReviewCards = cards;
        // this.sm2Store.map.set(review.id, review);
        // this.sm2Store.reviewCardMap.set(review.id, cards);
    };
    
    public DeleteReview = async (
        reviewId: string,
        kind: ReviewKindEnumDTO
    ): Promise<void | IUIError> => {
        switch (kind) {
            case ReviewKindEnumDTO.MANUAL:
                const x = await this.manualStore.Delete(reviewId);
                if (!x.ok) {
                    return x.error as IUIError;
                }

                break;
            case ReviewKindEnumDTO.SM2:
                const x_1 = await this.sm2Store.Delete(reviewId);
                if (!x_1.ok) {
                    return x_1.error as IUIError;
                }
        }
    };
    
    UpdateCard = async (
        ongoingReviewId: string,
        kind: ReviewKindEnumDTO,
        newCard: ICard
    ): Promise<void | IUIError> => {
        if (kind === ReviewKindEnumDTO.MANUAL) {
            const ongoingManualReview = this.manualStore.map.get(ongoingReviewId);
            
            if (ongoingManualReview) {
                try {
                    const updatedCard = await this.cardStore.Update(newCard as Card);
                    
                    if (updatedCard.ok) {
                        let reviewCards =
                            this.manualStore.reviewCardMap.get(ongoingReviewId);
                        if (reviewCards) {
                            const index = reviewCards.findIndex(
                                (x) => x.cardId === newCard.id
                            );
                            if (index >= 0) {
                                reviewCards[index].composite.card = updatedCard.value;
                            }
                        } else {
                            return NewUIErrorV2(
                                ActionType.UpdateCardInReviewManualCard,
                                EntityKind.ReviewManualCard,
                                `review card to perform update for card not found`
                            );
                        }
                    } else {
                        return NewUIErrorV2(
                            ActionType.UpdateCardInReviewManualCard,
                            EntityKind.ReviewManualCard,
                            `failed to update card: ${newCard.id} - ${JSON.stringify(
                                updatedCard.error
                            )}`
                        );
                    }
                } catch (e) {
                    return NewUIErrorV2(
                        ActionType.UpdateCardInReviewManualCard,
                        EntityKind.ReviewManualCard,
                        `[panic]: failed to update card: ${newCard.id} - ${JSON.stringify(
                            e
                        )}`
                    );
                }
                
                return Promise.resolve();
            }
            
            const ongoingReview = this.sm2Store.map.get(ongoingReviewId);
            
            if (ongoingReview) {
                try {
                    const updatedCard = await this.cardStore.Update(newCard as Card);
                    if (updatedCard.ok) {
                        let reviewCards = this.sm2Store.reviewCardMap.get(ongoingReviewId);
                        if (reviewCards) {
                            const index = reviewCards.findIndex(
                                (x) => x.cardId === newCard.id
                            );
                            if (index >= 0) {
                                reviewCards[index].composite.card = updatedCard.value;
                            }
                        } else {
                            console.error("review card to perform update for card not found");
                        }
                    } else {
                        return NewUIErrorV2(
                            ActionType.UpdateCardInReviewSM2Card,
                            EntityKind.ReviewSM2Card,
                            `review card to perform update for card not found`
                        );
                    }
                } catch (e) {
                    return NewUIErrorV2(
                        ActionType.UpdateCardInReviewManualCard,
                        EntityKind.ReviewManualCard,
                        `[panic]: failed to update card: ${newCard.id} - ${JSON.stringify(
                            e
                        )}`
                    );
                }
            }
        } else if (kind === ReviewKindEnumDTO.SM2) {
            let ongoingReview = this.sm2Store.map.get(ongoingReviewId);
            
            if (ongoingReview) {
                try {
                    const updatedCard = await this.cardStore.Update(newCard as Card);
                    if (updatedCard.ok) {
                        let reviewCards = this.sm2Store.reviewCardMap.get(ongoingReviewId);
                        if (reviewCards) {
                            const index = reviewCards.findIndex(
                                (x) => x.cardId === newCard.id
                            );
                            
                            if (index >= 0) {
                                reviewCards[index].composite.card = updatedCard.value;
                            }
                          
                            // Update the temp created review cards - This is where the review cards are stored before the review is created
                          if (this.sm2Store.tempCreatedReviewCards) {
                            const index2 = this.sm2Store.tempCreatedReviewCards?.findIndex(
                                (x) => x.cardId === newCard.id
                            );
                            
                            if (index2 > -1) {
                              this.sm2Store.tempCreatedReviewCards[index2].composite.card =
                                  updatedCard.value;
                            }
                          }
                        } else {
                            console.error("review card to perform update for card not found");
                        }
                    } else {
                        return NewUIErrorV2(
                            ActionType.UpdateCardInReviewManualCard,
                            EntityKind.ReviewSM2Card,
                            `failed to update card: ${newCard.id} - ${JSON.stringify(
                                updatedCard.error
                            )}`
                        );
                    }
                } catch (e) {
                    return NewUIErrorV2(
                        ActionType.UpdateCardInReviewManualCard,
                        EntityKind.ReviewManualCard,
                        `[panic]: failed to update card: ${newCard.id} - ${JSON.stringify(
                            e
                        )}`
                    );
                }
            }
        }
    };
    
    public SetSelectedReview = async (
        id: string,
        kind: ReviewKindEnumDTO
    ): Promise<void | IUIError> => {
        switch (kind) {
            case ReviewKindEnumDTO.MANUAL:
                this.manualStore.GetOneOrFetch(id).then((res) => {
                    if (res.ok && res.value !== undefined) {
                        this.selectedReview = [id, kind];
                    } else {
                        console.error(`failed to retrieve manual review for id: ${id}`);
                    }
                });
                
                return Promise.resolve();
            case ReviewKindEnumDTO.SM2:
                if (this.sm2Store.tempCreatedReview) {
                    this.selectedReview = [this.sm2Store.tempCreatedReview.id, kind];
                    return Promise.resolve();
                } else {
                    return Promise.reject(
                        NewUIErrorV2(
                            ActionType.SetSelectedReview,
                            EntityKind.ReviewSM2,
                            `SM2 temporary object not created: ${id}`
                        )
                    );
                }
                // this.selectedReview =  this.sm2Store.tempCreatedReview
                // this.sm2Store.GetOneOrFetch(id).then((res) => {
                // 	if (res.ok && res.value !== undefined) {
                // 		this.selectedReview = [id, kind];
                // 	} else {
                // 		console.error(
                // 			`failed to retrieve sm2 review for id: ${id}`
                // 		);
                // 	}
                // })
                
                return Promise.resolve();
        }
    };
    
    get SelectedReview(): IReview | undefined {
        if (this.selectedReview) {
            const [id, kind] = this.selectedReview;
            switch (kind) {
                case ReviewKindEnumDTO.MANUAL:
                    return this.manualStore.map.get(id);
                case ReviewKindEnumDTO.SM2:
                    return this.sm2Store.map.get(id);
            }
        } else return undefined;
    }
    
    get SelectedReviewCards(): IReviewCard[] | undefined {
        if (this.selectedReview) {
            const [id, kind] = this.selectedReview;
            switch (kind) {
                case ReviewKindEnumDTO.MANUAL:
                    return this.manualStore.reviewCardMap.get(id);
                case ReviewKindEnumDTO.SM2:
                    return this.sm2Store.reviewCardMap.get(id);
            }
        } else return undefined;
    }
    
    get OngoingReview(): IReview | undefined {
        if (this.ongoingReview) {
            const [id, kind] = this.ongoingReview;
            switch (kind) {
                case ReviewKindEnumDTO.MANUAL:
                    return this.manualStore.map.get(id);
                case ReviewKindEnumDTO.SM2:
                    return this.sm2Store.tempCreatedReview;
            }
        } else return undefined;
    }
    
    public OngoingReviewCurrentCard = (): ICard | undefined => {
        if (this.ongoingReview) {
            const [id, kind] = this.ongoingReview;
            switch (kind) {
                case ReviewKindEnumDTO.MANUAL:
                    const cards = this.manualStore.reviewCardMap.get(id);
                    if (cards) {
                        if (this.ongoingReviewCardIndex >= 0) {
                            return cards[this.ongoingReviewCardIndex].composite.card;
                        }
                    }
                    break;
                case ReviewKindEnumDTO.SM2:
                    const sm2Cards = this.sm2Store.reviewCardMap.get(id);
                    if (sm2Cards) {
                        if (this.ongoingReviewCardIndex >= 0) {
                            return sm2Cards[this.ongoingReviewCardIndex].composite.card;
                        }
                    }
                    break;
            }
        }
    };
    
    public SetSelectedManualReviewAndFetchItsCards = async (id: string) => {
        await this.SetSelectedReview(id, ReviewKindEnumDTO.MANUAL);
        await this.manualStore.ListCards(id);
    };
    
    public SetSelectedSM2ReviewAndFetchItsCards = async (id: string) => {
        await this.SetSelectedReview(id, ReviewKindEnumDTO.SM2);
        await this.sm2Store.ListCards(id);
    };
    
    public GetReview = (
        id: string,
        kind: ReviewKindEnumDTO
    ): IReview | undefined => {
        switch (kind) {
            case ReviewKindEnumDTO.MANUAL:
                return this.manualStore.map.get(id);
            case ReviewKindEnumDTO.SM2:
                return this.sm2Store.map.get(id);
        }
    };
    
    public GetOrFetchReview = async (
        id: string,
        kind: ReviewKindEnumDTO
    ): Promise<Result<IReview | undefined, IUIError>> => {
        switch (kind) {
            case ReviewKindEnumDTO.MANUAL:
                return this.manualStore.GetOneOrFetch(id)

            case ReviewKindEnumDTO.SM2:
                return this.sm2Store.GetOneOrFetch(id)
        }

        return Promise.resolve(Ok(undefined));
    };
    
    public FetchReviews = async (limit?: number) => {
        let newLimit = DEFAULT_LIMIT;
        if (limit) {
            newLimit = limit;
        }
        
        const opt: ListOptionsRequestDTO = new ListOptionsRequestDTO();
        opt.setLimit(newLimit);
        
        let sm2 = this.sm2Store.List(opt);
        let manual = this.manualStore.List(opt);
        
        return Promise.all([sm2, manual]).then((data) => {
        });
    };
    
    /**
     *
     * @param reviewId
     * @param kind
     * @param config
     * @constructor
     */
    public FetchCardsForReview = async <T>(
        reviewId: string,
        kind: ReviewKindEnumDTO,
        config: T
    ) => {
        switch (kind) {
            case ReviewKindEnumDTO.MANUAL:
                try {
                    const res = await this.manualStore.ListCardsForNewReview(
                        reviewId,
                        config as unknown as ReviewManualOptionsDTO
                    );
                    if (!res.ok) {
                        console.error(
                            `failed to retrieve manual cards for id: ${reviewId} - ${JSON.stringify(
                                res
                            )}`
                        );
                    }
                } catch (e) {
                    console.error(
                        `failed to retrieve manual cards for id: ${reviewId} - ${JSON.stringify(
                            e
                        )}`
                    );
                }
                
                break;
            case ReviewKindEnumDTO.SM2:
                try {
                    const res = await this.sm2Store.ListCardsForNewReview(
                        reviewId,
                        config as unknown as ReviewSM2FilterConfigDTO
                    );
                    if (!res.ok) {
                        console.error(
                            `failed to retrieve sm2 cards for id: ${reviewId} - ${JSON.stringify(
                                res
                            )}`
                        );
                    }
                } catch (e) {
                    console.error(
                        `failed to retrieve SM2 cards for id: ${reviewId} - ${JSON.stringify(
                            e
                        )}`
                    );
                }
                break;
        }
    };
    
    public ResumeSM2Review = async (
        id: string,
        config: ReviewSM2FilterConfigDTO
    ): Promise<void | IUIError> => {
        return this.sm2Store
            .ResumeSM2Review(id, config)
            .then((res) => {
                if (res.ok) {
                    const [review, cards] = res.value;
                    
                    
                    
                    this.sm2Store.tempCreatedReviewCards = cards;
                    this.sm2Store.tempCreatedReview = review;
                    
                    runInAction(() => {
                        this.SetOngoingReview(id, ReviewKindEnumDTO.SM2);
                    });
                } else {
                    return Promise.reject(res.error as IUIError);
                }
            })
            .catch((e) => {
                
            });
    };
    
    public ResumeManualReview = async (id: string): Promise<void | IUIError> => {
        this.manualStore.Resume(id).then((res) => {
            if (res.ok) {
                runInAction(() => {
                    this.SetOngoingReview(id, ReviewKindEnumDTO.MANUAL);
                });
            } else {
                console.error(
                    `failed to resume manual review for id: ${id} - ${JSON.stringify(
                        res.error
                    )}`
                );
            }
        });
    };
    
    /**
     * Sets up a review to be started, the caller is implored to use `ongoingReview` to update the review.
     * @constructor
     * @param id
     * @param kind
     */
    public StartReview = async (
        id: string,
        kind: ReviewKindEnumDTO
    ): Promise<void> => {
        switch (kind) {
            case ReviewKindEnumDTO.MANUAL:
                return this.manualStore.Start(id).then((res) => {
                    if (res.ok) {
                        runInAction(() => {
                            this.SetOngoingReview(id, kind);
                        });
                        return Promise.resolve();
                    } else {
                        return Promise.reject(res.error as IUIError);
                    }
                });
            case ReviewKindEnumDTO.SM2:
                return this.sm2Store.Start(id).then((res) => {
                    if (res.ok) {
                        runInAction(() => {
                            this.SetOngoingReview(id, kind);
                        });
                        return Promise.resolve();
                    } else {
                        return Promise.reject(res.error as IUIError);
                    }
                });
        }
    };
    
    updateReviewMapFromArray = (
        items: IReview[],
        oldMap: Map<string, IReview>
    ): Map<string, IReview> => {
        let map = new Map<string, IReview>(oldMap);
        items.forEach((v, k) => {
            map.set(v.id, v);
        });
        return map;
    };
}

export class OngoingReview {
    kind: ReviewKindEnumDTO;
    review: IReview;
    cards: IReviewCard[];
    currentIndex: number = 0;
    
    constructor(
        kind: ReviewKindEnumDTO,
        review: IReview,
        cards: IReviewCard[],
        startIndex?: number
    ) {
        this.kind = kind;
        this.review = review;
        this.cards = cards;
        
        if (startIndex !== undefined) {
            this.currentIndex = startIndex;
        }
    }
    
    get currentCard(): IReviewCard {
        return this.cards[this.currentIndex];
    }
    
    get nextCard(): IReviewCard {
        return this.cards[this.currentIndex + 1];
    }
    
    get isComplete(): boolean {
        return this.currentIndex >= this.cards.length - 1;
    }
}
