import {StripeServicePromiseClient} from "proto/stripe_grpc_web_pb";
import {Err, Ok, Result} from "utils/result";
import {ActionType, IUIError, NewUIErrorV2} from "service/cartaError";
import {EntityKind} from "model/BaseModel";
import {StripeProduct} from "model/StripeProduct";
import {PublicServicePromiseClient} from "proto/public_grpc_web_pb";
import {ContactUsRequest, GetProductAndPriceRequest} from "proto/public_pb";
import {EnumSubscriptionProductDTO} from "proto/stripe_pb";
import {CARTA_PROXY_URL} from "consts";

const stripeClient = new StripeServicePromiseClient(CARTA_PROXY_URL!, null, {
    withCredentials: true,
});

const publicClient = new PublicServicePromiseClient(CARTA_PROXY_URL!, null, {});

export class PublicStore {
    constructor() {
    }

    public async SendContactForm(name: string, email: string, message: string): Promise<Result<void, IUIError>> {
        let req = new ContactUsRequest();
        req.setName(name)
        req.setEmail(email)
        req.setMessage(message)

        try {
            const res = await publicClient.contactUs(req)
            return Ok(undefined)
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.StripeCreateCheckoutSession, EntityKind.Stripe, e))
        }
    }

    async GetProductAndPrice(sub: EnumSubscriptionProductDTO): Promise<Result<StripeProduct, IUIError>> {
        let req = new GetProductAndPriceRequest();
        req.setSub(sub)

        try {
            const res = await publicClient.getProductAndPrice(req)
            if (!res.getProduct()) {
                return Err(NewUIErrorV2(ActionType.StripeProductAndPrice, EntityKind.Stripe, new Error("product is null")))
            }

            let product = new StripeProduct();
            product.fromDTO(res.getProduct()!);

            console.log("PRODUCT: ", product)

            return Ok(product)
        } catch (e) {
            return Err(NewUIErrorV2(ActionType.StripeCreateCheckoutSession, EntityKind.Stripe, e))
        }
    }
}