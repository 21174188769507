import {TagDTO} from "../proto/tag_pb";
import {ActionType, InternalErrorTypes, isError, IUIError, NewUIError, NewUIErrorV2,} from "../service/cartaError";
import {convertDateToTimestamp, convertTimestampToDate, IsUUIDValid, ListItem} from "../utils/utils";
import {UUID_DTO} from "../proto/utils_pb";
import {v4 as uuidv4} from "uuid";
import {IDisplayItem, Listable} from "./interfaces";
import {BaseModel, EntityKind} from "./BaseModel";
import {Err, Ok, Result} from "utils/result";
import {EnumSubscriptionProductDTO, StripeEntitlementDto, StripePriceDto, StripeProductDto} from "proto/stripe_pb";
import {DEFAULT_TAG_COLOR} from "consts";

// message StripeEntitlementDto {
//   string id = 1;
//   string stripe_entitlement_id = 2;
//   string stripe_lookup_key = 3;
//   string name = 4;
//   string description = 5;
//   bool active = 6;
//   utils.TimestampDTO created_on = 7;
// }
//
// message StripePriceDto {
//   string id = 1;
//   string stripe_price_id = 2;
//   string lookup_key = 3;
//   bool active = 4;
//   utils.TimestampDTO created_on = 5;
//   string currency = 6; // Using string since Option<T> maps to string or empty string in Protobuf
//   int64 unit_amount = 7;
//   string metadata = 8; // Assuming metadata is a serialized JSON string
//   string product_id = 9; // Using string since Option<T> maps to string or empty string in Protobuf
// }

export class StripeEntitlement extends BaseModel<StripeEntitlement, StripeEntitlementDto> {
    to1LineString(): String {
        return this.stripeLookupKey
    }

    TYPE: EntityKind = EntityKind.StripeEntitlement;

    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.name,
            color: "",
        }
    }

    id: string;
    stripeEntitlementId: string;
    stripeLookupKey: string;
    name: string;
    description: string;
    active: boolean;
    createdOn: Date;

    constructor() {
        super();
        this.id = "";
        this.stripeEntitlementId = "";
        this.stripeLookupKey = "";
        this.name = "";
        this.description = "";
        this.active = false;
        this.createdOn = new Date();
    }

    static fromJSON(s: StripeEntitlementDto): StripeEntitlement {
        let stripe = new StripeEntitlement();

        stripe.id = s.getId();
        stripe.stripeEntitlementId = s.getStripeEntitlementId();
        stripe.stripeLookupKey = s.getStripeLookupKey();
        stripe.name = s.getName();
        stripe.description = s.getDescription();
        stripe.active = s.getActive();
        stripe.createdOn = convertTimestampToDate(s.getCreatedOn()!);

        return stripe
    }

    clone(): StripeEntitlement {
        let temp = Object.assign({}, this);
        let newItem = new StripeEntitlement();

        newItem.id = temp.id;
        newItem.stripeEntitlementId = temp.stripeEntitlementId;
        newItem.stripeLookupKey = temp.stripeLookupKey;
        newItem.name = temp.name;
        newItem.description = temp.description;
        newItem.active = temp.active;
        newItem.createdOn = temp.createdOn;

        return newItem
    }

    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.name,
            color: "",
        }
    }


    init(): StripeEntitlement {
        return new StripeEntitlement()
    }

    validate(): StripeEntitlement | IUIError {
        if (this.name.length === 0) {
            const errMsg = "name is empty";
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                errMsg, errMsg, errMsg
            )
        }

        if (this.description.length === 0) {
            const errMsg = "description is empty";
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                errMsg, errMsg, errMsg)
        }

        return this
    }

    sanitize(): StripeEntitlement {
        this.name = this.name.trim();
        this.description = this.description.trim();

        return this
    }

    intoDTO(): StripeEntitlementDto | IUIError {
        if (!IsUUIDValid(this.id)) {
            return NewUIErrorV2(ActionType.ConvertToDTO, EntityKind.StripeEntitlement, "id is not valid", `id is not valid: ${this.id}`, "id is not valid")
        }

        if (this.name.length === 0) {
            return NewUIErrorV2(ActionType.ConvertToDTO, EntityKind.StripeEntitlement, "name is empty", `name is empty: ${this.name}`, "name is empty")
        }

        if (this.description.length === 0) {
            return NewUIErrorV2(ActionType.ConvertToDTO, EntityKind.StripeEntitlement, "description is empty", `description is empty: ${this.description}`, "description is empty")
        }

        let dto = new StripeEntitlementDto();
        dto.setId(this.id);
        dto.setStripeEntitlementId(this.stripeEntitlementId);
        dto.setStripeLookupKey(this.stripeLookupKey);
        dto.setName(this.name);
        dto.setDescription(this.description);
        dto.setActive(this.active);
        dto.setCreatedOn(convertDateToTimestamp(this.createdOn));

        return dto;
    }

    fromDTO(dto: StripeEntitlementDto): void | IUIError {
        let id = dto.getId();
        let stripeEntitlementId = dto.getStripeEntitlementId();
        let stripeLookupKey = dto.getStripeLookupKey();
        let name = dto.getName();
        let description = dto.getDescription();
        let active = dto.getActive();
        let createdOn = convertTimestampToDate(dto.getCreatedOn()!);

        if (!id) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.StripeEntitlement,
                "id is empty",
                `id is empty: ${dto}`,
                "id is empty"
            )
        }

        if (!stripeEntitlementId) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.StripeEntitlement,
                "stripeEntitlementId is empty",
                `stripeEntitlementId is empty: ${dto}`,
                "stripeEntitlementId is empty"
            )
        }



        if (!stripeLookupKey) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.StripeEntitlement,
                "stripeLookupKey is empty",
                `stripeLookupKey is empty: ${dto}`,
                "stripeLookupKey is empty"
            )
        }


        if (!name) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.StripeEntitlement,
                "name is empty",
                `name is empty: ${dto}`,
                "name is empty"
            )
        }

        if (!description) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.StripeEntitlement,
                "description is empty",
                `description is empty: ${dto}`,
                "description is empty"
            )
        }

        this.id = id;
        this.stripeEntitlementId = stripeEntitlementId;
        this.stripeLookupKey = stripeLookupKey;
        this.name = name;
        this.description = description;
        this.active = active;
        this.createdOn = createdOn;
    }
}

export class StripePrice extends BaseModel<StripePrice, StripePriceDto> {
    TYPE: EntityKind = EntityKind.StripePrice;

    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.currency,
            color: "",
        }
    }

    id: string;
    stripePriceId: string;
    lookupKey: string;
    active: boolean;
    createdOn: Date;
    currency: string;
    unitAmount: number;
    metadata: string;
    productId: string;

    constructor() {
        super();
        this.id = "";
        this.stripePriceId = "";
        this.lookupKey = "";
        this.active = false;
        this.createdOn = new Date();
        this.currency = "";
        this.unitAmount = 0;
        this.metadata = "";
        this.productId = "";
    }

    static fromJSON(s: StripePriceDto): StripePrice {
        let stripe = new StripePrice();

        stripe.id = s.getId();
        stripe.stripePriceId = s.getStripePriceId();
        stripe.lookupKey = s.getLookupKey();
        stripe.active = s.getActive();
        stripe.createdOn = convertTimestampToDate(s.getCreatedOn()!);
        stripe.currency = s.getCurrency();
        stripe.unitAmount = s.getUnitAmount();
        stripe.metadata = s.getMetadata();
        stripe.productId = s.getProductId();

        return stripe
    }

    clone(): StripePrice {
        let temp = Object.assign({}, this);
        let newItem = new StripePrice();

        newItem.id = temp.id;
        newItem.stripePriceId = temp.stripePriceId;
        newItem.lookupKey = temp.lookupKey;
        newItem.active = temp.active;
        newItem.createdOn = temp.createdOn;
        newItem.currency = temp.currency;
        newItem.unitAmount = temp.unitAmount;
        newItem.metadata = temp.metadata;
        newItem.productId = temp.productId;

        return newItem
    }

    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.currency,
            color: "",
        }
    }

    to1LineString(): String {
        return this.currency
    }

    init(): StripePrice {
        return new StripePrice()
    }

    validate(): StripePrice | IUIError {
        if (this.currency.length === 0) {
            const errMsg = "currency is empty";
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                errMsg, errMsg, errMsg
            )
        }

        if (this.unitAmount <= 0) {
            const errMsg = "unitAmount is invalid";
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                errMsg, errMsg, errMsg
            )
        }

        return this
    }

    sanitize(): StripePrice {
        this.currency = this.currency.trim();
        this.metadata = this.metadata.trim();

        return this
    }

    intoDTO(): StripePriceDto | IUIError {
        if (!IsUUIDValid(this.id)) {
            return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, "id is not valid", `id is not valid: ${this.id}`, "id is not valid")
        }

        if (this.currency.length === 0) {
            return NewUIErrorV2(ActionType.ConvertToDTO,this.TYPE, "currency is empty", `currency is empty: ${this.currency}`, "currency is empty")
        }

        if (this.unitAmount <= 0) {
            return NewUIErrorV2(ActionType.ConvertToDTO,this.TYPE, "unitAmount is invalid", `unitAmount is invalid: ${this.unitAmount}`, "unitAmount is invalid")
        }

        let dto = new StripePriceDto();
        dto.setId(this.id);
        dto.setStripePriceId(this.stripePriceId);
        dto.setLookupKey(this.lookupKey);
        dto.setActive(this.active);
        dto.setCreatedOn(convertDateToTimestamp(this.createdOn));
        dto.setCurrency(this.currency);
        dto.setUnitAmount(this.unitAmount);
        dto.setMetadata(this.metadata);
        dto.setProductId(this.productId);

        return dto;
    }

    fromDTO(dto: StripePriceDto): void | IUIError {
        let id = dto.getId();
        let stripePriceId = dto.getStripePriceId();
        let lookupKey = dto.getLookupKey();
        let active = dto.getActive();
        let createdOn = convertTimestampToDate(dto.getCreatedOn()!);
        let currency = dto.getCurrency();
        let unitAmount = dto.getUnitAmount();
        let metadata = dto.getMetadata();
        let productId = dto.getProductId();

        if (!id) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Stripe,
                "id is empty",
                `id is empty: ${dto}`,
                "id is empty"
            )
        }


        if (!stripePriceId) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Stripe,
                "stripePriceId is empty",
                `stripePriceId is empty: ${dto}`,
                "stripePriceId is empty"
            )
        }


        if (!lookupKey) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Stripe,
                "lookupKey is empty",
                `lookupKey is empty: ${dto}`,
                "lookupKey is empty"
            )
        }


        if (!currency) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Stripe,
                "currency is empty",
                `currency is empty: ${dto}`,
                "currency is empty"
            )
        }


        if (unitAmount === undefined) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Stripe,
                "unitAmount is empty",
                `unitAmount is empty: ${dto}`,
                "unitAmount is empty"
            )
        }

        if (metadata) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Stripe,
                "metadata is empty",
                `metadata is empty: ${dto}`,
                "metadata is empty"
            )
        }

        if (!productId) {
            return NewUIErrorV2(
                ActionType.Validate,
                EntityKind.Stripe,
                "productId is empty",
                `productId is empty: ${dto}`,
                "productId is empty"
            )
        }

        this.id = id;
        this.stripePriceId = stripePriceId;
        this.lookupKey = lookupKey;
        this.active = active;
        this.createdOn = createdOn;
        this.currency = currency;
        this.unitAmount = unitAmount;
        this.metadata = metadata;
        this.productId = productId;
    }
}

export class StripeProduct extends BaseModel<StripeProduct, StripeProductDto> {
    TYPE: EntityKind = EntityKind.StripeProduct;

    toListItem(): ListItem {
        return {
            id: this.id,
            title: this.name,
            color: "",
        }
    }

    id: string;
    name: string;
    description: string;
    active: boolean;
    price: StripePrice | undefined;
    sub_level: EnumSubscriptionProductDTO=  EnumSubscriptionProductDTO.FREE
    entitlements: Array<StripeEntitlement> = [];

    constructor() {
        super();
        this.id = "";
        this.name = "";
        this.description = "";
        this.active = false;
    }


    clone(): StripeProduct {
        let temp = Object.assign({}, this);
        let newItem = new StripeProduct();

        newItem.id = temp.id;
        newItem.name = temp.name;
        newItem.description = temp.description;
        newItem.active = temp.active;
        newItem.price = temp.price;
        newItem.sub_level = temp.sub_level;
        newItem.entitlements = [...temp.entitlements];

        return newItem
    }

    toDisplayable(): IDisplayItem {
        return {
            id: this.id,
            title: this.name,
            color: "",
        }
    }

    to1LineString(): String {
        return this.name
    }

    init(): StripeProduct {
        return new StripeProduct()
    }

    validate(): StripeProduct | IUIError {
        if (this.name.length === 0) {
            const errMsg = "name is empty";
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                errMsg, errMsg, errMsg
            )
        }

        if (this.description.length === 0) {
            const errMsg = "description is empty";
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                errMsg, errMsg, errMsg
            )
        }

        return this
    }

    sanitize(): StripeProduct {
        this.name = this.name.trim();
        this.description = this.description.trim();
        return this
    }

    intoDTO(): StripeProductDto | IUIError {
        if (!IsUUIDValid(this.id)) {
            return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, "id is not valid", `id is not valid: ${this.id}`, "id is not valid")
        }

        if (this.name.length === 0) {
            return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, "name is empty", `name is empty: ${this.name}`, "name is empty")
        }

        if (this.description.length === 0) {
            return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, "description is empty", `description is empty: ${this.description}`, "description is empty")
        }

        if (!this.price) {
            return NewUIErrorV2(ActionType.ConvertToDTO, this.TYPE, "price is empty", `price is empty: ${this.price}`, "price is empty")
        }


        let price = this.price.intoDTO();
        if (isError(price)) {
            return price as IUIError
        }

        let dto = new StripeProductDto();
        dto.setId(this.id);
        dto.setName(this.name);
        dto.setDescription(this.description);
        dto.setActive(this.active);
        dto.setProduct(this.sub_level)
        dto.setPrice(price as StripePriceDto);

        return dto;
    }

    fromDTO(dto: StripeProductDto): void | IUIError {
        let id = dto.getId();
        let name = dto.getName();
        let description = dto.getDescription();
        let active = dto.getActive();
        let price = dto.getPrice();

        if (!id) {
            return NewUIErrorV2(
                ActionType.ConvertFromDTO,
                this.TYPE,
                "id is empty",
                `id is empty: ${dto}`,
                "id is empty"
            )
        }


        if (!name) {
            return NewUIErrorV2(
                ActionType.ConvertFromDTO,
                this.TYPE,
                "name is empty",
                `name is empty: ${dto}`,
                "name is empty"
            )
        }


        if (!description) {
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                "description is empty",
                `description is empty: ${dto}`,
                "description is empty"
            )
        }


        if (!price) {
            return NewUIErrorV2(
                ActionType.Validate,
                this.TYPE,
                "price is empty",
                `price is empty: ${dto}`,
                "price is empty"
            )
        }


        let stripePrice = new StripePrice();
        const err = stripePrice.fromDTO(dto.getPrice()!);
        if (err) {
            return NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, err)
        }

        let entitlements: Array<StripeEntitlement> = [];
        dto.getEntitlementList().forEach((x) => {
            let entitlement = new StripeEntitlement();
            const err = entitlement.fromDTO(x)
            if (err) {
                return NewUIErrorV2(ActionType.ConvertFromDTO, this.TYPE, err)
            }

            entitlements.push(entitlement)
        })


        this.id = id;
        this.name = name;
        this.description = description;
        this.active = active;
        this.sub_level = dto.getProduct()
        this.price = stripePrice;
        this.entitlements = entitlements;
    }
}
