// source: stripe.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
goog.exportSymbol('proto.stripe.CreateCheckoutSessionRequest', null, global);
goog.exportSymbol('proto.stripe.CreateCheckoutSessionResponse', null, global);
goog.exportSymbol('proto.stripe.CreatePortalSessionRequest', null, global);
goog.exportSymbol('proto.stripe.CreatePortalSessionResponse', null, global);
goog.exportSymbol('proto.stripe.EnumSubscriptionProductDTO', null, global);
goog.exportSymbol('proto.stripe.EnumSubscriptionStatusDTO', null, global);
goog.exportSymbol('proto.stripe.PricingSessionDto', null, global);
goog.exportSymbol('proto.stripe.StripeEntitlementDto', null, global);
goog.exportSymbol('proto.stripe.StripePriceDto', null, global);
goog.exportSymbol('proto.stripe.StripeProductDto', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.PricingSessionDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stripe.PricingSessionDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.PricingSessionDto.displayName = 'proto.stripe.PricingSessionDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.StripeEntitlementDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stripe.StripeEntitlementDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.StripeEntitlementDto.displayName = 'proto.stripe.StripeEntitlementDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.StripePriceDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stripe.StripePriceDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.StripePriceDto.displayName = 'proto.stripe.StripePriceDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.StripeProductDto = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.stripe.StripeProductDto.repeatedFields_, null);
};
goog.inherits(proto.stripe.StripeProductDto, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.StripeProductDto.displayName = 'proto.stripe.StripeProductDto';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.CreateCheckoutSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stripe.CreateCheckoutSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.CreateCheckoutSessionRequest.displayName = 'proto.stripe.CreateCheckoutSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.CreateCheckoutSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stripe.CreateCheckoutSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.CreateCheckoutSessionResponse.displayName = 'proto.stripe.CreateCheckoutSessionResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.CreatePortalSessionRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stripe.CreatePortalSessionRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.CreatePortalSessionRequest.displayName = 'proto.stripe.CreatePortalSessionRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.stripe.CreatePortalSessionResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.stripe.CreatePortalSessionResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.stripe.CreatePortalSessionResponse.displayName = 'proto.stripe.CreatePortalSessionResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.PricingSessionDto.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.PricingSessionDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.PricingSessionDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.PricingSessionDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    priceId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.PricingSessionDto}
 */
proto.stripe.PricingSessionDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.PricingSessionDto;
  return proto.stripe.PricingSessionDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.PricingSessionDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.PricingSessionDto}
 */
proto.stripe.PricingSessionDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setPriceId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.PricingSessionDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.PricingSessionDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.PricingSessionDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.PricingSessionDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPriceId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string price_id = 1;
 * @return {string}
 */
proto.stripe.PricingSessionDto.prototype.getPriceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.PricingSessionDto} returns this
 */
proto.stripe.PricingSessionDto.prototype.setPriceId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.stripe.PricingSessionDto.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.PricingSessionDto} returns this
 */
proto.stripe.PricingSessionDto.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.StripeEntitlementDto.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.StripeEntitlementDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.StripeEntitlementDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.StripeEntitlementDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stripeEntitlementId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    stripeLookupKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    description: jspb.Message.getFieldWithDefault(msg, 5, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 6, false),
    createdOn: (f = msg.getCreatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.StripeEntitlementDto}
 */
proto.stripe.StripeEntitlementDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.StripeEntitlementDto;
  return proto.stripe.StripeEntitlementDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.StripeEntitlementDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.StripeEntitlementDto}
 */
proto.stripe.StripeEntitlementDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeEntitlementId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeLookupKey(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 6:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 7:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.StripeEntitlementDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.StripeEntitlementDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.StripeEntitlementDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.StripeEntitlementDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStripeEntitlementId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getStripeLookupKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      6,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stripe.StripeEntitlementDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeEntitlementDto} returns this
 */
proto.stripe.StripeEntitlementDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string stripe_entitlement_id = 2;
 * @return {string}
 */
proto.stripe.StripeEntitlementDto.prototype.getStripeEntitlementId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeEntitlementDto} returns this
 */
proto.stripe.StripeEntitlementDto.prototype.setStripeEntitlementId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string stripe_lookup_key = 3;
 * @return {string}
 */
proto.stripe.StripeEntitlementDto.prototype.getStripeLookupKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeEntitlementDto} returns this
 */
proto.stripe.StripeEntitlementDto.prototype.setStripeLookupKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.stripe.StripeEntitlementDto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeEntitlementDto} returns this
 */
proto.stripe.StripeEntitlementDto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string description = 5;
 * @return {string}
 */
proto.stripe.StripeEntitlementDto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeEntitlementDto} returns this
 */
proto.stripe.StripeEntitlementDto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional bool active = 6;
 * @return {boolean}
 */
proto.stripe.StripeEntitlementDto.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 6, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stripe.StripeEntitlementDto} returns this
 */
proto.stripe.StripeEntitlementDto.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 6, value);
};


/**
 * optional utils.TimestampDTO created_on = 7;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stripe.StripeEntitlementDto.prototype.getCreatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 7));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stripe.StripeEntitlementDto} returns this
*/
proto.stripe.StripeEntitlementDto.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stripe.StripeEntitlementDto} returns this
 */
proto.stripe.StripeEntitlementDto.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stripe.StripeEntitlementDto.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 7) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.StripePriceDto.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.StripePriceDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.StripePriceDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.StripePriceDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    stripePriceId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    lookupKey: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 4, false),
    createdOn: (f = msg.getCreatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    currency: jspb.Message.getFieldWithDefault(msg, 6, ""),
    unitAmount: jspb.Message.getFieldWithDefault(msg, 7, 0),
    metadata: jspb.Message.getFieldWithDefault(msg, 8, ""),
    productId: jspb.Message.getFieldWithDefault(msg, 9, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.StripePriceDto}
 */
proto.stripe.StripePriceDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.StripePriceDto;
  return proto.stripe.StripePriceDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.StripePriceDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.StripePriceDto}
 */
proto.stripe.StripePriceDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripePriceId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLookupKey(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 5:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCurrency(value);
      break;
    case 7:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setUnitAmount(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setMetadata(value);
      break;
    case 9:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.StripePriceDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.StripePriceDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.StripePriceDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.StripePriceDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStripePriceId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLookupKey();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getCurrency();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUnitAmount();
  if (f !== 0) {
    writer.writeInt64(
      7,
      f
    );
  }
  f = message.getMetadata();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
  f = message.getProductId();
  if (f.length > 0) {
    writer.writeString(
      9,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stripe.StripePriceDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string stripe_price_id = 2;
 * @return {string}
 */
proto.stripe.StripePriceDto.prototype.getStripePriceId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setStripePriceId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string lookup_key = 3;
 * @return {string}
 */
proto.stripe.StripePriceDto.prototype.getLookupKey = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setLookupKey = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 4;
 * @return {boolean}
 */
proto.stripe.StripePriceDto.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * optional utils.TimestampDTO created_on = 5;
 * @return {?proto.utils.TimestampDTO}
 */
proto.stripe.StripePriceDto.prototype.getCreatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 5));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.stripe.StripePriceDto} returns this
*/
proto.stripe.StripePriceDto.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stripe.StripePriceDto.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional string currency = 6;
 * @return {string}
 */
proto.stripe.StripePriceDto.prototype.getCurrency = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setCurrency = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional int64 unit_amount = 7;
 * @return {number}
 */
proto.stripe.StripePriceDto.prototype.getUnitAmount = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 7, 0));
};


/**
 * @param {number} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setUnitAmount = function(value) {
  return jspb.Message.setProto3IntField(this, 7, value);
};


/**
 * optional string metadata = 8;
 * @return {string}
 */
proto.stripe.StripePriceDto.prototype.getMetadata = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setMetadata = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};


/**
 * optional string product_id = 9;
 * @return {string}
 */
proto.stripe.StripePriceDto.prototype.getProductId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 9, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripePriceDto} returns this
 */
proto.stripe.StripePriceDto.prototype.setProductId = function(value) {
  return jspb.Message.setProto3StringField(this, 9, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.stripe.StripeProductDto.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.StripeProductDto.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.StripeProductDto.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.StripeProductDto} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.StripeProductDto.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    description: jspb.Message.getFieldWithDefault(msg, 3, ""),
    active: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    price: (f = msg.getPrice()) && proto.stripe.StripePriceDto.toObject(includeInstance, f),
    entitlementList: jspb.Message.toObjectList(msg.getEntitlementList(),
    proto.stripe.StripeEntitlementDto.toObject, includeInstance),
    product: jspb.Message.getFieldWithDefault(msg, 8, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.StripeProductDto}
 */
proto.stripe.StripeProductDto.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.StripeProductDto;
  return proto.stripe.StripeProductDto.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.StripeProductDto} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.StripeProductDto}
 */
proto.stripe.StripeProductDto.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setActive(value);
      break;
    case 6:
      var value = new proto.stripe.StripePriceDto;
      reader.readMessage(value,proto.stripe.StripePriceDto.deserializeBinaryFromReader);
      msg.setPrice(value);
      break;
    case 7:
      var value = new proto.stripe.StripeEntitlementDto;
      reader.readMessage(value,proto.stripe.StripeEntitlementDto.deserializeBinaryFromReader);
      msg.addEntitlement(value);
      break;
    case 8:
      var value = /** @type {!proto.stripe.EnumSubscriptionProductDTO} */ (reader.readEnum());
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.StripeProductDto.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.StripeProductDto.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.StripeProductDto} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.StripeProductDto.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getActive();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getPrice();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      proto.stripe.StripePriceDto.serializeBinaryToWriter
    );
  }
  f = message.getEntitlementList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      proto.stripe.StripeEntitlementDto.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f !== 0.0) {
    writer.writeEnum(
      8,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stripe.StripeProductDto.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeProductDto} returns this
 */
proto.stripe.StripeProductDto.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.stripe.StripeProductDto.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeProductDto} returns this
 */
proto.stripe.StripeProductDto.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string description = 3;
 * @return {string}
 */
proto.stripe.StripeProductDto.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.StripeProductDto} returns this
 */
proto.stripe.StripeProductDto.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional bool active = 5;
 * @return {boolean}
 */
proto.stripe.StripeProductDto.prototype.getActive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stripe.StripeProductDto} returns this
 */
proto.stripe.StripeProductDto.prototype.setActive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional StripePriceDto price = 6;
 * @return {?proto.stripe.StripePriceDto}
 */
proto.stripe.StripeProductDto.prototype.getPrice = function() {
  return /** @type{?proto.stripe.StripePriceDto} */ (
    jspb.Message.getWrapperField(this, proto.stripe.StripePriceDto, 6));
};


/**
 * @param {?proto.stripe.StripePriceDto|undefined} value
 * @return {!proto.stripe.StripeProductDto} returns this
*/
proto.stripe.StripeProductDto.prototype.setPrice = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stripe.StripeProductDto} returns this
 */
proto.stripe.StripeProductDto.prototype.clearPrice = function() {
  return this.setPrice(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stripe.StripeProductDto.prototype.hasPrice = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated StripeEntitlementDto entitlement = 7;
 * @return {!Array<!proto.stripe.StripeEntitlementDto>}
 */
proto.stripe.StripeProductDto.prototype.getEntitlementList = function() {
  return /** @type{!Array<!proto.stripe.StripeEntitlementDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.stripe.StripeEntitlementDto, 7));
};


/**
 * @param {!Array<!proto.stripe.StripeEntitlementDto>} value
 * @return {!proto.stripe.StripeProductDto} returns this
*/
proto.stripe.StripeProductDto.prototype.setEntitlementList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.stripe.StripeEntitlementDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stripe.StripeEntitlementDto}
 */
proto.stripe.StripeProductDto.prototype.addEntitlement = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.stripe.StripeEntitlementDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.stripe.StripeProductDto} returns this
 */
proto.stripe.StripeProductDto.prototype.clearEntitlementList = function() {
  return this.setEntitlementList([]);
};


/**
 * optional EnumSubscriptionProductDTO product = 8;
 * @return {!proto.stripe.EnumSubscriptionProductDTO}
 */
proto.stripe.StripeProductDto.prototype.getProduct = function() {
  return /** @type {!proto.stripe.EnumSubscriptionProductDTO} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.stripe.EnumSubscriptionProductDTO} value
 * @return {!proto.stripe.StripeProductDto} returns this
 */
proto.stripe.StripeProductDto.prototype.setProduct = function(value) {
  return jspb.Message.setProto3EnumField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.CreateCheckoutSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.CreateCheckoutSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreateCheckoutSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    pricingSession: (f = msg.getPricingSession()) && proto.stripe.PricingSessionDto.toObject(includeInstance, f),
    customerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    createCustomer: jspb.Message.getBooleanFieldWithDefault(msg, 3, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.CreateCheckoutSessionRequest}
 */
proto.stripe.CreateCheckoutSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.CreateCheckoutSessionRequest;
  return proto.stripe.CreateCheckoutSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.CreateCheckoutSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.CreateCheckoutSessionRequest}
 */
proto.stripe.CreateCheckoutSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.stripe.PricingSessionDto;
      reader.readMessage(value,proto.stripe.PricingSessionDto.deserializeBinaryFromReader);
      msg.setPricingSession(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setCreateCustomer(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.CreateCheckoutSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.CreateCheckoutSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreateCheckoutSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getPricingSession();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.stripe.PricingSessionDto.serializeBinaryToWriter
    );
  }
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCreateCustomer();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
};


/**
 * optional PricingSessionDto pricing_session = 1;
 * @return {?proto.stripe.PricingSessionDto}
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.getPricingSession = function() {
  return /** @type{?proto.stripe.PricingSessionDto} */ (
    jspb.Message.getWrapperField(this, proto.stripe.PricingSessionDto, 1));
};


/**
 * @param {?proto.stripe.PricingSessionDto|undefined} value
 * @return {!proto.stripe.CreateCheckoutSessionRequest} returns this
*/
proto.stripe.CreateCheckoutSessionRequest.prototype.setPricingSession = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.stripe.CreateCheckoutSessionRequest} returns this
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.clearPricingSession = function() {
  return this.setPricingSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.hasPricingSession = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string customer_id = 2;
 * @return {string}
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.CreateCheckoutSessionRequest} returns this
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool create_customer = 3;
 * @return {boolean}
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.getCreateCustomer = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.stripe.CreateCheckoutSessionRequest} returns this
 */
proto.stripe.CreateCheckoutSessionRequest.prototype.setCreateCustomer = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.CreateCheckoutSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.CreateCheckoutSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreateCheckoutSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    sessionId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 3, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.CreateCheckoutSessionResponse}
 */
proto.stripe.CreateCheckoutSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.CreateCheckoutSessionResponse;
  return proto.stripe.CreateCheckoutSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.CreateCheckoutSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.CreateCheckoutSessionResponse}
 */
proto.stripe.CreateCheckoutSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.CreateCheckoutSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.CreateCheckoutSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreateCheckoutSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.CreateCheckoutSessionResponse} returns this
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string session_id = 2;
 * @return {string}
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.CreateCheckoutSessionResponse} returns this
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string redirect_url = 3;
 * @return {string}
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.CreateCheckoutSessionResponse} returns this
 */
proto.stripe.CreateCheckoutSessionResponse.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.CreatePortalSessionRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.CreatePortalSessionRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.CreatePortalSessionRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreatePortalSessionRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    sessionId: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.CreatePortalSessionRequest}
 */
proto.stripe.CreatePortalSessionRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.CreatePortalSessionRequest;
  return proto.stripe.CreatePortalSessionRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.CreatePortalSessionRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.CreatePortalSessionRequest}
 */
proto.stripe.CreatePortalSessionRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setSessionId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.CreatePortalSessionRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.CreatePortalSessionRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.CreatePortalSessionRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreatePortalSessionRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSessionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string session_id = 1;
 * @return {string}
 */
proto.stripe.CreatePortalSessionRequest.prototype.getSessionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.CreatePortalSessionRequest} returns this
 */
proto.stripe.CreatePortalSessionRequest.prototype.setSessionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.stripe.CreatePortalSessionResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.stripe.CreatePortalSessionResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.stripe.CreatePortalSessionResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreatePortalSessionResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    redirectUrl: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.stripe.CreatePortalSessionResponse}
 */
proto.stripe.CreatePortalSessionResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.stripe.CreatePortalSessionResponse;
  return proto.stripe.CreatePortalSessionResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.stripe.CreatePortalSessionResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.stripe.CreatePortalSessionResponse}
 */
proto.stripe.CreatePortalSessionResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setRedirectUrl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.stripe.CreatePortalSessionResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.stripe.CreatePortalSessionResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.stripe.CreatePortalSessionResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.stripe.CreatePortalSessionResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getRedirectUrl();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string id = 1;
 * @return {string}
 */
proto.stripe.CreatePortalSessionResponse.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.CreatePortalSessionResponse} returns this
 */
proto.stripe.CreatePortalSessionResponse.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string redirect_url = 2;
 * @return {string}
 */
proto.stripe.CreatePortalSessionResponse.prototype.getRedirectUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.stripe.CreatePortalSessionResponse} returns this
 */
proto.stripe.CreatePortalSessionResponse.prototype.setRedirectUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.stripe.EnumSubscriptionProductDTO = {
  FREE: 0,
  STANDARD: 1,
  PRO: 2,
  PREMIUM: 3
};

/**
 * @enum {number}
 */
proto.stripe.EnumSubscriptionStatusDTO = {
  ACTIVE: 0,
  INACTIVE: 1,
  PASTDUE: 2,
  CANCELED: 3,
  UNPAID: 4
};

goog.object.extend(exports, proto.stripe);
