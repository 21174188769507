import {Tag} from "model/tag";
import BaseStore from "./BaseStore";
import {TagDTO} from "proto/tag_pb";
import {TagGRPCImpl, TagService} from "service/TagService";


export class TagStore extends BaseStore<Tag, TagDTO, TagGRPCImpl, TagService> {
    public service: TagService;

    constructor(service: TagService) {
        super(service, Tag);

        this.service = new TagService();
    }
}
