
/// Environment variables - These constants are set by the environment and are used to configure the application.
export let CARTA_PROXY_URL = process.env.REACT_APP_PROXY_URL ? process.env.REACT_APP_PROXY_URL : "https://localhost:18002"
export let CARTA_RESOURCE_PROXY_URL = process.env.REACT_APP_RESOURCE_PROXY_URL
export let REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI_STAGING
export let STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export let STRIPE_SECRET_KEY = process.env.REACT_APP_STRIPE_SECRET_KEY
export const STRIPE_PRICING_TABLE_ID = process.env.REACT_APP_STRIPE_PRICING_TABLE_ID
export const STRIPE_PRICING_TABLE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PRICING_TABLE_PUBLISHABLE_KEY
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const STRIPE_BILLING_CUSTOMER_PORTAL = process.env.REACT_APP_STRIPE_BILLING_CUSTOMER_PORTAL

/**
 * Entitlements
 */
export const ENTITLEMENT_COMPLETE_DASHBOARD = "meminni-feature-complete-dashboard"
export const ENTITLEMENT_MANUAL_REVIEW_MANAGEMENT = "meminni-feature-manual-review-management"
export const ENTITLEMENT_BROWSER_PLUGIN_MANAGEMENT = "meminni-feature-browser-plugin-management"
export const ENTITLEMENT_ENHANCED_RESOURCE_SEARCH = "meminni-feature-enhanced-resource-search"
export const ENTITLEMENT_ADVANCED_ANALYTICS = "meminni-feature-advanced-analytics"
export const ENTITLEMENT_BASIC_APP_USAGE = "meminni-feature-basic-app-usage"
export const ENTITLEMENT_UNLIMITED_OBJECTS = "meminni-feature-unlimited-objects"
export const ENTITLEMENT_STANDARD_DASHBOARD = "meminni-feature-standard-dashboard"
export const ENTITLEMENT_RESOURCE_METADATA = "meminni-feature-resource-metadata"
export const ENTITLEMENT_MEDIA_UPLOADS = "meminni-feature-media-uploads" // This will be the meminni url of the running app, usually ends in meminni.com

/**
 * Feature Flags
 */
export const TRACE_DISTRIBUTION_TARGET_FIREBASE = process.env.REACT_APP_TRACE_DISTRIBUTION_TARGET_FIREBASE
export const TRACE_DISTRIBUTION_TARGET_MEMINNI = process.env.REACT_APP_TRACE_DISTRIBUTION_TARGET_MEMINNI
export const MAX_TOPIC_RELATIONSHIP_DEPTH = 7;
export const DEFAULT_TOPIC_RELATIONSHIP_DEPTH = 2;
export const DEFAULT_TAG_COLOR = "#FFFFAA";

export const GRPC_CARTA_ERROR_HEADER = "x-grpc-carta-error";
export const DEBOUNCE_DELAY = 100;
export const LOCAL_STORAGE_CARD_METADATA = "card_metadata"
export const DEFAULT_LIMIT = 25;
export const DEFAULT_SM2_CARD_LIMIT = 30;
export const MAX_LIMIT = 100;
export const MAX_LIMIT_REVIEW_SM2 = 30;
export const MAX_LIMIT_TAG = 100;
export const MAX_LIMIT_TOPIC = 100;
export const MAX_LIMIT_CARD = 100;
export const MAX_LIMIT_RESOURCE = 100;
export const MAX_LIMIT_REVIEW_MANUAL = 100;
export const MAX_LIMIT_REVIEW = 100;


/**
 * Local Storage Keys
 */
export const LOCAL_STORAGE_PRICING_SESSION = 'pricing-session';
export const LOCAL_STORAGE_SIGNUP_PRICING_SESSION = 'signup-pricing-session';
export const LOCAL_STORAGE_ENTITLEMENTS = 'entitlements';
export const LOCAL_STORAGE_USER = 'user';
export const LOCAL_STORAGE_USER_ID = 'user-id';
export const LOCAL_STORAGE_LAST_HEARTBEAT_TIME = 'last-heartbeat-time';
export const LOCAL_STORAGE_CONFIG_PROVIDER = 'config-provider';
export const LOCAL_STORAGE_SUBSCRIPTION_PRODUCT = 'subscription-product';