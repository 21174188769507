import {GrpcGoogleAuthServicePromiseClient} from "proto/auth_grpc_web_pb";
import {AuthObj, IGoogleOAuthTokenResponse, JWTToken} from "model/auth";
import {ActionType, InternalErrorTypes, isError, IUIError, NewUIErrorV2, UIErrorV2} from "service/cartaError";
import {
    FederatedProviderDTO,
    GoogleOAuthTokenDTO,
    HeartbeatRequest,
    LoginRequest,
    LoginResponse,
    LogoutRequest,
    SignUpProfileDTO,
    SignUpRequest,
    SignUpResponse
} from "proto/auth_pb";
import {action, computed, makeObservable, observable, runInAction} from "mobx";
import {IFromDTO, IIntoDTO, IValidator} from "model/model";
import {Err, Ok, Result} from "utils/result";
import {EntityKind} from "model/BaseModel";
import {User} from "model/user";
import {
    getLastHeartBeatTimeFromLocalStorage,
    removeAuthInfoFromLocalStorage,
    retrieveUserFromLocalStorage,
    storeAuthObjInLocalStorage,
    storeHeartBeatTimeInLocalStorage
} from "service/AuthService";
import {UserStore} from "stores/UserStore";
import {PricingSessionDto} from "proto/stripe_pb";
import grpcWeb from "grpc-web";
import {ErrorStatusDTO} from "proto/utils_pb";
import {PricingSession} from "pages/billing/pricing";
import {
    CARTA_PROXY_URL,
    GRPC_CARTA_ERROR_HEADER,
    LOCAL_STORAGE_ENTITLEMENTS,
    LOCAL_STORAGE_SUBSCRIPTION_PRODUCT
} from "consts";

const authClient = new GrpcGoogleAuthServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {
        'withCredentials': true,
    }
);

export class AuthenticationStore {
    authenticationState: AuthenticationState = {
        state: AuthenticationStateEnum.LoggedOff,
        payload: null
    }

    userStore: UserStore;
    entitlements: string[] = [];

    constructor(useStore: UserStore) {
        makeObservable(this, {
            authenticationState: observable,
            entitlements: observable,

            LoginGoogle: action,
            HeartBeat: action,
            AuthCheck: action,
            SetAuthenticationState: action,

            GetUser: computed,
            GetAuthenticationState: computed,
            GetEntitlements: computed
        });

        this.userStore = useStore
    }

    SetAuthenticationState = (state: AuthenticationState) => {
        this.authenticationState = state;
    }

    /**
     * Sends the completed OAuth2 details to the backend server to either login/sign-up
     * @param token
     * @constructor
     */
    LoginGoogle = async (token: IGoogleOAuthTokenResponse): Promise<Result<AuthObj, UIErrorV2>> => {
        let profile = token.profileObj.intoDTO()
        if (isError(profile)) {
            return Err(NewUIErrorV2(
                ActionType.Authenticate,
                EntityKind.Auth,
                profile as IUIError,
                `failed to convert GoogleProfile to DTO - Err(Value = ${JSON.stringify(profile)})`
            ));
        }

        let tokenReq = token.tokenObj.intoDTO()
        if (isError(tokenReq)) {
            return Err(NewUIErrorV2(
                ActionType.Authenticate,
                EntityKind.Auth,
                tokenReq as IUIError,
                `failed to convert GoogleOAuthToken to DTO - Err(Value = ${JSON.stringify(tokenReq)})`
            ));
        }

        let loginReq = new LoginRequest();
        loginReq.setProfile(profile as SignUpProfileDTO);
        loginReq.setToken(tokenReq as GoogleOAuthTokenDTO);

        try {
            const response: LoginResponse =
                await authClient.login(loginReq, undefined);

            if (!response.getJwt()) {
                return Err(NewUIErrorV2(
                        ActionType.Authenticate,
                        EntityKind.Auth,
                    InternalErrorTypes.AuthenticationGoogle,
                    `authentication JWT response from backend is nil`
                ));
            }

            if (!response.getUserId()) {
                return Err(NewUIErrorV2(
                    ActionType.Authenticate,
                    EntityKind.Auth,
                    InternalErrorTypes.AuthenticationGoogle,
                    `authentication userId response from backend is nil`
                ));
            }

            if (!response.getUser()) {
                throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "user is empty")
            }

            if (!response.getEntitlementsList()) {
                throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "entitlements is empty")
            }

            const token: JWTToken = new JWTToken();
            token.fromDTO(response.getJwt()!)

            if (isError(token)) {
                throw NewUIErrorV2(ActionType.Authenticate, EntityKind.JWTToken, token as unknown as IUIError)
            }

            let user = new User();
            user.fromDTO(response.getUser()!);

            if (isError(user)) {
                throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, user as unknown as IUIError)
            }

            runInAction(() => {
                this.SaveAuth({
                    jwt: token,
                    userId: response.getUserId()!.getValue(),
                    user: user
                });
            })

            runInAction(() => {
                const entitlements1 = response.getEntitlementsList().map((e) => e.toLowerCase());
                this.entitlements = entitlements1
                localStorage.setItem(LOCAL_STORAGE_ENTITLEMENTS, JSON.stringify(entitlements1))
                localStorage.setItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT, JSON.stringify(response.getSubscriptionProduct()))
            });

            return Ok({
                jwt: token,
                userId: response.getUserId()!.getValue(),
                user: user
            } as AuthObj)
        } catch (error) {
            if (error instanceof grpcWeb.RpcError) {
                // Handle gRPC-Web error
                if (error.metadata[GRPC_CARTA_ERROR_HEADER]) {
                    console.log("metadata: ", error.metadata)
                    let errorStatus = error.metadata[GRPC_CARTA_ERROR_HEADER] as unknown as ErrorStatusDTO
                    let customerId = error.metadata["customer_id"]

                    return  Err(NewUIErrorV2(
                        ActionType.Authenticate,
                        EntityKind.Auth,
                        error,
                        `failed to send Google OAuth Information to Backend`,
                        ``,
                        errorStatus,
                        [customerId]
                    ));
                }
                // const errorCode = error.metadata.metadataMap[GRPC_CARTA_ERROR_HEADER]
            } else {
                // Handle other types of errors
            }
            // isErrorStatusDto()
            return Err(NewUIErrorV2(
                ActionType.Authenticate,
                EntityKind.Auth,
                error,
                `failed to send Google OAuth Information to Backend`,
            ));
        }
    }


    /**
     * Sends the completed OAuth2 details to the backend server to sign-up. The user won't have access to the application until they select the subscription plan
     * and then perform a login.
     * @param token
     * @constructor
     */
    SignUpGoogle = async (token: IGoogleOAuthTokenResponse, pricingSession: PricingSession | null): Promise<Result<SignUpResponse, UIErrorV2>> => {
        let profile = token.profileObj.intoDTO()
        if (isError(profile)) {
            return Err(NewUIErrorV2(
                ActionType.Authenticate,
                EntityKind.Auth,
                profile as IUIError,
                `failed to convert GoogleProfile to DTO - Err(Value = ${JSON.stringify(profile)})`
            ));
        }

        let tokenReq = token.tokenObj.intoDTO()
        if (isError(tokenReq)) {
            return Err(NewUIErrorV2(
                ActionType.Authenticate,
                EntityKind.Auth,
                tokenReq as IUIError,
                `failed to convert GoogleOAuthToken to DTO - Err(Value = ${JSON.stringify(tokenReq)})`
            ));
        }

        let signUpReq = new SignUpRequest();
        signUpReq.setProfile(profile as SignUpProfileDTO);
        signUpReq.setToken(tokenReq as GoogleOAuthTokenDTO);
        if (pricingSession) {
            let dto = new PricingSessionDto();
            dto.setPriceId(pricingSession.priceId);
            dto.setSessionId(pricingSession.sessionId);
            signUpReq.setPricingSession(dto)
        }

        // TODO: We need to send the subscription plan here

        try {
            const response: SignUpResponse =
                await authClient.signUp(signUpReq, undefined);

            if (!response.getSession() && !response.getCustomerId()) {
                return Err(NewUIErrorV2(
                    ActionType.Authenticate,
                    EntityKind.Auth,
                    `authentication session response from backend AND customer_id is nil - this should not happen`,
                ));
            }

            runInAction(() => {
                this.SaveAuthState({
                    state: AuthenticationStateEnum.SignedUpPendingSubscription,
                    payload: null,
                });
            })

            return Ok(response)
        } catch (error: any) {
            if (error instanceof grpcWeb.RpcError) {
                // Handle gRPC-Web error
                if (error.metadata[GRPC_CARTA_ERROR_HEADER]) {
                    let errorStatus = error.metadata[GRPC_CARTA_ERROR_HEADER] as unknown as ErrorStatusDTO
                    let customerId = error.metadata["customer_id"]

                    return  Err(NewUIErrorV2(
                        ActionType.Authenticate,
                        EntityKind.Auth,
                        error,
                        `failed to send Google OAuth Information to Backend`,
                        ``,
                        errorStatus,
                        [customerId]
                    ));
                }
                // const errorCode = error.metadata.metadataMap[GRPC_CARTA_ERROR_HEADER]
            } else {
                // Handle other types of errors
            }

            // Handle the error
            return Err(NewUIErrorV2(
                ActionType.Authenticate,
                EntityKind.Auth,
                error,
                `failed to google signup`,
            ));
        }
    }

    AuthCheck = async (): Promise<boolean> => {
        try {
            const lastHeartbeat = getLastHeartBeatTimeFromLocalStorage();
            if (lastHeartbeat != null) {
                const now = new Date();
                const diff = now.getTime() - lastHeartbeat.getTime();
                if (diff < 1000 * 60 * 60) {
                    console.debug("Heartbeat was less than 1 hour ago, skipping heartbeat check")
                    return true;
                }
            }

            const resp = await this.HeartBeat();
            if (resp.ok) {
                const heartbeat = resp.value

                if (heartbeat) {
                    // We can confirm the user object is still in storage, if not a call to getMe should work

                    let user = retrieveUserFromLocalStorage();
                    if (user == null) {
                        console.warn("User object not found in local storage despite successful heartbeat ... fetching getMe user.")

                        const fetchedUser = await this.userStore.GetMe();
                        if (fetchedUser.ok) {
                            user = fetchedUser.value;
                        } else {
                            throw new Error("Failed to fetch user from backend");
                        }
                    }

                    runInAction(() => {
                        this.SetAuthenticationState({
                            state: AuthenticationStateEnum.LoggedIn,
                            payload: {
                                user: user,
                            }
                        });
                    })

                    storeHeartBeatTimeInLocalStorage(new Date());

                    return true;
                } else {
                    return false
                }
            } else {
                // Logout
                console.error("Heartbeat failed, logging out user: ", resp.error)
                this.InvalidateAuthState();

                await this.Logout();

                return false
            }
        } catch (err) {
            console.error(err);

            this.InvalidateAuthState();

            await this.Logout();

            return false
        }
    };

    HeartBeat = async (): Promise<Result<boolean, IUIError>> => {
        let req = new HeartbeatRequest();

        try {
            const response = await authClient.heartbeat(req)

            console.log("Heartbeat response: ", response.getIsAlive())
            return Ok(response.getIsAlive())
        } catch (e) {
            return Err(NewUIErrorV2(
                ActionType.HeartBeat,
                EntityKind.Auth,
                `failed to send heartbeat to backend - Err(Value = ${JSON.stringify(e)})`
            ));
        }
    }

    Logout = async () => {
        let req = new LogoutRequest();
        try {
            await authClient.logout(req)
            this.InvalidateAuthState();
            removeAuthInfoFromLocalStorage()
        } catch (e) {
            return Err(NewUIErrorV2(
                ActionType.Logout,
                EntityKind.Auth,
                `failed to logout to backend - Err(Value = ${JSON.stringify(e)})`
            ));
        }
    }

    SaveAuth = (auth: AuthObj) => {
        this.SetAuthenticationState({
            state: AuthenticationStateEnum.LoggedIn,
            payload: {
                user: auth.user,
            }
        });

        storeAuthObjInLocalStorage(auth);
    }

    SaveAuthState = (auth: AuthenticationState) => {
        this.SetAuthenticationState(auth);
    }

    InvalidateAuthState = () => {
        this.SetAuthenticationState({
            state: AuthenticationStateEnum.LoggedOff,
            payload: null
        });

        removeAuthInfoFromLocalStorage();
    }

    get GetUser(): User | null {
        if (this.authenticationState.state === AuthenticationStateEnum.LoggedIn) {
            return retrieveUserFromLocalStorage()
        }

        console.log("authenticationState is not logged in", this.authenticationState)

        return null
    }

    get GetAuthenticationState(): AuthenticationState {
        return this.authenticationState
    }

    get GetEntitlements(): string[] {
        const res = localStorage.getItem(LOCAL_STORAGE_ENTITLEMENTS)
        if (res) {
            return JSON.parse(res)
        }

        return []
    }
}

export enum AuthenticationStateEnum {
    LoggedIn,
    LoggedOff,
    SignedUpPendingSubscription,
}

export interface AuthenticationState {
    state: AuthenticationStateEnum,
    payload: AuthPayload | null
}

interface AuthPayload {
    user: User,
}

export class GoogleOAuthToken implements IFromDTO<GoogleOAuthTokenDTO>, IIntoDTO<GoogleOAuthTokenDTO>, IValidator<GoogleOAuthToken> {
    private _accessToken: string = ""
    private _expiresAt: number = 0
    private _expiresIn: number = 0;
    private _idToken: string = "";
    private _firstIssuedAt: number = 0;
    private _idpId: string = "";
    private _tokenType: string = "";
    private _refreshToken: string = "";

    fromDTO(t: GoogleOAuthTokenDTO): void | IUIError {
        this._accessToken = t.getAccessToken();
        this._expiresAt = t.getExpiresAt();
        this._expiresIn = t.getExpiresIn();
        this._firstIssuedAt = t.getFirstIssuedAt();
        this._idpId = t.getIdpid();
        this._tokenType = t.getTokenType();
        this._refreshToken = t.getRefreshToken();
        this._idToken = t.getIdToken();

        return;
    }

    intoDTO(): GoogleOAuthTokenDTO | IUIError {
        let dto = new GoogleOAuthTokenDTO();
        dto.setAccessToken(this._accessToken);
        dto.setExpiresAt(this._expiresAt);
        dto.setExpiresIn(this._expiresIn);
        dto.setFirstIssuedAt(this._firstIssuedAt);
        dto.setIdToken(this._idToken);
        dto.setIdpid(this._idpId);
        dto.setTokenType(this._tokenType);
        dto.setRefreshToken(this._refreshToken);

        return dto;
    }

    validate(): IUIError | GoogleOAuthToken {
        if (this._accessToken === "") {
            return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "access_token is empty")
        }

        return this
    }

    sanitize(): IUIError | GoogleOAuthToken {
        return this
    }

    get accessToken(): string {
        return this._accessToken;
    }

    set accessToken(value: string) {
        this._accessToken = value;
    }

    get expiresAt(): number {
        return this._expiresAt;
    }

    set expiresAt(value: number) {
        this._expiresAt = value;
    }

    get expiresIn(): number {
        return this._expiresIn;
    }

    set expiresIn(value: number) {
        this._expiresIn = value;
    }

    get idToken(): string {
        return this._idToken;
    }

    set idToken(value: string) {
        this._idToken = value;
    }

    get firstIssuedAt(): number {
        return this._firstIssuedAt;
    }

    set firstIssuedAt(value: number) {
        this._firstIssuedAt = value;
    }

    get idpId(): string {
        return this._idpId;
    }

    set idpId(value: string) {
        this._idpId = value;
    }

    get tokenType(): string {
        return this._tokenType;
    }

    set tokenType(value: string) {
        this._tokenType = value;
    }

    get refreshToken(): string {
        return this._refreshToken;
    }

    set refreshToken(value: string) {
        this._refreshToken = value;
    }
}

export interface IGoogleAdditionalUserInfo {
    isNewUser: boolean,
    providerId: string,
    profile: {
        email: string,
        family_name: string,
        given_name: string,
        id: string,
        locale: string,
        name: string,
        picture: string,
        verified_email: boolean
    }
}

export interface IGoogleOAuthCredential {
    accessToken: string,
    idToken: string,
    providerId: string,
    signInMethod: string,
    token: string
}

export class GenericFederatedProfile implements IFromDTO<SignUpProfileDTO>, IValidator<GenericFederatedProfile> {
    private _providerId: string = "";
    private _email: string = "";
    private _familyName: string = "";
    private _name: string = "";
    private _givenName: string = "";
    private _imageUrl: string = "";
    private _locale: string = "";
    private _isVerified: boolean = false;
    private _isNewUser: boolean = false;
    private _provider: FederatedProviderDTO = FederatedProviderDTO.GOOGLE

    fromDTO(t: SignUpProfileDTO): void | IUIError {
        this._providerId = t.getProviderid();
        this._email = t.getEmail();
        this._familyName = t.getFamilyname();
        this._name = t.getName();
        this._givenName = t.getGivenname();
        this._imageUrl = t.getImageurl();
        this._provider = t.getProvider();
        return;
    }

    intoDTO(): IUIError | SignUpProfileDTO {
        let dto = new SignUpProfileDTO();
        dto.setProviderid(this._providerId);
        dto.setEmail(this._email);
        dto.setFamilyname(this._familyName);
        dto.setName(this._name);
        dto.setGivenname(this._givenName);
        dto.setImageurl(this._imageUrl);
        dto.setLocale(this._locale);
        dto.setIsverified(this._isVerified);
        dto.setProviderid(this._providerId);

        return dto;
    }

    validate(): IUIError | GenericFederatedProfile {
        if (this._providerId === "") {
            return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "google_id is empty")
        }
        if (this._email === "") {
            return NewUIErrorV2(ActionType.Validate, EntityKind.Auth, "email is empty")
        }

        return this
    }

    sanitize(): IUIError | GenericFederatedProfile {
        return this
    }

    get provider(): FederatedProviderDTO {
        return this._provider;
    }

    set provider(value: FederatedProviderDTO) {
        this._provider = value;
    }

    get providerId(): string {
        return this._providerId;
    }

    set providerId(value: string) {
        this._providerId = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get familyName(): string {
        return this._familyName;
    }

    set familyName(value: string) {
        this._familyName = value;
    }

    get name(): string {
        return this._name;
    }

    set name(value: string) {
        this._name = value;
    }

    get givenName(): string {
        return this._givenName;
    }

    set givenName(value: string) {
        this._givenName = value;
    }

    get imageUrl(): string {
        return this._imageUrl;
    }

    set imageUrl(value: string) {
        this._imageUrl = value;
    }

    get locale(): string {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
    }

    get isVerified(): boolean {
        return this._isVerified;
    }

    set isVerified(value: boolean) {
        this._isVerified = value;
    }

    get isNewUser(): boolean {
        return this._isNewUser;
    }

    set isNewUser(value: boolean) {
        this._isNewUser = value;
    }
}

interface InfoRequest {
    token_id: string
    profile: GenericFederatedProfile,
    token: GoogleOAuthToken
}

