import {TagDTO} from "../proto/tag_pb";
import {ActionType, InternalErrorTypes, IUIError, NewUIError, NewUIErrorV2,} from "../service/cartaError";
import {convertDateToTimestamp, convertTimestampToDate, IsUUIDValid, ListItem} from "../utils/utils";
import {UUID_DTO} from "../proto/utils_pb";
import {v4 as uuidv4} from "uuid";
import {IDisplayItem, Listable} from "./interfaces";
import {BaseModel, EntityKind} from "./BaseModel";
import {Err, Ok, Result} from "utils/result";
import {DEFAULT_TAG_COLOR} from "consts";

export interface ITag {
	_id: string,
	_userId: string,
	_tag: string,
	_color: string,
	_createdOn: Date,
	_updatedOn: Date,
	_archivedOn: Date
}

export class Tag extends BaseModel<Tag, TagDTO> implements Listable {
	private _tag: string;
	private _color?: string;
	private _createdOn: Date;
	private _updatedOn: Date;
	private _archivedOn?: Date;
	
	constructor() {
		super();
		const now = new Date();
		
		this._createdOn = now,
			this._updatedOn = now,
			this._tag = "",
			this.userId = "",
			this.id = uuidv4();
	}
	
	toListItem(): ListItem {
		return {
			id: this.id,
			title: this.tag,
			color: this.color,
		}
    }

	kind: EntityKind = EntityKind.Tag

	static init(userId: string): Tag {
		const now = new Date();
		let tag = new Tag();

		tag._createdOn = now;
		tag._updatedOn = now;
		tag._tag =  "";
		tag.userId = userId;
		tag.id = uuidv4();

		return tag
	}
	
	init(): Tag {
		return new Tag()
	}
	
	TYPE: EntityKind = EntityKind.Tag
	
	sanitize(): Tag {
		this.tag = this.tag.trim();
		this.color = this.color ? this.color.trim() : undefined;

		return this
	}
	validate(): Tag | IUIError {
		if (this.tag.length === 0) {
			const errMsg = "tag is empty";
			return NewUIErrorV2(
				ActionType.Validate,
				this.TYPE,
				errMsg, errMsg,errMsg
			)
		}

		if (this.color && this.color.length === 0) {
			const errMsg = "tag color is invalid, must be hex e.g. #ffffff"
			return NewUIErrorV2(
				ActionType.Validate,
				this.TYPE,
				errMsg, `tag color is invalid: got: ${this.color}`, errMsg
			)
		}

		return this.sanitize()
	}

	static validator(tag: Tag): Result<void, IUIError> {
		if (tag.tag.length === 0) {
			const errMsg = "tag is empty";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				tag.TYPE,
				errMsg, errMsg,errMsg
			))
		}

		if (tag.color && tag.color.length === 0) {
			const errMsg = "tag color is invalid, must be hex e.g. #ffffff"
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				tag.TYPE,
				errMsg, `tag color is invalid: got: ${tag.color}`, errMsg
			))
		}

		return Ok(undefined)
	}

	static TagTagValidator(item: string): Result<void, IUIError> {
		if (item.length === 0) {
			const errMsg = "tag is empty";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, errMsg,errMsg
			))
		}
		if (item.length > 200) {
			const errMsg = "title cannot exceed 200 characters";
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, errMsg,errMsg
			))
		}

		return Ok(undefined)
	}

	static TagColorValidator(color: string): Result<void, IUIError> {
		if (color.length === 0) {
			const errMsg = "tag color is invalid, must be hex e.g. #ffffff"
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, `tag color is invalid: got: ${color}`, errMsg
			))
		}
		if (color.length > 6) {
			const errMsg = "tag color must be valid hex. #ffffff"
			return Err<IUIError>(NewUIErrorV2(
				ActionType.Validate,
				EntityKind.Tag,
				errMsg, `tag color is invalid: got: ${color}`, errMsg
			))
		}

		return Ok(undefined)
	}

	static Validators: Array<(item: any) => Result<void, IUIError>> = [
		Tag.TagTagValidator,
		Tag.TagColorValidator
	]

	static fromJSON(t: ITag): Tag {
		let tag = new Tag();

		tag.id = t._id;
		tag.userId = t._userId;
		tag.tag = t._tag;
		tag.color = t._color;
		tag.createdOn = new Date(t._createdOn);
		tag.updatedOn = new Date(t._updatedOn);
		tag.archivedOn = (t._archivedOn) ? new Date(t._archivedOn) : undefined;

		return tag
	}

	clone(): Tag {
		let temp = Object.assign({}, this);
		let newItem = new Tag();

		newItem.id = temp.id
		newItem.userId = temp.userId
		newItem._tag = temp._tag
		newItem._color = temp._color
		newItem._createdOn = temp._createdOn
		newItem._updatedOn = temp._updatedOn
		newItem._archivedOn = temp._archivedOn

		return newItem
	}
	public fromDTO(dto: TagDTO): void | IUIError {
		let origin = "fromDTO";
		let errorKind = InternalErrorTypes.InvalidTagDTO;
		let id = "";
		let userId = "";

		if (dto.getId()) {
			if (dto.getId()!.getValue()) {
				id = dto.getId()!.getValue();
			} else {
				return NewUIError(
					origin,
					errorKind,
					`tagID is empty '' - tag: ${dto}"`
				);
			}
		} else {
			return NewUIError(
				origin,
				errorKind,
				`tagID is undefined '' - tag: ${dto}"`
			);
		}

		if (dto.getUserid()) {
			if (dto.getUserid()!.getValue()) {
				userId = dto.getUserid()!.getValue();
			} else {
				return NewUIError(
					origin,
					errorKind,
					`tag userID is empty '' - tag: ${dto}"`
				);
			}
		} else {
			return NewUIError(
				origin,
				errorKind,
				`tag userID is undefined '' - tag: ${dto}"`
			);
		}

		if (!dto.getTag()) {
			return NewUIError(
				origin,
				errorKind,
				`tag string is empty '' - tag: ${dto}"`
			);
		}

		if (!dto.getCreatedon()) {
			return NewUIError(
				origin,
				errorKind,
				`tag created date is empty '' - tag: ${dto}"`
			);
		}

		if (!dto.getUpdatedon()) {
			return NewUIError(
				origin,
				errorKind,
				`tag updated date is empty '' - tag: ${dto}"`
			);
		}

		const createdOn = convertTimestampToDate(dto.getCreatedon()!);
		const updatedOn = convertTimestampToDate(dto.getUpdatedon()!);
		const archivedOn: Date | undefined = dto.getArchivedon()
			? convertTimestampToDate(dto.getArchivedon()!)
			: undefined;

		this.id = id;
		this.userId = userId;
		this.tag = dto.getTag();
		this.color = dto.getColor();
		this.createdOn = createdOn;
		this.updatedOn = updatedOn;
		this.archivedOn = archivedOn;
	}

	intoDTO(): TagDTO | IUIError {
		let origin = "intoDTO";
		let errorKind = InternalErrorTypes.InvalidTag;
		if (!IsUUIDValid(this.id)) {
			return NewUIError(origin, errorKind, `id is not valid: ${this.id}`)
		}
		if (!IsUUIDValid(this.userId)) {
			return NewUIError(origin, errorKind, `user_id is not valid: ${this.userId}`)
		}

		let dto = new TagDTO();
		dto.setId(new UUID_DTO().setValue(this.id))
		dto.setUserid(new UUID_DTO().setValue(this.userId))
		dto.setTag(this._tag)
		dto.setColor(this.color ? this.color : DEFAULT_TAG_COLOR);
		dto.setCreatedon(convertDateToTimestamp(this.createdOn));
		dto.setUpdatedon(convertDateToTimestamp(this.updatedOn));
		if (this.archivedOn) {
			dto.setArchivedon(convertDateToTimestamp(this.archivedOn));
		} else {
			dto.setArchivedon(undefined);
		}

		return dto;
	}
	get tag(): string {
		return this._tag;
	}

	set tag(value: string) {
		this._tag = value;
	}

	get color(): string | undefined {
		return this._color;
	}

	set color(value: string | undefined) {
		this._color = value;
	}

	get archivedOn(): Date | undefined {
		return this._archivedOn;
	}

	set archivedOn(value: Date | undefined) {
		this._archivedOn = value;
	}

	toDisplayable(): IDisplayItem {
		return {
			id: this.id,
			title: this.tag,
			color: this.color,
		}
	}

	to1LineString(): String {
		return this.tag
	}
}

// export interface Tag {
// 	id: string;
// 	userId: string;
// 	tag: string;
// 	color?: string;
// 	createdOn: Date;
// 	updatedOn: Date;
// 	archivedOn?: Date;
// }

/// Validates a TagDTO to ensure all fields are available, if not null is returned
// export const convertDTOToTag = (tag: TagDTO): Tag | InternalError => {
// 	let id = "";
// 	let userId = "";
//
// 	if (tag.getId()) {
// 		if (tag.getId()!.getValue()) {
// 			id = tag.getId()!.getValue();
// 		} else {
// 			return NewInternalError(
// 				"sanitizeTagDTO",
// 				InternalErrorTypes.InvalidTag,
// 				`tagID is empty '' - tag: ${tag}"`
// 			);
// 		}
// 	} else {
// 		return NewInternalError(
// 			"sanitizeTagDTO",
// 			InternalErrorTypes.InvalidTag,
// 			`tagID is undefined '' - tag: ${tag}"`
// 		);
// 	}
//
// 	if (tag.getUserid()) {
// 		if (tag.getUserid()!.getValue()) {
// 			userId = tag.getUserid()!.getValue();
// 		} else {
// 			return NewInternalError(
// 				"sanitizeTagDTO",
// 				InternalErrorTypes.InvalidTag,
// 				`tag userID is empty '' - tag: ${tag}"`
// 			);
// 		}
// 	} else {
// 		return NewInternalError(
// 			"sanitizeTagDTO",
// 			InternalErrorTypes.InvalidTag,
// 			`tag userID is undefined '' - tag: ${tag}"`
// 		);
// 	}
//
// 	if (!tag.getTag()) {
// 		return NewInternalError(
// 			"sanitizeTagDTO",
// 			InternalErrorTypes.InvalidTag,
// 			`tag string is empty '' - tag: ${tag}"`
// 		);
// 	}
//
// 	if (!tag.getCreatedon()) {
// 		return NewInternalError(
// 			"sanitizeTagDTO",
// 			InternalErrorTypes.InvalidTag,
// 			`tag created date is empty '' - tag: ${tag}"`
// 		);
// 	}
//
// 	if (!tag.getUpdatedon()) {
// 		return NewInternalError(
// 			"sanitizeTagDTO",
// 			InternalErrorTypes.InvalidTag,
// 			`tag updated date is empty '' - tag: ${tag}"`
// 		);
// 	}
//
// 	const createdOn = convertTimestampToDate(tag.getCreatedon()!);
// 	const updatedOn = convertTimestampToDate(tag.getUpdatedon()!);
// 	const archivedOn: Date | undefined = tag.getArchivedon()
// 		? convertTimestampToDate(tag.getArchivedon()!)
// 		: undefined;
//
// 	return {
// 		id: id,
// 		userId: userId,
// 		tag: tag.getTag(),
// 		color: tag.getColor(),
// 		createdOn: createdOn,
// 		updatedOn: updatedOn,
// 		archivedOn: archivedOn,
// 	};
// };

// export const NewTagDTO = (): TagDTO => {
// 	const id = new UUID().setValue(uuidv4());
//
// 	let tag = new TagDTO();
// 	tag.setId(id);
//
// 	return tag;
// };
//
// export const NewTag = (): Tag => {
// 	const now = new Date();
//
// 	return {
// 		createdOn: now,
// 		updatedOn: now,
// 		tag: "",
// 		userId: "",
// 		id: uuidv4(),
// 	};
// };
