import { createContext, useState } from "react";

export enum AlertKind {
  None,
  Success,
  Info,
  Warn,
  Error,
}

export interface SnackbarInfo {
  alert: AlertKind;
  message: string;
  // This is called by default if the snackbar is closed
  onProceed?: () => void;
  // This is called only when the user presses UNDO or CANCEL
  onCancel?: () => void;
}

export const EMPTY_ALERT: SnackbarInfo = {
  alert: AlertKind.None,
  message: "",
};

export interface AlertType {
  alert: SnackbarInfo;
  setAlert: (alert: SnackbarInfo) => void;
  onProceed?: () => void;
  onCancel?: () => void;
}

export const DEFAULT_ALERT_TYPE: AlertType = {
  alert: EMPTY_ALERT,
  setAlert: (info) => {},
};

export const AlertContext = createContext<AlertType>(DEFAULT_ALERT_TYPE);
AlertContext.displayName = "AlertContext";

interface AlertProviderProps {
  children: React.ReactNode;
}

export const AlertProvider = (props: AlertProviderProps) => {
  const [alert, setAlert] = useState<SnackbarInfo>(EMPTY_ALERT);

  const handleClose = () => {
    setAlert(EMPTY_ALERT);
  };

  const handleOpen = (): boolean => {
    if (alert.alert !== AlertKind.None) {
      return true;
    }

    return false;
  };

  return (
    <AlertContext.Provider
      value={{
        alert: alert,
        onProceed: alert.onProceed,
        onCancel: alert.onCancel,
        setAlert: (info) => {
          setAlert(info);
        },
      }}
    >
      {props.children}
    </AlertContext.Provider>
  );
};
