import {lazy} from 'react';

// project import
import Loadable from 'components/Loadable';
import PublicLayout from "layout/Public";

// // // render - auth

const StripePricingTable = Loadable(lazy(() => import('pages/billing/pricing')));
const TOS = Loadable(lazy(() => import('pages/legal/tos')));
const Refund = Loadable(lazy(() => import('pages/legal/refund')));
const PublicContactForm = Loadable(lazy(() => import('pages/public/contact-us')));
const PrivacyPolicy = Loadable(lazy(() => import('pages/legal/privacy')));

// ==============================|| AUTH ROUTING ||============================== //
const PublicRoutes =
    {
        path: '/',
        children: [
            {
                path: '/',
                element: <PublicLayout/>,
                children: [
                    {
                        path: 'p',
                        children: [
                            {
                                path: 'privacy',
                                element: <PrivacyPolicy/>
                            },
                            {
                                path: 'pricing',
                                element: <StripePricingTable/>
                            },
                            {
                                path: 'tos',
                                element: <TOS title={"Terms of Service"}/>
                            },
                            {
                                path: 'refund',
                                element: <Refund/>
                            },
                            {
                                path: 'contact-us',
                                element: <PublicContactForm/>
                            },
                        ]
                    },
                ]
            }
        ]
    };

export default PublicRoutes;
