import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {Outlet} from 'react-router-dom';
import {Box, Breakpoint, Container, Drawer, Toolbar, useTheme} from '@mui/material';
import useContainerDimensions from 'hooks/useContainerDimensions';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Loader from 'components/Loader';
import useConfig from 'hooks/useConfig';
import {handlerDrawerOpen, useGetMenuMaster} from 'api/menu';
import {MenuOrientation} from 'types/config';
import useMediaQuery from "@mui/material/useMediaQuery";
import Footer from "layout/Dashboard/Footer";
import HorizontalBar from "layout/Dashboard/Drawer/HorizontalBar";
import Header from "layout/Public/Header";

const PublicLayout = () => {
    const theme = useTheme();
    const {menuMasterLoading} = useGetMenuMaster();
    const matchDownXL = useMediaQuery(theme.breakpoints.down('xl'));
    const downLG = useMediaQuery(theme.breakpoints.down('lg'));
    const {container, miniDrawer, menuOrientation} = useConfig();
    const isHorizontal = menuOrientation === MenuOrientation.HORIZONTAL && !downLG;
    const isBaseTopicPage = window.location.pathname === '/topics';
    const containerRef = useRef<HTMLDivElement>(null);

    // const [dimensions, setDimensions] = useState({ width:0, height: 0 });

    useEffect(() => {
        if (!miniDrawer) {
            handlerDrawerOpen(!matchDownXL);
        }
    }, [matchDownXL, miniDrawer]);

    //
    const showContainer = (): false | Breakpoint | undefined => {
        if (isBaseTopicPage) {
            return false;
        }

        if (container) {
            return 'xl';
        }

        return false;
    };


    if (menuMasterLoading) return <Loader/>;

    return (
        // <AuthGuard>
            <Box sx={{display: 'flex', width: '100%'}}>
                <Header/>
                {!isHorizontal ? <Drawer/> : <HorizontalBar/>}
                <Box component="main" sx={{width: 'calc(100% - 260px)', flexGrow: 1, p: {xs: 2, sm: 3}}}>
                    <Toolbar sx={{mt: isHorizontal ? 8 : 'inherit'}}/>
                    <Container
                        ref={containerRef}
                        maxWidth={showContainer()}
                        sx={{
                            ...(container && {px: {xs: 0, sm: 2}}),
                            position: 'relative',
                            minHeight: 'calc(100vh - 110px)',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        {/* <Breadcrumbs /> */}
                        <Outlet/>
                        <Footer/>
                    </Container>
                </Box>
                {/* <AddCustomer /> */}
            </Box>
        // </AuthGuard>
    );
};

export default PublicLayout;
