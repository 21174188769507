/**
 * @fileoverview gRPC-Web generated client stub for auth
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v3.20.3
// source: auth.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var utils_pb = require('./utils_pb.js')

var user_pb = require('./user_pb.js')

var stripe_pb = require('./stripe_pb.js')
const proto = {};
proto.auth = require('./auth_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.GrpcGoogleAuthServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.auth.GrpcGoogleAuthServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.SignUpRequest,
 *   !proto.auth.SignUpResponse>}
 */
const methodDescriptor_GrpcGoogleAuthService_signUp = new grpc.web.MethodDescriptor(
  '/auth.GrpcGoogleAuthService/signUp',
  grpc.web.MethodType.UNARY,
  proto.auth.SignUpRequest,
  proto.auth.SignUpResponse,
  /**
   * @param {!proto.auth.SignUpRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.SignUpResponse.deserializeBinary
);


/**
 * @param {!proto.auth.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.SignUpResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.SignUpResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.GrpcGoogleAuthServiceClient.prototype.signUp =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/signUp',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_signUp,
      callback);
};


/**
 * @param {!proto.auth.SignUpRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.SignUpResponse>}
 *     Promise that resolves to the response
 */
proto.auth.GrpcGoogleAuthServicePromiseClient.prototype.signUp =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/signUp',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_signUp);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LoginRequest,
 *   !proto.auth.LoginResponse>}
 */
const methodDescriptor_GrpcGoogleAuthService_login = new grpc.web.MethodDescriptor(
  '/auth.GrpcGoogleAuthService/login',
  grpc.web.MethodType.UNARY,
  proto.auth.LoginRequest,
  proto.auth.LoginResponse,
  /**
   * @param {!proto.auth.LoginRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LoginResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LoginResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LoginResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.GrpcGoogleAuthServiceClient.prototype.login =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/login',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_login,
      callback);
};


/**
 * @param {!proto.auth.LoginRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LoginResponse>}
 *     Promise that resolves to the response
 */
proto.auth.GrpcGoogleAuthServicePromiseClient.prototype.login =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/login',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_login);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.HeartbeatRequest,
 *   !proto.auth.HeartbeatResponse>}
 */
const methodDescriptor_GrpcGoogleAuthService_heartbeat = new grpc.web.MethodDescriptor(
  '/auth.GrpcGoogleAuthService/heartbeat',
  grpc.web.MethodType.UNARY,
  proto.auth.HeartbeatRequest,
  proto.auth.HeartbeatResponse,
  /**
   * @param {!proto.auth.HeartbeatRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.HeartbeatResponse.deserializeBinary
);


/**
 * @param {!proto.auth.HeartbeatRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.HeartbeatResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.HeartbeatResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.GrpcGoogleAuthServiceClient.prototype.heartbeat =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/heartbeat',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_heartbeat,
      callback);
};


/**
 * @param {!proto.auth.HeartbeatRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.HeartbeatResponse>}
 *     Promise that resolves to the response
 */
proto.auth.GrpcGoogleAuthServicePromiseClient.prototype.heartbeat =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/heartbeat',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_heartbeat);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.auth.LogoutRequest,
 *   !proto.auth.LogoutResponse>}
 */
const methodDescriptor_GrpcGoogleAuthService_logout = new grpc.web.MethodDescriptor(
  '/auth.GrpcGoogleAuthService/logout',
  grpc.web.MethodType.UNARY,
  proto.auth.LogoutRequest,
  proto.auth.LogoutResponse,
  /**
   * @param {!proto.auth.LogoutRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.auth.LogoutResponse.deserializeBinary
);


/**
 * @param {!proto.auth.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.auth.LogoutResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.auth.LogoutResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.auth.GrpcGoogleAuthServiceClient.prototype.logout =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/logout',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_logout,
      callback);
};


/**
 * @param {!proto.auth.LogoutRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.auth.LogoutResponse>}
 *     Promise that resolves to the response
 */
proto.auth.GrpcGoogleAuthServicePromiseClient.prototype.logout =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/auth.GrpcGoogleAuthService/logout',
      request,
      metadata || {},
      methodDescriptor_GrpcGoogleAuthService_logout);
};


module.exports = proto.auth;

