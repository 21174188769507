import {runInAction,} from "mobx";
import {
    ActionType,
    InternalErrorTypes,
    isError,
    IUIError,
    NewUIError,
    NewUIErrorV2,
    UIError,
} from "service/cartaError";
import {IsUUIDValid, sanitizeListOptions} from "utils/utils";
import {ListOptionsRequestDTO, UUID_DTO} from "proto/utils_pb";
import {
    CreateResourceRequest,
    CreateResourceResponse,
    GetResourceRequest,
    GetResourceResponse,
    ResourceDTO,
} from "proto/resource_pb";
import {ResourceServicePromiseClient} from "proto/resource_grpc_web_pb";
import {getUserId} from "service/AuthService";
import BaseStore from "./BaseStore";
import {ResourceGRPCImpl, ResourceService} from "service/ResourceService";
import {Resource} from "model/resource/Resource";
import {EntityKind, ListResponse} from "model/BaseModel";
import {Result} from "utils/result";
import {CARTA_PROXY_URL} from "consts";

const resourceClient = new ResourceServicePromiseClient(
    CARTA_PROXY_URL!,
    null,
    {'withCredentials': true}
);

export class ResourceStore extends BaseStore<Resource, ResourceDTO, ResourceGRPCImpl, ResourceService> {
    public service: ResourceService;

    constructor(service: ResourceService) {

        super(service, Resource);

        // makeObservable(this, {
        //     resourceMap: observable,
        //     Create: action,
        //     fetchResources: action,
        //     resources: computed,
        // });

        this.service = new ResourceService();
    }

    public getOrFetch = async (id: string): Promise<Resource | IUIError> => {
        let resource = this.map.get(id);

        if (resource) {
            return Promise.resolve(resource)
        } else {
            return this.fetchResource(id)
        }
    }

    public fetchResource = async (
        id: string
    ): Promise<Resource | IUIError> => {
        // TODO: Validate Resource
        const errorKind = InternalErrorTypes.CreateResource;
        const origin = "createResource";

        if (!IsUUIDValid(id)) {
            return NewUIError(origin, errorKind, `invalid id: ${id}`)
        }

        let req = new GetResourceRequest();
        req.setResourceId(new UUID_DTO().setValue(id))

        try {
            const response: GetResourceResponse = await resourceClient.get(
                req,
            );

            if (response.getResource() === undefined) {
                return NewUIError(origin, errorKind, "returned resources undefined");
            }

            let resource = new Resource();
            const err = resource.fromDTO(response.getResource() as ResourceDTO)

            if (!err) {
                await runInAction(async () => {
                    this.map.set(resource.id, resource);
                });
                return resource;
            } else {
                return NewUIError(
                    origin,
                    errorKind,
                    `failed to convert returned entity: ${resource}`,
                    undefined,
                    err
                );
            }
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `failed to create resource: %v ${err}`
            );
        }
    };

    public createResource = async (
        resource: Resource
    ): Promise<Resource | IUIError> => {
        const errorKind = InternalErrorTypes.CreateResource;
        const origin = "createResource";

        resource.userId = getUserId()

        let dto = resource.intoDTO();
        if (isError(dto)) {
            throw new UIError(InternalErrorTypes.InvalidResource, "failed to create resource", dto as UIError)
        }

        let req = new CreateResourceRequest();
        req.setResource(dto as ResourceDTO);

        try {
            const response: CreateResourceResponse = await resourceClient.create(
                req,
            );

            if (response.getResource() === undefined) {
                return NewUIError(origin, errorKind, "returned resources undefined");
            }

            let resource = new Resource();
            const err = resource.fromDTO(response.getResource() as ResourceDTO)

            if (!err) {
                await runInAction(async () => {
                    this.map.set(resource.id, resource);
                });
                return resource;
            } else {
                return NewUIError(
                    origin,
                    errorKind,
                    `failed to convert returned entity: ${resource}`,
                    undefined,
                    err
                );
            }
        } catch (err) {
            return NewUIError(
                origin,
                errorKind,
                `failed to create resource: %v ${err}`
            );
        }
    };

    // public deleteResource = async (id: string): Promise<void | IUIError> => {
    // 	return Promise.reject("unimplemented")
    // }

    // public fetchWithCache = async (shouldInvalidate: boolean, limit: number, offset: number, text?: string): Promise<Resource[] | IUIError> => {
    // 	if (shouldInvalidate || Object.keys(this.map).length < 1) {
    // 		return this.fetchResources(limit, offset, text)
    // 	}
    // 	return Promise.resolve(Array.from(this.map.values()))
    // }
    //
    public fetchResources = async (
        pageLimit: number,
        pageNumber: number,
        searchText?: string
    ): Promise<Result<ListResponse<Resource>, IUIError>> => {
        if (searchText !== undefined && searchText.length < 2) {
            throw NewUIErrorV2(ActionType.List, EntityKind.Resource, "search text must be at least 2 characters")
        }

        let opts: ListOptionsRequestDTO = new ListOptionsRequestDTO();
        opts.setLimit(pageLimit);
        opts.setOffset(pageNumber);
        if (searchText) {
            opts.setSearchtext(searchText);
            // TODO: set search field
        }

        const newOpts = sanitizeListOptions(opts);

        return this.List(newOpts)
    };

    public get resources(): Resource[] {
        return Array.from(this.map.values());
    }
}
