// source: auth.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
var user_pb = require('./user_pb.js');
goog.object.extend(proto, user_pb);
var stripe_pb = require('./stripe_pb.js');
goog.object.extend(proto, stripe_pb);
goog.exportSymbol('proto.auth.FederatedProviderDTO', null, global);
goog.exportSymbol('proto.auth.GoogleOAuthTokenDTO', null, global);
goog.exportSymbol('proto.auth.HeartbeatRequest', null, global);
goog.exportSymbol('proto.auth.HeartbeatResponse', null, global);
goog.exportSymbol('proto.auth.JWTTokenDTO', null, global);
goog.exportSymbol('proto.auth.LoginRequest', null, global);
goog.exportSymbol('proto.auth.LoginResponse', null, global);
goog.exportSymbol('proto.auth.LogoutRequest', null, global);
goog.exportSymbol('proto.auth.LogoutResponse', null, global);
goog.exportSymbol('proto.auth.SignUpProfileDTO', null, global);
goog.exportSymbol('proto.auth.SignUpRequest', null, global);
goog.exportSymbol('proto.auth.SignUpResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.HeartbeatRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.HeartbeatRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.HeartbeatRequest.displayName = 'proto.auth.HeartbeatRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.HeartbeatResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.HeartbeatResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.HeartbeatResponse.displayName = 'proto.auth.HeartbeatResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.GoogleOAuthTokenDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.GoogleOAuthTokenDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.GoogleOAuthTokenDTO.displayName = 'proto.auth.GoogleOAuthTokenDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.SignUpProfileDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.SignUpProfileDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.SignUpProfileDTO.displayName = 'proto.auth.SignUpProfileDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.JWTTokenDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.JWTTokenDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.JWTTokenDTO.displayName = 'proto.auth.JWTTokenDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.LoginRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.LoginRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.LoginRequest.displayName = 'proto.auth.LoginRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.SignUpRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.SignUpRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.SignUpRequest.displayName = 'proto.auth.SignUpRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.LogoutRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.LogoutRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.LogoutRequest.displayName = 'proto.auth.LogoutRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.LogoutResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.LogoutResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.LogoutResponse.displayName = 'proto.auth.LogoutResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.LoginResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.auth.LoginResponse.repeatedFields_, null);
};
goog.inherits(proto.auth.LoginResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.LoginResponse.displayName = 'proto.auth.LoginResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.auth.SignUpResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.auth.SignUpResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.auth.SignUpResponse.displayName = 'proto.auth.SignUpResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.HeartbeatRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.HeartbeatRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.HeartbeatRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.HeartbeatRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.HeartbeatRequest}
 */
proto.auth.HeartbeatRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.HeartbeatRequest;
  return proto.auth.HeartbeatRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.HeartbeatRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.HeartbeatRequest}
 */
proto.auth.HeartbeatRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.HeartbeatRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.HeartbeatRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.HeartbeatRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.HeartbeatRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.HeartbeatResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.HeartbeatResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.HeartbeatResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.HeartbeatResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    isAlive: jspb.Message.getBooleanFieldWithDefault(msg, 1, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.HeartbeatResponse}
 */
proto.auth.HeartbeatResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.HeartbeatResponse;
  return proto.auth.HeartbeatResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.HeartbeatResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.HeartbeatResponse}
 */
proto.auth.HeartbeatResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsAlive(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.HeartbeatResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.HeartbeatResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.HeartbeatResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.HeartbeatResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIsAlive();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
};


/**
 * optional bool is_alive = 1;
 * @return {boolean}
 */
proto.auth.HeartbeatResponse.prototype.getIsAlive = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.auth.HeartbeatResponse} returns this
 */
proto.auth.HeartbeatResponse.prototype.setIsAlive = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.GoogleOAuthTokenDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.GoogleOAuthTokenDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.GoogleOAuthTokenDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    accessToken: jspb.Message.getFieldWithDefault(msg, 1, ""),
    expiresAt: jspb.Message.getFieldWithDefault(msg, 2, 0),
    expiresIn: jspb.Message.getFieldWithDefault(msg, 3, 0),
    firstIssuedAt: jspb.Message.getFieldWithDefault(msg, 4, 0),
    idToken: jspb.Message.getFieldWithDefault(msg, 5, ""),
    idpid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    tokenType: jspb.Message.getFieldWithDefault(msg, 7, ""),
    refreshToken: jspb.Message.getFieldWithDefault(msg, 8, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.GoogleOAuthTokenDTO}
 */
proto.auth.GoogleOAuthTokenDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.GoogleOAuthTokenDTO;
  return proto.auth.GoogleOAuthTokenDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.GoogleOAuthTokenDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.GoogleOAuthTokenDTO}
 */
proto.auth.GoogleOAuthTokenDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAccessToken(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiresAt(value);
      break;
    case 3:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setExpiresIn(value);
      break;
    case 4:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setFirstIssuedAt(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdToken(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setIdpid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenType(value);
      break;
    case 8:
      var value = /** @type {string} */ (reader.readString());
      msg.setRefreshToken(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.GoogleOAuthTokenDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.GoogleOAuthTokenDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.GoogleOAuthTokenDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAccessToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getExpiresAt();
  if (f !== 0) {
    writer.writeUint32(
      2,
      f
    );
  }
  f = message.getExpiresIn();
  if (f !== 0) {
    writer.writeUint32(
      3,
      f
    );
  }
  f = message.getFirstIssuedAt();
  if (f !== 0) {
    writer.writeUint32(
      4,
      f
    );
  }
  f = message.getIdToken();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getIdpid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getTokenType();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getRefreshToken();
  if (f.length > 0) {
    writer.writeString(
      8,
      f
    );
  }
};


/**
 * optional string access_token = 1;
 * @return {string}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getAccessToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setAccessToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional uint32 expires_at = 2;
 * @return {number}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getExpiresAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setExpiresAt = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional uint32 expires_in = 3;
 * @return {number}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getExpiresIn = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {number} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setExpiresIn = function(value) {
  return jspb.Message.setProto3IntField(this, 3, value);
};


/**
 * optional uint32 first_issued_at = 4;
 * @return {number}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getFirstIssuedAt = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {number} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setFirstIssuedAt = function(value) {
  return jspb.Message.setProto3IntField(this, 4, value);
};


/**
 * optional string id_token = 5;
 * @return {string}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getIdToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setIdToken = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string idpId = 6;
 * @return {string}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getIdpid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setIdpid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string token_type = 7;
 * @return {string}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getTokenType = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setTokenType = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string refresh_token = 8;
 * @return {string}
 */
proto.auth.GoogleOAuthTokenDTO.prototype.getRefreshToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.GoogleOAuthTokenDTO} returns this
 */
proto.auth.GoogleOAuthTokenDTO.prototype.setRefreshToken = function(value) {
  return jspb.Message.setProto3StringField(this, 8, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.SignUpProfileDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.SignUpProfileDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.SignUpProfileDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.SignUpProfileDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    providerid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    familyname: jspb.Message.getFieldWithDefault(msg, 3, ""),
    name: jspb.Message.getFieldWithDefault(msg, 4, ""),
    givenname: jspb.Message.getFieldWithDefault(msg, 5, ""),
    imageurl: jspb.Message.getFieldWithDefault(msg, 6, ""),
    locale: jspb.Message.getFieldWithDefault(msg, 7, ""),
    isverified: jspb.Message.getBooleanFieldWithDefault(msg, 8, false),
    isnewuser: jspb.Message.getBooleanFieldWithDefault(msg, 9, false),
    provider: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.SignUpProfileDTO}
 */
proto.auth.SignUpProfileDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.SignUpProfileDTO;
  return proto.auth.SignUpProfileDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.SignUpProfileDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.SignUpProfileDTO}
 */
proto.auth.SignUpProfileDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProviderid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setFamilyname(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenname(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setImageurl(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setLocale(value);
      break;
    case 8:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsverified(value);
      break;
    case 9:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setIsnewuser(value);
      break;
    case 10:
      var value = /** @type {!proto.auth.FederatedProviderDTO} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.SignUpProfileDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.SignUpProfileDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.SignUpProfileDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.SignUpProfileDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProviderid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getFamilyname();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGivenname();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getImageurl();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getLocale();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getIsverified();
  if (f) {
    writer.writeBool(
      8,
      f
    );
  }
  f = message.getIsnewuser();
  if (f) {
    writer.writeBool(
      9,
      f
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      10,
      f
    );
  }
};


/**
 * optional string providerId = 1;
 * @return {string}
 */
proto.auth.SignUpProfileDTO.prototype.getProviderid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setProviderid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string email = 2;
 * @return {string}
 */
proto.auth.SignUpProfileDTO.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string familyName = 3;
 * @return {string}
 */
proto.auth.SignUpProfileDTO.prototype.getFamilyname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setFamilyname = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string name = 4;
 * @return {string}
 */
proto.auth.SignUpProfileDTO.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string givenName = 5;
 * @return {string}
 */
proto.auth.SignUpProfileDTO.prototype.getGivenname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setGivenname = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string imageUrl = 6;
 * @return {string}
 */
proto.auth.SignUpProfileDTO.prototype.getImageurl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setImageurl = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string locale = 7;
 * @return {string}
 */
proto.auth.SignUpProfileDTO.prototype.getLocale = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setLocale = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool isVerified = 8;
 * @return {boolean}
 */
proto.auth.SignUpProfileDTO.prototype.getIsverified = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 8, false));
};


/**
 * @param {boolean} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setIsverified = function(value) {
  return jspb.Message.setProto3BooleanField(this, 8, value);
};


/**
 * optional bool isNewUser = 9;
 * @return {boolean}
 */
proto.auth.SignUpProfileDTO.prototype.getIsnewuser = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 9, false));
};


/**
 * @param {boolean} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setIsnewuser = function(value) {
  return jspb.Message.setProto3BooleanField(this, 9, value);
};


/**
 * optional FederatedProviderDTO provider = 10;
 * @return {!proto.auth.FederatedProviderDTO}
 */
proto.auth.SignUpProfileDTO.prototype.getProvider = function() {
  return /** @type {!proto.auth.FederatedProviderDTO} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {!proto.auth.FederatedProviderDTO} value
 * @return {!proto.auth.SignUpProfileDTO} returns this
 */
proto.auth.SignUpProfileDTO.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.JWTTokenDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.JWTTokenDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.JWTTokenDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.JWTTokenDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    issueDate: (f = msg.getIssueDate()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    expiryDate: (f = msg.getExpiryDate()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.JWTTokenDTO}
 */
proto.auth.JWTTokenDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.JWTTokenDTO;
  return proto.auth.JWTTokenDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.JWTTokenDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.JWTTokenDTO}
 */
proto.auth.JWTTokenDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setIssueDate(value);
      break;
    case 3:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setExpiryDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.JWTTokenDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.JWTTokenDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.JWTTokenDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.JWTTokenDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIssueDate();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getExpiryDate();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.auth.JWTTokenDTO.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.JWTTokenDTO} returns this
 */
proto.auth.JWTTokenDTO.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional utils.TimestampDTO issue_date = 2;
 * @return {?proto.utils.TimestampDTO}
 */
proto.auth.JWTTokenDTO.prototype.getIssueDate = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 2));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.auth.JWTTokenDTO} returns this
*/
proto.auth.JWTTokenDTO.prototype.setIssueDate = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.JWTTokenDTO} returns this
 */
proto.auth.JWTTokenDTO.prototype.clearIssueDate = function() {
  return this.setIssueDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.JWTTokenDTO.prototype.hasIssueDate = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional utils.TimestampDTO expiry_date = 3;
 * @return {?proto.utils.TimestampDTO}
 */
proto.auth.JWTTokenDTO.prototype.getExpiryDate = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 3));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.auth.JWTTokenDTO} returns this
*/
proto.auth.JWTTokenDTO.prototype.setExpiryDate = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.JWTTokenDTO} returns this
 */
proto.auth.JWTTokenDTO.prototype.clearExpiryDate = function() {
  return this.setExpiryDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.JWTTokenDTO.prototype.hasExpiryDate = function() {
  return jspb.Message.getField(this, 3) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.LoginRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.LoginRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.LoginRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LoginRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profile: (f = msg.getProfile()) && proto.auth.SignUpProfileDTO.toObject(includeInstance, f),
    token: (f = msg.getToken()) && proto.auth.GoogleOAuthTokenDTO.toObject(includeInstance, f),
    provider: jspb.Message.getFieldWithDefault(msg, 4, 0),
    pricingSession: (f = msg.getPricingSession()) && stripe_pb.PricingSessionDto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.LoginRequest}
 */
proto.auth.LoginRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.LoginRequest;
  return proto.auth.LoginRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.LoginRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.LoginRequest}
 */
proto.auth.LoginRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenid(value);
      break;
    case 2:
      var value = new proto.auth.SignUpProfileDTO;
      reader.readMessage(value,proto.auth.SignUpProfileDTO.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 3:
      var value = new proto.auth.GoogleOAuthTokenDTO;
      reader.readMessage(value,proto.auth.GoogleOAuthTokenDTO.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 4:
      var value = /** @type {!proto.auth.FederatedProviderDTO} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 5:
      var value = new stripe_pb.PricingSessionDto;
      reader.readMessage(value,stripe_pb.PricingSessionDto.deserializeBinaryFromReader);
      msg.setPricingSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.LoginRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.LoginRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.LoginRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LoginRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.auth.SignUpProfileDTO.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.auth.GoogleOAuthTokenDTO.serializeBinaryToWriter
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getPricingSession();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      stripe_pb.PricingSessionDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tokenId = 1;
 * @return {string}
 */
proto.auth.LoginRequest.prototype.getTokenid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.LoginRequest} returns this
 */
proto.auth.LoginRequest.prototype.setTokenid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SignUpProfileDTO profile = 2;
 * @return {?proto.auth.SignUpProfileDTO}
 */
proto.auth.LoginRequest.prototype.getProfile = function() {
  return /** @type{?proto.auth.SignUpProfileDTO} */ (
    jspb.Message.getWrapperField(this, proto.auth.SignUpProfileDTO, 2));
};


/**
 * @param {?proto.auth.SignUpProfileDTO|undefined} value
 * @return {!proto.auth.LoginRequest} returns this
*/
proto.auth.LoginRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.LoginRequest} returns this
 */
proto.auth.LoginRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.LoginRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GoogleOAuthTokenDTO token = 3;
 * @return {?proto.auth.GoogleOAuthTokenDTO}
 */
proto.auth.LoginRequest.prototype.getToken = function() {
  return /** @type{?proto.auth.GoogleOAuthTokenDTO} */ (
    jspb.Message.getWrapperField(this, proto.auth.GoogleOAuthTokenDTO, 3));
};


/**
 * @param {?proto.auth.GoogleOAuthTokenDTO|undefined} value
 * @return {!proto.auth.LoginRequest} returns this
*/
proto.auth.LoginRequest.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.LoginRequest} returns this
 */
proto.auth.LoginRequest.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.LoginRequest.prototype.hasToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FederatedProviderDTO provider = 4;
 * @return {!proto.auth.FederatedProviderDTO}
 */
proto.auth.LoginRequest.prototype.getProvider = function() {
  return /** @type {!proto.auth.FederatedProviderDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.auth.FederatedProviderDTO} value
 * @return {!proto.auth.LoginRequest} returns this
 */
proto.auth.LoginRequest.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional stripe.PricingSessionDto pricing_session = 5;
 * @return {?proto.stripe.PricingSessionDto}
 */
proto.auth.LoginRequest.prototype.getPricingSession = function() {
  return /** @type{?proto.stripe.PricingSessionDto} */ (
    jspb.Message.getWrapperField(this, stripe_pb.PricingSessionDto, 5));
};


/**
 * @param {?proto.stripe.PricingSessionDto|undefined} value
 * @return {!proto.auth.LoginRequest} returns this
*/
proto.auth.LoginRequest.prototype.setPricingSession = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.LoginRequest} returns this
 */
proto.auth.LoginRequest.prototype.clearPricingSession = function() {
  return this.setPricingSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.LoginRequest.prototype.hasPricingSession = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.SignUpRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.SignUpRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.SignUpRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.SignUpRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    tokenid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profile: (f = msg.getProfile()) && proto.auth.SignUpProfileDTO.toObject(includeInstance, f),
    token: (f = msg.getToken()) && proto.auth.GoogleOAuthTokenDTO.toObject(includeInstance, f),
    provider: jspb.Message.getFieldWithDefault(msg, 5, 0),
    pricingSession: (f = msg.getPricingSession()) && stripe_pb.PricingSessionDto.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.SignUpRequest}
 */
proto.auth.SignUpRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.SignUpRequest;
  return proto.auth.SignUpRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.SignUpRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.SignUpRequest}
 */
proto.auth.SignUpRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setTokenid(value);
      break;
    case 2:
      var value = new proto.auth.SignUpProfileDTO;
      reader.readMessage(value,proto.auth.SignUpProfileDTO.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 3:
      var value = new proto.auth.GoogleOAuthTokenDTO;
      reader.readMessage(value,proto.auth.GoogleOAuthTokenDTO.deserializeBinaryFromReader);
      msg.setToken(value);
      break;
    case 5:
      var value = /** @type {!proto.auth.FederatedProviderDTO} */ (reader.readEnum());
      msg.setProvider(value);
      break;
    case 6:
      var value = new stripe_pb.PricingSessionDto;
      reader.readMessage(value,stripe_pb.PricingSessionDto.deserializeBinaryFromReader);
      msg.setPricingSession(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.SignUpRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.SignUpRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.SignUpRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.SignUpRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTokenid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.auth.SignUpProfileDTO.serializeBinaryToWriter
    );
  }
  f = message.getToken();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.auth.GoogleOAuthTokenDTO.serializeBinaryToWriter
    );
  }
  f = message.getProvider();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getPricingSession();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      stripe_pb.PricingSessionDto.serializeBinaryToWriter
    );
  }
};


/**
 * optional string tokenId = 1;
 * @return {string}
 */
proto.auth.SignUpRequest.prototype.getTokenid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpRequest} returns this
 */
proto.auth.SignUpRequest.prototype.setTokenid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional SignUpProfileDTO profile = 2;
 * @return {?proto.auth.SignUpProfileDTO}
 */
proto.auth.SignUpRequest.prototype.getProfile = function() {
  return /** @type{?proto.auth.SignUpProfileDTO} */ (
    jspb.Message.getWrapperField(this, proto.auth.SignUpProfileDTO, 2));
};


/**
 * @param {?proto.auth.SignUpProfileDTO|undefined} value
 * @return {!proto.auth.SignUpRequest} returns this
*/
proto.auth.SignUpRequest.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.SignUpRequest} returns this
 */
proto.auth.SignUpRequest.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.SignUpRequest.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional GoogleOAuthTokenDTO token = 3;
 * @return {?proto.auth.GoogleOAuthTokenDTO}
 */
proto.auth.SignUpRequest.prototype.getToken = function() {
  return /** @type{?proto.auth.GoogleOAuthTokenDTO} */ (
    jspb.Message.getWrapperField(this, proto.auth.GoogleOAuthTokenDTO, 3));
};


/**
 * @param {?proto.auth.GoogleOAuthTokenDTO|undefined} value
 * @return {!proto.auth.SignUpRequest} returns this
*/
proto.auth.SignUpRequest.prototype.setToken = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.SignUpRequest} returns this
 */
proto.auth.SignUpRequest.prototype.clearToken = function() {
  return this.setToken(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.SignUpRequest.prototype.hasToken = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional FederatedProviderDTO provider = 5;
 * @return {!proto.auth.FederatedProviderDTO}
 */
proto.auth.SignUpRequest.prototype.getProvider = function() {
  return /** @type {!proto.auth.FederatedProviderDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.auth.FederatedProviderDTO} value
 * @return {!proto.auth.SignUpRequest} returns this
 */
proto.auth.SignUpRequest.prototype.setProvider = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional stripe.PricingSessionDto pricing_session = 6;
 * @return {?proto.stripe.PricingSessionDto}
 */
proto.auth.SignUpRequest.prototype.getPricingSession = function() {
  return /** @type{?proto.stripe.PricingSessionDto} */ (
    jspb.Message.getWrapperField(this, stripe_pb.PricingSessionDto, 6));
};


/**
 * @param {?proto.stripe.PricingSessionDto|undefined} value
 * @return {!proto.auth.SignUpRequest} returns this
*/
proto.auth.SignUpRequest.prototype.setPricingSession = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.SignUpRequest} returns this
 */
proto.auth.SignUpRequest.prototype.clearPricingSession = function() {
  return this.setPricingSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.SignUpRequest.prototype.hasPricingSession = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.LogoutRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.LogoutRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.LogoutRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LogoutRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.LogoutRequest}
 */
proto.auth.LogoutRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.LogoutRequest;
  return proto.auth.LogoutRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.LogoutRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.LogoutRequest}
 */
proto.auth.LogoutRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.LogoutRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.LogoutRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.LogoutRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LogoutRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.LogoutResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.LogoutResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.LogoutResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LogoutResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.LogoutResponse}
 */
proto.auth.LogoutResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.LogoutResponse;
  return proto.auth.LogoutResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.LogoutResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.LogoutResponse}
 */
proto.auth.LogoutResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.LogoutResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.LogoutResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.LogoutResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LogoutResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.auth.LoginResponse.repeatedFields_ = [4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.LoginResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.LoginResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.LoginResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LoginResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    jwt: (f = msg.getJwt()) && proto.auth.JWTTokenDTO.toObject(includeInstance, f),
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    user: (f = msg.getUser()) && user_pb.UserDTO.toObject(includeInstance, f),
    entitlementsList: (f = jspb.Message.getRepeatedField(msg, 4)) == null ? undefined : f,
    subscriptionProduct: jspb.Message.getFieldWithDefault(msg, 5, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.LoginResponse}
 */
proto.auth.LoginResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.LoginResponse;
  return proto.auth.LoginResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.LoginResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.LoginResponse}
 */
proto.auth.LoginResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.auth.JWTTokenDTO;
      reader.readMessage(value,proto.auth.JWTTokenDTO.deserializeBinaryFromReader);
      msg.setJwt(value);
      break;
    case 2:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    case 3:
      var value = new user_pb.UserDTO;
      reader.readMessage(value,user_pb.UserDTO.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.addEntitlements(value);
      break;
    case 5:
      var value = /** @type {!proto.stripe.EnumSubscriptionProductDTO} */ (reader.readEnum());
      msg.setSubscriptionProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.LoginResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.LoginResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.LoginResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.LoginResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getJwt();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.auth.JWTTokenDTO.serializeBinaryToWriter
    );
  }
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      user_pb.UserDTO.serializeBinaryToWriter
    );
  }
  f = message.getEntitlementsList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      4,
      f
    );
  }
  f = message.getSubscriptionProduct();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
};


/**
 * optional JWTTokenDTO jwt = 1;
 * @return {?proto.auth.JWTTokenDTO}
 */
proto.auth.LoginResponse.prototype.getJwt = function() {
  return /** @type{?proto.auth.JWTTokenDTO} */ (
    jspb.Message.getWrapperField(this, proto.auth.JWTTokenDTO, 1));
};


/**
 * @param {?proto.auth.JWTTokenDTO|undefined} value
 * @return {!proto.auth.LoginResponse} returns this
*/
proto.auth.LoginResponse.prototype.setJwt = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.LoginResponse} returns this
 */
proto.auth.LoginResponse.prototype.clearJwt = function() {
  return this.setJwt(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.LoginResponse.prototype.hasJwt = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional utils.UUID_DTO user_id = 2;
 * @return {?proto.utils.UUID_DTO}
 */
proto.auth.LoginResponse.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 2));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.auth.LoginResponse} returns this
*/
proto.auth.LoginResponse.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.LoginResponse} returns this
 */
proto.auth.LoginResponse.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.LoginResponse.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional user.UserDTO user = 3;
 * @return {?proto.user.UserDTO}
 */
proto.auth.LoginResponse.prototype.getUser = function() {
  return /** @type{?proto.user.UserDTO} */ (
    jspb.Message.getWrapperField(this, user_pb.UserDTO, 3));
};


/**
 * @param {?proto.user.UserDTO|undefined} value
 * @return {!proto.auth.LoginResponse} returns this
*/
proto.auth.LoginResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.LoginResponse} returns this
 */
proto.auth.LoginResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.LoginResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * repeated string entitlements = 4;
 * @return {!Array<string>}
 */
proto.auth.LoginResponse.prototype.getEntitlementsList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 4));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.auth.LoginResponse} returns this
 */
proto.auth.LoginResponse.prototype.setEntitlementsList = function(value) {
  return jspb.Message.setField(this, 4, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.auth.LoginResponse} returns this
 */
proto.auth.LoginResponse.prototype.addEntitlements = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 4, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.auth.LoginResponse} returns this
 */
proto.auth.LoginResponse.prototype.clearEntitlementsList = function() {
  return this.setEntitlementsList([]);
};


/**
 * optional stripe.EnumSubscriptionProductDTO subscription_product = 5;
 * @return {!proto.stripe.EnumSubscriptionProductDTO}
 */
proto.auth.LoginResponse.prototype.getSubscriptionProduct = function() {
  return /** @type {!proto.stripe.EnumSubscriptionProductDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.stripe.EnumSubscriptionProductDTO} value
 * @return {!proto.auth.LoginResponse} returns this
 */
proto.auth.LoginResponse.prototype.setSubscriptionProduct = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.auth.SignUpResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.auth.SignUpResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.auth.SignUpResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.SignUpResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    customerId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    session: (f = msg.getSession()) && stripe_pb.CreateCheckoutSessionResponse.toObject(includeInstance, f),
    redirectToPricing: jspb.Message.getBooleanFieldWithDefault(msg, 4, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.auth.SignUpResponse}
 */
proto.auth.SignUpResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.auth.SignUpResponse;
  return proto.auth.SignUpResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.auth.SignUpResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.auth.SignUpResponse}
 */
proto.auth.SignUpResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerId(value);
      break;
    case 3:
      var value = new stripe_pb.CreateCheckoutSessionResponse;
      reader.readMessage(value,stripe_pb.CreateCheckoutSessionResponse.deserializeBinaryFromReader);
      msg.setSession(value);
      break;
    case 4:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setRedirectToPricing(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.auth.SignUpResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.auth.SignUpResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.auth.SignUpResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.auth.SignUpResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomerId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSession();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      stripe_pb.CreateCheckoutSessionResponse.serializeBinaryToWriter
    );
  }
  f = message.getRedirectToPricing();
  if (f) {
    writer.writeBool(
      4,
      f
    );
  }
};


/**
 * optional string customer_id = 1;
 * @return {string}
 */
proto.auth.SignUpResponse.prototype.getCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.auth.SignUpResponse} returns this
 */
proto.auth.SignUpResponse.prototype.setCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional stripe.CreateCheckoutSessionResponse session = 3;
 * @return {?proto.stripe.CreateCheckoutSessionResponse}
 */
proto.auth.SignUpResponse.prototype.getSession = function() {
  return /** @type{?proto.stripe.CreateCheckoutSessionResponse} */ (
    jspb.Message.getWrapperField(this, stripe_pb.CreateCheckoutSessionResponse, 3));
};


/**
 * @param {?proto.stripe.CreateCheckoutSessionResponse|undefined} value
 * @return {!proto.auth.SignUpResponse} returns this
*/
proto.auth.SignUpResponse.prototype.setSession = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.auth.SignUpResponse} returns this
 */
proto.auth.SignUpResponse.prototype.clearSession = function() {
  return this.setSession(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.auth.SignUpResponse.prototype.hasSession = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional bool redirect_to_pricing = 4;
 * @return {boolean}
 */
proto.auth.SignUpResponse.prototype.getRedirectToPricing = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 4, false));
};


/**
 * @param {boolean} value
 * @return {!proto.auth.SignUpResponse} returns this
 */
proto.auth.SignUpResponse.prototype.setRedirectToPricing = function(value) {
  return jspb.Message.setProto3BooleanField(this, 4, value);
};


/**
 * @enum {number}
 */
proto.auth.FederatedProviderDTO = {
  GOOGLE: 0,
  FACEBOOK: 1
};

goog.object.extend(exports, proto.auth);
