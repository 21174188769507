import {
    EnumReviewCardKindDTO,
    ErrorStatusDTO,
    ListOptionsRequestDTO,
    ProgressStateEnumDTO,
    TimestampDTO,
    UUID_DTO,
} from "proto/utils_pb";
import React, {useEffect, useState} from "react";
import {ResourceKind} from "proto/resource_pb";
import {CardDTO} from "proto/card_pb";
import {DateTime, ToRelativeOptions} from "luxon";
import {v4 as uuidv4, validate} from "uuid";
import {useLocation} from "react-router-dom";
import {ActionType, InternalErrorTypes, IUIError, NewUIError, NewUIErrorV2} from "service/cartaError";
import {ReviewSM2FilterConfigDTO} from "proto/reviewSM2_pb";
import {formatDistanceToNow} from "date-fns";
import {Ok, Result} from "utils/result";
import axios, {AxiosResponse} from "axios";
import {EntityKind} from "model/BaseModel";
import {Topic} from "model/topic";
import {TreeNode} from "components/tree/CustomTreeView";
import {TopicRelationshipEnumDTO} from "proto/topic_pb";
import {CardMedia, CardMediaSignedURL} from "model/CardMedia";
import {ReviewKindEnumDTO} from "proto/reviewManual_pb";
import {ObservableMap} from "mobx";
import {
    DEFAULT_LIMIT,
    DEFAULT_SM2_CARD_LIMIT,
    MAX_LIMIT,
    MAX_LIMIT_CARD,
    MAX_LIMIT_RESOURCE,
    MAX_LIMIT_REVIEW,
    MAX_LIMIT_REVIEW_MANUAL,
    MAX_LIMIT_TAG,
    MAX_LIMIT_TOPIC
} from "consts";


// This represents an Item that can be displayed. It must have a unique id identifier and a title to be displayed.
export interface SimpleDisplayItem {
    id: string;
    title: string;
    color?: string;
}

export function isErrorStatusDto(metadata: any): metadata is ErrorStatusDTO {
    return (
        typeof metadata === 'object' &&
        metadata !== null &&
        'code' in metadata &&
        'message' in metadata &&
        typeof metadata.code === 'string' &&
        typeof metadata.message === 'string'
    );
}

export function generateLightColor(): string {
    // Generating a random hue between 0 and 360
    const hue = Math.floor(Math.random() * 360);
    // Keeping saturation high for vibrancy, let's say 80%
    const saturation = 80;
    // Setting lightness above 70% to ensure the color is light enough
    const lightness = 70 + Math.random() * 30; // random lightness between 70% and 100%

    // Returning the HSL color string
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
}

// This represents an Item that can be displayed. It must have a unique id identifier and a title to be displayed.
export interface DisplayItem {
    id: string;
    title: string;
    // This is used primarily for tooltips
    description?: string;
    // The text field is a generic field for any text one would like to add.
    text?: string;
}

export const uploadFile = async (signedURL: string, file: File): Promise<Result<AxiosResponse, IUIError>> => {
    if (!file) throw NewUIErrorV2(ActionType.UploadS3, EntityKind.CardMediaSignedUrl, undefined, "file is undefined");

    const response = await axios.put(signedURL, file, {
        headers: {
            'Content-Type': file.type,
        },
    });
    return Ok(response);
};

export function MapInsertAtHead<K, V>(map: ObservableMap<K, V>, key: K, value: V): ObservableMap<K, V> {
    // Create a new Map with the new key-value pair
    const newMap = new ObservableMap<K, V>([[key, value]]);

    // Add all existing entries to the new Map
    for (const [existingKey, existingValue] of map) {
        newMap.set(existingKey, existingValue);
    }

    return newMap;
}

export async function handleFileUploads(signedURLs: CardMediaSignedURL[], cardMedia: CardMedia[]): Promise<Result<AxiosResponse, IUIError>> {
    for (const media of cardMedia) {
        console.log("rr media: ", media)
        console.log("rr signedURLs: ", signedURLs)
        let url = signedURLs.find((x) => x.mediaId === media.id);

        if (url) {
            

            try {
                await uploadFile(url.signedUrl, media.file);
            } catch (e) {
                throw NewUIErrorV2(ActionType.UploadS3, EntityKind.CardMediaSignedUrl, e, `unable to upload to s3: type: ${media.file.type} - size: ${media.file.size}`);
            }
        } else {
            throw NewUIErrorV2(ActionType.UploadS3, EntityKind.CardMediaSignedUrl, undefined, "url returned after create is empty/undefined");
        }
    }

    return Ok({} as AxiosResponse);
}

export const generateTopicTree = (topic: Topic, children: Topic[], parent?: Topic): TreeNode[] => {
    if (parent) {
        return [
            {
                id: parent.id,
                name: parent.topic,
                relationship: TopicRelationshipEnumDTO.PARENTCHILD,
                children: [
                    {
                        id: topic.id,
                        name: topic.topic,
                        relationship: TopicRelationshipEnumDTO.PARENTCHILD,
                        children: children.map((child) => {
                            return {
                                id: child.id,
                                relationship: TopicRelationshipEnumDTO.PARENTCHILD,
                                name: child.topic,
                            };
                        }),
                    },
                ],
            },
        ];
    } else {
        return [
            {
                id: topic.id,
                name: topic.topic,
                relationship: TopicRelationshipEnumDTO.PARENTCHILD,
                children: children.map((child) => {
                    return {
                        id: child.id,
                        relationship: TopicRelationshipEnumDTO.PARENTCHILD,
                        name: child.topic,
                    };
                }),
            },
        ];

    }
}

export const StringArrToLine = (items: string[]) => items.join(", ")
export const ValidateText = (input: string): boolean => {
    const allowedCharactersRegex = /^[a-zA-Z0-9!@#$%^&*()\{\}\[\]'":?. ]+$/;
    return allowedCharactersRegex.test(input);
};

export const NewUUID = (): string => {
    return uuidv4();
};

export const NewDefaultUUID = (): string => {
    return "00000000-0000-0000-0000-000000000000"
};

export const IsUUIDValid = (id: string): boolean => {
    return validate(id);
};

export const StringToUUID = (id: string): UUID_DTO | IUIError => {
    if (IsUUIDValid(id)) {
        let uuid = new UUID_DTO();
        uuid.setValue(id);
        return uuid;
    }

    return NewUIError("StringToUUID", InternalErrorTypes.InvalidUUID, "Invalid UUID");
}

export interface ResourceKindObj {
    title: string;
    kind: ResourceKind;
}

export interface ProgressStateObj {
    title: string;
    kind: ProgressStateEnumDTO;
}

export function useQuery() {
    const {search} = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const ProgressStateMap: Map<ProgressStateEnumDTO, string> = new Map<
    ProgressStateEnumDTO,
    string
>([
    [ProgressStateEnumDTO.IN_PROGRESS, "In Progress"],
    [ProgressStateEnumDTO.COMPLETE, "Complete"],
    [ProgressStateEnumDTO.NOT_STARTED, "Not Started"],
]);

export const ReviewKindMap: Map<ReviewKindEnumDTO, string> = new Map<
    ReviewKindEnumDTO,
    string
>([
    [ReviewKindEnumDTO.MANUAL, "Manual"],
    [ReviewKindEnumDTO.SM2, "SM2"],
]);


export interface FetchOpts {
    invalidate?: boolean;
    limit?: number;
    offset?: number;
}


// This interface allos implementors to define sensible defaults for the fetch options.
export interface IFetchOpts {
    parseDefaultFetchOpts(opts?: FetchOpts): FetchOpts;
}

export const ParseDefaultFetchOpts = (opts?: FetchOpts): FetchOpts => {
    if (opts === undefined) {
        return {
            invalidate: false,
            limit: DEFAULT_LIMIT,
            offset: 0
        };
    }

    let invalidate = (opts!.invalidate) ? true : false;
    let limit = (opts!.limit) ? opts!.limit : DEFAULT_LIMIT;
    let offset = (opts!.offset) ? opts!.offset : 0;

    return {
        invalidate: invalidate,
        limit: limit,
        offset: offset
    }
}

/**
 * Computes the hash of a given file using SHA-256.
 * @param file - The file to compute the hash for.
 * @returns A promise that resolves to the file's hash as a hexadecimal string.
 */
export const computeFileHash = async (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = async () => {
            try {
                const arrayBuffer = reader.result as ArrayBuffer;
                const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
                const hashArray = Array.from(new Uint8Array(hashBuffer));
                const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
                resolve(hashHex);
            } catch (error) {
                reject(error);
            }
        };

        reader.onerror = () => {
            reject(new Error('Error reading file'));
        };

        reader.readAsArrayBuffer(file);
    });
};

const generateHash = async (file: Blob): Promise<string> => {
    const arrayBuffer = await file.arrayBuffer();
    const hashBuffer = await crypto.subtle.digest('SHA-256', arrayBuffer);
    return Array.from(new Uint8Array(hashBuffer)).map(b => b.toString(16).padStart(2, '0')).join('');
};

// /**
//  * This custom hook is a wrapper around `useSearchParams()` that parses and
//  * serializes the search param value using the JSURL library, which permits any
//  * JavaScript value to be safely URL-encoded.
//  *
//  * It's a good example of how React hooks offer a great deal of flexibility when
//  * you compose them together!
//  *
//  * TODO: rethink the generic type here, users can put whatever they want in the
//  * URL, probably best to use runtime validation with a type predicate:
//  * https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
//  */
// export function useQueryParam<T>(
//     key: string
// ): [T | undefined, (newQuery: T, options?: NavigateOptions) => void] {
//   let [searchParams, setSearchParams] = useSearchParams();
//   let paramValue = searchParams.get(key);
//
//   let value = React.useMemo(() => JSURL.parse(paramValue), [paramValue]);
//
//   let setValue = React.useCallback(
//       (newValue: T, options?: NavigateOptions) => {
//         let newSearchParams = new URLSearchParams(searchParams);
//         newSearchParams.set(key, JSURL.stringify(newValue));
//         setSearchParams(newSearchParams, options);
//       },
//       [key, searchParams, setSearchParams]
//   );
//
//   return [value, setValue];
// }

// interface ListOption {
//     title: string,
//     option: ListOptionsRequestEnumDTO
// }
//
//
// export const ListOptionsMap: Map<ListOptionsRequestEnumDTO, ListOption> = new Map<ListOptionsRequestEnumDTO, ListOption>([
//     [ListOptionsRequestEnumDTO.RANDOM, {title: "Random", option: ListOptionsRequestEnumDTO.RANDOM}],
//     [ListOptionsRequestEnumDTO.MOSTRECENT, {title: "Recent", option: ListOptionsRequestEnumDTO.MOSTRECENT}],
//     [ListOptionsRequestEnumDTO.OLDEST, {title: "Oldest", option: ListOptionsRequestEnumDTO.OLDEST}],
// ])

export const sanitizeListOptions = (
    opts: ListOptionsRequestDTO
): ListOptionsRequestDTO => {
    if (opts.getLimit() > MAX_LIMIT) {
        opts.setLimit(MAX_LIMIT);
    }
    if (opts.getLimit() < 1) {
        opts.setLimit(DEFAULT_LIMIT);
    }
    if (opts.getOffset() < 0) {
        opts.setOffset(0);
    }

    return opts;
};

export const DefaultListOptions = (): ListOptionsRequestDTO => {
    let opts = new ListOptionsRequestDTO();
    opts.setLimit(DEFAULT_LIMIT);
    opts.setOffset(0);

    return opts;
};

export const IsCardFullyEmpty = (card: CardDTO): boolean => {
    // (card.getFront())

    return false;
};

export const Now = (): TimestampDTO => {
    let now = new Date();
    return new TimestampDTO().setSeconds(Math.round(now.getTime()));
};

export const formatDate = (t?: Date): string => {
    if (t) {
        return t.toDateString();
    }

    return "";
};


export const containsIllegalChars = (input: string): boolean => {
    const illegalChars = /[^a-zA-Z0-9!@#$%^&*()\{\}\[\]'":?. ]/;
    return illegalChars.test(input);
}

export const NewReviewSM2FilterConfigDTO = (): ReviewSM2FilterConfigDTO => {
    let config = new ReviewSM2FilterConfigDTO();
    config.setLimit(DEFAULT_SM2_CARD_LIMIT);
    config.setOffset(0);
    config.setId(new UUID_DTO().setValue(NewUUID()));
    config.setNewnessfactor(20)
    config.setCardkind(EnumReviewCardKindDTO.REVIEWCARDKIND_MULTI)

    return config;
}

export const convertDateToTimestamp = (date: Date): TimestampDTO => {
    const seconds = Math.floor(date.getTime() / 1000);
    const nanos = (date.getTime() % 1000) * 1e6;

    let timestamp = new TimestampDTO();
    timestamp.setSeconds(seconds);
    timestamp.setNanos(nanos);

    return timestamp
};

export const convertTimestampToDate = (t: TimestampDTO): Date => {
    return new Date(t.getSeconds() * 1000 + t.getNanos() / 1e6);
};

export const convertOptTimestampToDate = (t?: TimestampDTO): Date | undefined => {
    if (t) {
        return convertTimestampToDate(t);
    }

    return undefined;
}

export interface GRPCError {
    code: number;
    message: string;
}

export const UUIDDTOToID = (id: UUID_DTO): string => id.getValue();

// Hook
// T is a generic type for value parameter, our case this will be string
export function useDebounce<T>(value: T, delay: number): T {
    // State and setters for debounced value
    const [debouncedValue, setDebouncedValue] = useState<T>(value);
    useEffect(
        () => {
            // Update debounced value after delay
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);
            // Cancel the timeout if value changes (also on delay change or unmount)
            // This is how we prevent debounced value from updating if value is changed ...
            // .. within the delay period. Timeout gets cleared and restarted.
            return () => {
                clearTimeout(handler);
            };
        },
        [value, delay] // Only re-call effect if value or delay changes
    );
    return debouncedValue;
}

function getWindowDimensions(divisor: number = 1) {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width: width / divisor,
        height: height / divisor,
    };
}

export const commaSeparatedString = (items: string[]): string => {
    let str = String();
    items.forEach((item, index) => {
        if (index != items.length - 1) {
            str = str + `${item},`
        } else {
            str = str + `${item}`
        }
    })

    return str.toString()
}

export function useWindowDimensions(divisor: number = 1) {
    const [windowDimensions, setWindowDimensions] = useState(
        getWindowDimensions(divisor)
    );

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
}

export function removeItemFromArray<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) {
        arr.splice(index, 1);
    }
    return arr;
}

export interface ListItem {
    id: string;
    inputValue?: string;
    title: string;
    imageUrl?: string;
    metadata1?: string;
    metadata2?: string;
    metadata3?: string;
    metadata4?: string;
    color?: string;
}

export const formatOpts = (
    kind: ModelKind,
    limit?: number,
    offset?: number,
    text?: string
): ListOptionsRequestDTO => {
    let opts: ListOptionsRequestDTO = new ListOptionsRequestDTO();

    if (limit === undefined) {
        limit = DEFAULT_LIMIT
    }
    if (limit < 0) {
        limit = DEFAULT_LIMIT;
    }
    if (offset && offset < 0) {
        opts.setOffset(0);
    } else {
        opts.setOffset(0);
    }
    if (text) {
        opts.setSearchtext(text);
    }

    switch (kind) {
        case ModelKind.Card:
            if (limit && limit > MAX_LIMIT_CARD) {
                limit = MAX_LIMIT_CARD;
                opts.setLimit(limit);
            } else {
                opts.setLimit(MAX_LIMIT_CARD);
            }
            break;

        case ModelKind.Tag:
            if (limit && limit > MAX_LIMIT_TAG) {
                limit = MAX_LIMIT_TAG;
                opts.setLimit(limit);
            } else {
                opts.setLimit(MAX_LIMIT_TAG);
            }
            break;
        case ModelKind.Topic:
            if (limit && limit > MAX_LIMIT_TOPIC) {
                limit = MAX_LIMIT_TOPIC;
                opts.setLimit(limit);
            } else {
                opts.setLimit(MAX_LIMIT_TOPIC);
            }
            break;
        case ModelKind.Resource:
            if (limit && limit > MAX_LIMIT_RESOURCE) {
                limit = MAX_LIMIT_RESOURCE;
                opts.setLimit(limit);
            } else {
                opts.setLimit(MAX_LIMIT_RESOURCE);
            }
            break;
        case ModelKind.ReviewSM2:
            if (limit !== undefined && limit > DEFAULT_SM2_CARD_LIMIT) {
                limit = DEFAULT_SM2_CARD_LIMIT;
                opts.setLimit(limit);
            } else {
                opts.setLimit(limit);
            }
            break;
        case ModelKind.ReviewManual:
            if (limit && limit > MAX_LIMIT_REVIEW_MANUAL) {
                limit = MAX_LIMIT_REVIEW_MANUAL;
                opts.setLimit(limit);
            } else {
                opts.setLimit(MAX_LIMIT_REVIEW_MANUAL);
            }
            break;
        case ModelKind.Review:
            if (limit && limit > MAX_LIMIT_REVIEW) {
                limit = MAX_LIMIT_REVIEW;
                opts.setLimit(limit);
            } else {
                opts.setLimit(MAX_LIMIT_REVIEW);
            }
            break;
    }

    return opts;
};

export const dateTimeToRelative = (
    time: Date,
    relativeOpts?: ToRelativeOptions
): string => {
    const resp = DateTime.fromJSDate(time).toRelative(relativeOpts);
    if (resp) {
        return resp;
    }

    return time.toISOString();
};

export const dateTimeToRelativeV2 = (time: Date): string => {
    return formatDistanceToNow(time, {addSuffix: true});
}

export enum ModelKind {
    Card,
    Tag,
    Topic,
    Resource,
    ReviewSM2,
    ReviewManual,
    Review,
}
