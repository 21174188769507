// source: utils.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

goog.exportSymbol('proto.utils.CursorActionEnumDTO', null, global);
goog.exportSymbol('proto.utils.EnumCardKindDTO', null, global);
goog.exportSymbol('proto.utils.EnumReviewCardKindDTO', null, global);
goog.exportSymbol('proto.utils.ErrorDTO', null, global);
goog.exportSymbol('proto.utils.ErrorStatusDTO', null, global);
goog.exportSymbol('proto.utils.ListOptionsOrderDTO', null, global);
goog.exportSymbol('proto.utils.ListOptionsRequestDTO', null, global);
goog.exportSymbol('proto.utils.ListOptionsResponseDTO', null, global);
goog.exportSymbol('proto.utils.OrderFieldsDTO', null, global);
goog.exportSymbol('proto.utils.ProgressStateEnumDTO', null, global);
goog.exportSymbol('proto.utils.SearchFieldsDTO', null, global);
goog.exportSymbol('proto.utils.TimelineDurationDTO', null, global);
goog.exportSymbol('proto.utils.TimelineIntDTO', null, global);
goog.exportSymbol('proto.utils.TimelineTimeDTO', null, global);
goog.exportSymbol('proto.utils.TimestampDTO', null, global);
goog.exportSymbol('proto.utils.UUID_DTO', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.TimestampDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.TimestampDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.TimestampDTO.displayName = 'proto.utils.TimestampDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.UUID_DTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.UUID_DTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.UUID_DTO.displayName = 'proto.utils.UUID_DTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.ListOptionsRequestDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.ListOptionsRequestDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.ListOptionsRequestDTO.displayName = 'proto.utils.ListOptionsRequestDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.ListOptionsResponseDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.ListOptionsResponseDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.ListOptionsResponseDTO.displayName = 'proto.utils.ListOptionsResponseDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.TimelineIntDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.TimelineIntDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.TimelineIntDTO.displayName = 'proto.utils.TimelineIntDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.TimelineTimeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.TimelineTimeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.TimelineTimeDTO.displayName = 'proto.utils.TimelineTimeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.TimelineDurationDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.TimelineDurationDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.TimelineDurationDTO.displayName = 'proto.utils.TimelineDurationDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.utils.ErrorDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.utils.ErrorDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.utils.ErrorDTO.displayName = 'proto.utils.ErrorDTO';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.TimestampDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.TimestampDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.TimestampDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimestampDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    seconds: jspb.Message.getFieldWithDefault(msg, 1, 0),
    nanos: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.TimestampDTO}
 */
proto.utils.TimestampDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.TimestampDTO;
  return proto.utils.TimestampDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.TimestampDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.TimestampDTO}
 */
proto.utils.TimestampDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt64());
      msg.setSeconds(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setNanos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.TimestampDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.TimestampDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.TimestampDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimestampDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getSeconds();
  if (f !== 0) {
    writer.writeInt64(
      1,
      f
    );
  }
  f = message.getNanos();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional int64 seconds = 1;
 * @return {number}
 */
proto.utils.TimestampDTO.prototype.getSeconds = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.utils.TimestampDTO} returns this
 */
proto.utils.TimestampDTO.prototype.setSeconds = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 nanos = 2;
 * @return {number}
 */
proto.utils.TimestampDTO.prototype.getNanos = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.utils.TimestampDTO} returns this
 */
proto.utils.TimestampDTO.prototype.setNanos = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.UUID_DTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.UUID_DTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.UUID_DTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.UUID_DTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    value: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.UUID_DTO}
 */
proto.utils.UUID_DTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.UUID_DTO;
  return proto.utils.UUID_DTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.UUID_DTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.UUID_DTO}
 */
proto.utils.UUID_DTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.UUID_DTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.UUID_DTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.UUID_DTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.UUID_DTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValue();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string value = 1;
 * @return {string}
 */
proto.utils.UUID_DTO.prototype.getValue = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.utils.UUID_DTO} returns this
 */
proto.utils.UUID_DTO.prototype.setValue = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.ListOptionsRequestDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.ListOptionsRequestDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.ListOptionsRequestDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.ListOptionsRequestDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    limit: jspb.Message.getFieldWithDefault(msg, 1, 0),
    offset: jspb.Message.getFieldWithDefault(msg, 2, 0),
    searchtext: jspb.Message.getFieldWithDefault(msg, 3, ""),
    searchfield: jspb.Message.getFieldWithDefault(msg, 4, 0),
    orderfield: jspb.Message.getFieldWithDefault(msg, 5, 0),
    order: jspb.Message.getFieldWithDefault(msg, 6, 0),
    next: (f = msg.getNext()) && proto.utils.TimestampDTO.toObject(includeInstance, f),
    action: jspb.Message.getFieldWithDefault(msg, 8, 0),
    prev: (f = msg.getPrev()) && proto.utils.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.ListOptionsRequestDTO}
 */
proto.utils.ListOptionsRequestDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.ListOptionsRequestDTO;
  return proto.utils.ListOptionsRequestDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.ListOptionsRequestDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.ListOptionsRequestDTO}
 */
proto.utils.ListOptionsRequestDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setLimit(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setOffset(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setSearchtext(value);
      break;
    case 4:
      var value = /** @type {!proto.utils.SearchFieldsDTO} */ (reader.readEnum());
      msg.setSearchfield(value);
      break;
    case 5:
      var value = /** @type {!proto.utils.OrderFieldsDTO} */ (reader.readEnum());
      msg.setOrderfield(value);
      break;
    case 6:
      var value = /** @type {!proto.utils.ListOptionsOrderDTO} */ (reader.readEnum());
      msg.setOrder(value);
      break;
    case 7:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setNext(value);
      break;
    case 8:
      var value = /** @type {!proto.utils.CursorActionEnumDTO} */ (reader.readEnum());
      msg.setAction(value);
      break;
    case 9:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setPrev(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.ListOptionsRequestDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.ListOptionsRequestDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.ListOptionsRequestDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.ListOptionsRequestDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLimit();
  if (f !== 0) {
    writer.writeInt32(
      1,
      f
    );
  }
  f = message.getOffset();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 3));
  if (f != null) {
    writer.writeString(
      3,
      f
    );
  }
  f = /** @type {!proto.utils.SearchFieldsDTO} */ (jspb.Message.getField(message, 4));
  if (f != null) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = /** @type {!proto.utils.OrderFieldsDTO} */ (jspb.Message.getField(message, 5));
  if (f != null) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = /** @type {!proto.utils.ListOptionsOrderDTO} */ (jspb.Message.getField(message, 6));
  if (f != null) {
    writer.writeEnum(
      6,
      f
    );
  }
  f = message.getNext();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = /** @type {!proto.utils.CursorActionEnumDTO} */ (jspb.Message.getField(message, 8));
  if (f != null) {
    writer.writeEnum(
      8,
      f
    );
  }
  f = message.getPrev();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional int32 limit = 1;
 * @return {number}
 */
proto.utils.ListOptionsRequestDTO.prototype.getLimit = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.setLimit = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};


/**
 * optional int32 offset = 2;
 * @return {number}
 */
proto.utils.ListOptionsRequestDTO.prototype.getOffset = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.setOffset = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string searchText = 3;
 * @return {string}
 */
proto.utils.ListOptionsRequestDTO.prototype.getSearchtext = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.setSearchtext = function(value) {
  return jspb.Message.setField(this, 3, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.clearSearchtext = function() {
  return jspb.Message.setField(this, 3, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsRequestDTO.prototype.hasSearchtext = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional SearchFieldsDTO searchField = 4;
 * @return {!proto.utils.SearchFieldsDTO}
 */
proto.utils.ListOptionsRequestDTO.prototype.getSearchfield = function() {
  return /** @type {!proto.utils.SearchFieldsDTO} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.utils.SearchFieldsDTO} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.setSearchfield = function(value) {
  return jspb.Message.setField(this, 4, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.clearSearchfield = function() {
  return jspb.Message.setField(this, 4, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsRequestDTO.prototype.hasSearchfield = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional OrderFieldsDTO orderField = 5;
 * @return {!proto.utils.OrderFieldsDTO}
 */
proto.utils.ListOptionsRequestDTO.prototype.getOrderfield = function() {
  return /** @type {!proto.utils.OrderFieldsDTO} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.utils.OrderFieldsDTO} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.setOrderfield = function(value) {
  return jspb.Message.setField(this, 5, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.clearOrderfield = function() {
  return jspb.Message.setField(this, 5, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsRequestDTO.prototype.hasOrderfield = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional ListOptionsOrderDTO order = 6;
 * @return {!proto.utils.ListOptionsOrderDTO}
 */
proto.utils.ListOptionsRequestDTO.prototype.getOrder = function() {
  return /** @type {!proto.utils.ListOptionsOrderDTO} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {!proto.utils.ListOptionsOrderDTO} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.setOrder = function(value) {
  return jspb.Message.setField(this, 6, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.clearOrder = function() {
  return jspb.Message.setField(this, 6, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsRequestDTO.prototype.hasOrder = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * optional TimestampDTO next = 7;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.ListOptionsRequestDTO.prototype.getNext = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 7));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
*/
proto.utils.ListOptionsRequestDTO.prototype.setNext = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.clearNext = function() {
  return this.setNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsRequestDTO.prototype.hasNext = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional CursorActionEnumDTO action = 8;
 * @return {!proto.utils.CursorActionEnumDTO}
 */
proto.utils.ListOptionsRequestDTO.prototype.getAction = function() {
  return /** @type {!proto.utils.CursorActionEnumDTO} */ (jspb.Message.getFieldWithDefault(this, 8, 0));
};


/**
 * @param {!proto.utils.CursorActionEnumDTO} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.setAction = function(value) {
  return jspb.Message.setField(this, 8, value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.clearAction = function() {
  return jspb.Message.setField(this, 8, undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsRequestDTO.prototype.hasAction = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional TimestampDTO prev = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.ListOptionsRequestDTO.prototype.getPrev = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
*/
proto.utils.ListOptionsRequestDTO.prototype.setPrev = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.ListOptionsRequestDTO} returns this
 */
proto.utils.ListOptionsRequestDTO.prototype.clearPrev = function() {
  return this.setPrev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsRequestDTO.prototype.hasPrev = function() {
  return jspb.Message.getField(this, 9) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.ListOptionsResponseDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.ListOptionsResponseDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.ListOptionsResponseDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.ListOptionsResponseDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    next: (f = msg.getNext()) && proto.utils.TimestampDTO.toObject(includeInstance, f),
    prev: (f = msg.getPrev()) && proto.utils.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.ListOptionsResponseDTO}
 */
proto.utils.ListOptionsResponseDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.ListOptionsResponseDTO;
  return proto.utils.ListOptionsResponseDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.ListOptionsResponseDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.ListOptionsResponseDTO}
 */
proto.utils.ListOptionsResponseDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setNext(value);
      break;
    case 2:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setPrev(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.ListOptionsResponseDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.ListOptionsResponseDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.ListOptionsResponseDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.ListOptionsResponseDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getPrev();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimestampDTO next = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.ListOptionsResponseDTO.prototype.getNext = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.ListOptionsResponseDTO} returns this
*/
proto.utils.ListOptionsResponseDTO.prototype.setNext = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.ListOptionsResponseDTO} returns this
 */
proto.utils.ListOptionsResponseDTO.prototype.clearNext = function() {
  return this.setNext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsResponseDTO.prototype.hasNext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimestampDTO prev = 2;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.ListOptionsResponseDTO.prototype.getPrev = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 2));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.ListOptionsResponseDTO} returns this
*/
proto.utils.ListOptionsResponseDTO.prototype.setPrev = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.ListOptionsResponseDTO} returns this
 */
proto.utils.ListOptionsResponseDTO.prototype.clearPrev = function() {
  return this.setPrev(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.ListOptionsResponseDTO.prototype.hasPrev = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.TimelineIntDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.TimelineIntDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.TimelineIntDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimelineIntDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && proto.utils.TimestampDTO.toObject(includeInstance, f),
    value: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.utils.TimelineIntDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.TimelineIntDTO;
  return proto.utils.TimelineIntDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.TimelineIntDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.TimelineIntDTO}
 */
proto.utils.TimelineIntDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.TimelineIntDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.TimelineIntDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.TimelineIntDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimelineIntDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getValue();
  if (f !== 0) {
    writer.writeInt32(
      2,
      f
    );
  }
};


/**
 * optional TimestampDTO timestamp = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.TimelineIntDTO.prototype.getTimestamp = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.TimelineIntDTO} returns this
*/
proto.utils.TimelineIntDTO.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.TimelineIntDTO} returns this
 */
proto.utils.TimelineIntDTO.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.TimelineIntDTO.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional int32 value = 2;
 * @return {number}
 */
proto.utils.TimelineIntDTO.prototype.getValue = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.utils.TimelineIntDTO} returns this
 */
proto.utils.TimelineIntDTO.prototype.setValue = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.TimelineTimeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.TimelineTimeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.TimelineTimeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimelineTimeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && proto.utils.TimestampDTO.toObject(includeInstance, f),
    time: (f = msg.getTime()) && proto.utils.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.TimelineTimeDTO}
 */
proto.utils.TimelineTimeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.TimelineTimeDTO;
  return proto.utils.TimelineTimeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.TimelineTimeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.TimelineTimeDTO}
 */
proto.utils.TimelineTimeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setTime(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.TimelineTimeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.TimelineTimeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.TimelineTimeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimelineTimeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getTime();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimestampDTO timestamp = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.TimelineTimeDTO.prototype.getTimestamp = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.TimelineTimeDTO} returns this
*/
proto.utils.TimelineTimeDTO.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.TimelineTimeDTO} returns this
 */
proto.utils.TimelineTimeDTO.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.TimelineTimeDTO.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimestampDTO time = 2;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.TimelineTimeDTO.prototype.getTime = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 2));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.TimelineTimeDTO} returns this
*/
proto.utils.TimelineTimeDTO.prototype.setTime = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.TimelineTimeDTO} returns this
 */
proto.utils.TimelineTimeDTO.prototype.clearTime = function() {
  return this.setTime(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.TimelineTimeDTO.prototype.hasTime = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.TimelineDurationDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.TimelineDurationDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.TimelineDurationDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimelineDurationDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    timestamp: (f = msg.getTimestamp()) && proto.utils.TimestampDTO.toObject(includeInstance, f),
    duration: (f = msg.getDuration()) && proto.utils.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.TimelineDurationDTO}
 */
proto.utils.TimelineDurationDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.TimelineDurationDTO;
  return proto.utils.TimelineDurationDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.TimelineDurationDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.TimelineDurationDTO}
 */
proto.utils.TimelineDurationDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setTimestamp(value);
      break;
    case 2:
      var value = new proto.utils.TimestampDTO;
      reader.readMessage(value,proto.utils.TimestampDTO.deserializeBinaryFromReader);
      msg.setDuration(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.TimelineDurationDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.TimelineDurationDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.TimelineDurationDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.TimelineDurationDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTimestamp();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getDuration();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.utils.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional TimestampDTO timestamp = 1;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.TimelineDurationDTO.prototype.getTimestamp = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 1));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.TimelineDurationDTO} returns this
*/
proto.utils.TimelineDurationDTO.prototype.setTimestamp = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.TimelineDurationDTO} returns this
 */
proto.utils.TimelineDurationDTO.prototype.clearTimestamp = function() {
  return this.setTimestamp(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.TimelineDurationDTO.prototype.hasTimestamp = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional TimestampDTO duration = 2;
 * @return {?proto.utils.TimestampDTO}
 */
proto.utils.TimelineDurationDTO.prototype.getDuration = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, proto.utils.TimestampDTO, 2));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.utils.TimelineDurationDTO} returns this
*/
proto.utils.TimelineDurationDTO.prototype.setDuration = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.utils.TimelineDurationDTO} returns this
 */
proto.utils.TimelineDurationDTO.prototype.clearDuration = function() {
  return this.setDuration(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.utils.TimelineDurationDTO.prototype.hasDuration = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.utils.ErrorDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.utils.ErrorDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.utils.ErrorDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.ErrorDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    messageInternal: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.utils.ErrorDTO}
 */
proto.utils.ErrorDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.utils.ErrorDTO;
  return proto.utils.ErrorDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.utils.ErrorDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.utils.ErrorDTO}
 */
proto.utils.ErrorDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setMessageInternal(value);
      break;
    case 2:
      var value = /** @type {!proto.utils.ErrorStatusDTO} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.utils.ErrorDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.utils.ErrorDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.utils.ErrorDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.utils.ErrorDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMessageInternal();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string message_internal = 1;
 * @return {string}
 */
proto.utils.ErrorDTO.prototype.getMessageInternal = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.utils.ErrorDTO} returns this
 */
proto.utils.ErrorDTO.prototype.setMessageInternal = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional ErrorStatusDTO status = 2;
 * @return {!proto.utils.ErrorStatusDTO}
 */
proto.utils.ErrorDTO.prototype.getStatus = function() {
  return /** @type {!proto.utils.ErrorStatusDTO} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.utils.ErrorStatusDTO} value
 * @return {!proto.utils.ErrorDTO} returns this
 */
proto.utils.ErrorDTO.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * @enum {number}
 */
proto.utils.EnumCardKindDTO = {
  CARDKIND_STANDARD: 0,
  CARDKIND_LANGUAGE: 1
};

/**
 * @enum {number}
 */
proto.utils.EnumReviewCardKindDTO = {
  REVIEWCARDKIND_STANDARD: 0,
  REVIEWCARDKIND_LANGUAGE: 1,
  REVIEWCARDKIND_MULTI: 2
};

/**
 * @enum {number}
 */
proto.utils.ProgressStateEnumDTO = {
  NOT_STARTED: 0,
  IN_PROGRESS: 1,
  COMPLETE: 2
};

/**
 * @enum {number}
 */
proto.utils.ListOptionsOrderDTO = {
  ASC: 0,
  DESC: 2
};

/**
 * @enum {number}
 */
proto.utils.CursorActionEnumDTO = {
  CURSORACTION_GOPREVIOUS: 0,
  CURSORACTION_GONEXT: 1,
  CURSORACTION_GETEXACT: 2
};

/**
 * @enum {number}
 */
proto.utils.SearchFieldsDTO = {
  SEARCHFIELD_CREATEDON: 0,
  SEARCHFIELD_UPDATEDON: 1,
  SEARCHFIELDTAG_TAG: 2,
  SEARCHFIELDTOPIC_TOPIC: 3,
  SEARCHFIELDCARD_FRONTBACK: 4,
  SEARCHFIELDREVIEWMANUAL_NAMEDESCRIPTION: 5,
  SEARCHFIELDREVIEWMANUAL_CARDFRONTBACK: 6,
  SEARCHFIELDREVIEWSM2_NAME: 7,
  SEARCHFIELDRESOURCETITLE: 8,
  SEARCHFIELDRESOURCEAUTHOR: 9
};

/**
 * @enum {number}
 */
proto.utils.OrderFieldsDTO = {
  ORDERFIELD_CREATEDON: 0,
  ORDERFIELD_UPDATEDON: 1,
  ORDERFIELDTAG_TAG: 2,
  ORDERFIELDTOPIC_TOPIC: 3,
  ORDERFIELDCARD_FRONT: 4,
  ORDERFIELDREVIEWMANUAL_NAME: 5,
  ORDERFIELDREVIEWSM2_NAME: 6
};

/**
 * @enum {number}
 */
proto.utils.ErrorStatusDTO = {
  AUTH_REDIRECT_LOGIN_WITH_DIFFERENT_PROVIDER: 0,
  AUTH_REDIRECT_LOGIN: 1,
  AUTH_REDIRECT_REGISTER: 2,
  AUTH_RETRY: 3,
  AUTH_MISSING_EMAIL: 4,
  AUTH_UNVERIFIED_EMAIL: 5,
  AUTH_INVALID_EMAIL: 6,
  AUTH_EMAIL_EXISTS: 7,
  AUTH_UNSUPPORTED_PROVIDER: 8,
  AUTH_MISSING_USER_SUBSCRIPTION: 9,
  AUTH_MISSING_USER: 10,
  CARD_MAX_LIMIT_EXCEEDED: 11,
  UNDEFINED_ERROR: 999
};

goog.object.extend(exports, proto.utils);
