// source: user.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = (function() { return this || window || global || self || Function('return this')(); }).call(null);

var utils_pb = require('./utils_pb.js');
goog.object.extend(proto, utils_pb);
var stripe_pb = require('./stripe_pb.js');
goog.object.extend(proto, stripe_pb);
goog.exportSymbol('proto.user.GetAttributesRequest', null, global);
goog.exportSymbol('proto.user.GetMeRequest', null, global);
goog.exportSymbol('proto.user.GetMeResponse', null, global);
goog.exportSymbol('proto.user.GetUserRequest', null, global);
goog.exportSymbol('proto.user.GetUserResponse', null, global);
goog.exportSymbol('proto.user.UpdateUserRequest', null, global);
goog.exportSymbol('proto.user.UserAttributeDTO', null, global);
goog.exportSymbol('proto.user.UserDTO', null, global);
goog.exportSymbol('proto.user.UserSubscriptionDTO', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.UpdateUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.UpdateUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.UpdateUserRequest.displayName = 'proto.user.UpdateUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.UserAttributeDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.UserAttributeDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.UserAttributeDTO.displayName = 'proto.user.UserAttributeDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.UserSubscriptionDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.UserSubscriptionDTO.repeatedFields_, null);
};
goog.inherits(proto.user.UserSubscriptionDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.UserSubscriptionDTO.displayName = 'proto.user.UserSubscriptionDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.UserDTO = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.UserDTO, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.UserDTO.displayName = 'proto.user.UserDTO';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetMeRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetMeRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetMeRequest.displayName = 'proto.user.GetMeRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetAttributesRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetAttributesRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetAttributesRequest.displayName = 'proto.user.GetAttributesRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetMeResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.user.GetMeResponse.repeatedFields_, null);
};
goog.inherits(proto.user.GetMeResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetMeResponse.displayName = 'proto.user.GetMeResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetUserRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetUserRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetUserRequest.displayName = 'proto.user.GetUserRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.user.GetUserResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.user.GetUserResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.user.GetUserResponse.displayName = 'proto.user.GetUserResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.UpdateUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.UpdateUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.UpdateUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UpdateUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    displayName: jspb.Message.getFieldWithDefault(msg, 1, ""),
    bio: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.UpdateUserRequest}
 */
proto.user.UpdateUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.UpdateUserRequest;
  return proto.user.UpdateUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.UpdateUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.UpdateUserRequest}
 */
proto.user.UpdateUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setBio(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.UpdateUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.UpdateUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.UpdateUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UpdateUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getBio();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string display_name = 1;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string bio = 2;
 * @return {string}
 */
proto.user.UpdateUserRequest.prototype.getBio = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UpdateUserRequest} returns this
 */
proto.user.UpdateUserRequest.prototype.setBio = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.UserAttributeDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.user.UserAttributeDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.UserAttributeDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserAttributeDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    hascompletedintrotour: jspb.Message.getBooleanFieldWithDefault(msg, 1, false),
    themepreference: jspb.Message.getFieldWithDefault(msg, 2, ""),
    emailnotifications: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    languagepreference: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.UserAttributeDTO}
 */
proto.user.UserAttributeDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.UserAttributeDTO;
  return proto.user.UserAttributeDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.UserAttributeDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.UserAttributeDTO}
 */
proto.user.UserAttributeDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setHascompletedintrotour(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setThemepreference(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setEmailnotifications(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setLanguagepreference(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.UserAttributeDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.UserAttributeDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.UserAttributeDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserAttributeDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getHascompletedintrotour();
  if (f) {
    writer.writeBool(
      1,
      f
    );
  }
  f = message.getThemepreference();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmailnotifications();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getLanguagepreference();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional bool hasCompletedIntroTour = 1;
 * @return {boolean}
 */
proto.user.UserAttributeDTO.prototype.getHascompletedintrotour = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 1, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.UserAttributeDTO} returns this
 */
proto.user.UserAttributeDTO.prototype.setHascompletedintrotour = function(value) {
  return jspb.Message.setProto3BooleanField(this, 1, value);
};


/**
 * optional string themePreference = 2;
 * @return {string}
 */
proto.user.UserAttributeDTO.prototype.getThemepreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserAttributeDTO} returns this
 */
proto.user.UserAttributeDTO.prototype.setThemepreference = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional bool emailNotifications = 3;
 * @return {boolean}
 */
proto.user.UserAttributeDTO.prototype.getEmailnotifications = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.user.UserAttributeDTO} returns this
 */
proto.user.UserAttributeDTO.prototype.setEmailnotifications = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional string languagePreference = 4;
 * @return {string}
 */
proto.user.UserAttributeDTO.prototype.getLanguagepreference = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserAttributeDTO} returns this
 */
proto.user.UserAttributeDTO.prototype.setLanguagepreference = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.UserSubscriptionDTO.repeatedFields_ = [3,4];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.UserSubscriptionDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.user.UserSubscriptionDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.UserSubscriptionDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserSubscriptionDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    stripeSubscriptionId: jspb.Message.getFieldWithDefault(msg, 1, ""),
    status: jspb.Message.getFieldWithDefault(msg, 2, 0),
    productsList: jspb.Message.toObjectList(msg.getProductsList(),
    stripe_pb.StripeProductDto.toObject, includeInstance),
    priceList: jspb.Message.toObjectList(msg.getPriceList(),
    stripe_pb.StripePriceDto.toObject, includeInstance),
    createdOn: (f = msg.getCreatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    startDate: (f = msg.getStartDate()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    endDate: (f = msg.getEndDate()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.UserSubscriptionDTO}
 */
proto.user.UserSubscriptionDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.UserSubscriptionDTO;
  return proto.user.UserSubscriptionDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.UserSubscriptionDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.UserSubscriptionDTO}
 */
proto.user.UserSubscriptionDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeSubscriptionId(value);
      break;
    case 2:
      var value = /** @type {!proto.stripe.EnumSubscriptionStatusDTO} */ (reader.readEnum());
      msg.setStatus(value);
      break;
    case 3:
      var value = new stripe_pb.StripeProductDto;
      reader.readMessage(value,stripe_pb.StripeProductDto.deserializeBinaryFromReader);
      msg.addProducts(value);
      break;
    case 4:
      var value = new stripe_pb.StripePriceDto;
      reader.readMessage(value,stripe_pb.StripePriceDto.deserializeBinaryFromReader);
      msg.addPrice(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setStartDate(value);
      break;
    case 11:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setEndDate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.UserSubscriptionDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.UserSubscriptionDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.UserSubscriptionDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserSubscriptionDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getStripeSubscriptionId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getStatus();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
  f = message.getProductsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      stripe_pb.StripeProductDto.serializeBinaryToWriter
    );
  }
  f = message.getPriceList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      4,
      f,
      stripe_pb.StripePriceDto.serializeBinaryToWriter
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getStartDate();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getEndDate();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional string stripe_subscription_id = 1;
 * @return {string}
 */
proto.user.UserSubscriptionDTO.prototype.getStripeSubscriptionId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserSubscriptionDTO} returns this
 */
proto.user.UserSubscriptionDTO.prototype.setStripeSubscriptionId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional stripe.EnumSubscriptionStatusDTO status = 2;
 * @return {!proto.stripe.EnumSubscriptionStatusDTO}
 */
proto.user.UserSubscriptionDTO.prototype.getStatus = function() {
  return /** @type {!proto.stripe.EnumSubscriptionStatusDTO} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.stripe.EnumSubscriptionStatusDTO} value
 * @return {!proto.user.UserSubscriptionDTO} returns this
 */
proto.user.UserSubscriptionDTO.prototype.setStatus = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};


/**
 * repeated stripe.StripeProductDto products = 3;
 * @return {!Array<!proto.stripe.StripeProductDto>}
 */
proto.user.UserSubscriptionDTO.prototype.getProductsList = function() {
  return /** @type{!Array<!proto.stripe.StripeProductDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, stripe_pb.StripeProductDto, 3));
};


/**
 * @param {!Array<!proto.stripe.StripeProductDto>} value
 * @return {!proto.user.UserSubscriptionDTO} returns this
*/
proto.user.UserSubscriptionDTO.prototype.setProductsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.stripe.StripeProductDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stripe.StripeProductDto}
 */
proto.user.UserSubscriptionDTO.prototype.addProducts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.stripe.StripeProductDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.UserSubscriptionDTO} returns this
 */
proto.user.UserSubscriptionDTO.prototype.clearProductsList = function() {
  return this.setProductsList([]);
};


/**
 * repeated stripe.StripePriceDto price = 4;
 * @return {!Array<!proto.stripe.StripePriceDto>}
 */
proto.user.UserSubscriptionDTO.prototype.getPriceList = function() {
  return /** @type{!Array<!proto.stripe.StripePriceDto>} */ (
    jspb.Message.getRepeatedWrapperField(this, stripe_pb.StripePriceDto, 4));
};


/**
 * @param {!Array<!proto.stripe.StripePriceDto>} value
 * @return {!proto.user.UserSubscriptionDTO} returns this
*/
proto.user.UserSubscriptionDTO.prototype.setPriceList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 4, value);
};


/**
 * @param {!proto.stripe.StripePriceDto=} opt_value
 * @param {number=} opt_index
 * @return {!proto.stripe.StripePriceDto}
 */
proto.user.UserSubscriptionDTO.prototype.addPrice = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 4, opt_value, proto.stripe.StripePriceDto, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.UserSubscriptionDTO} returns this
 */
proto.user.UserSubscriptionDTO.prototype.clearPriceList = function() {
  return this.setPriceList([]);
};


/**
 * optional utils.TimestampDTO created_on = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.user.UserSubscriptionDTO.prototype.getCreatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.user.UserSubscriptionDTO} returns this
*/
proto.user.UserSubscriptionDTO.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserSubscriptionDTO} returns this
 */
proto.user.UserSubscriptionDTO.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserSubscriptionDTO.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO start_date = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.user.UserSubscriptionDTO.prototype.getStartDate = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.user.UserSubscriptionDTO} returns this
*/
proto.user.UserSubscriptionDTO.prototype.setStartDate = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserSubscriptionDTO} returns this
 */
proto.user.UserSubscriptionDTO.prototype.clearStartDate = function() {
  return this.setStartDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserSubscriptionDTO.prototype.hasStartDate = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional utils.TimestampDTO end_date = 11;
 * @return {?proto.utils.TimestampDTO}
 */
proto.user.UserSubscriptionDTO.prototype.getEndDate = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 11));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.user.UserSubscriptionDTO} returns this
*/
proto.user.UserSubscriptionDTO.prototype.setEndDate = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserSubscriptionDTO} returns this
 */
proto.user.UserSubscriptionDTO.prototype.clearEndDate = function() {
  return this.setEndDate(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserSubscriptionDTO.prototype.hasEndDate = function() {
  return jspb.Message.getField(this, 11) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.UserDTO.prototype.toObject = function(opt_includeInstance) {
  return proto.user.UserDTO.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.UserDTO} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserDTO.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && utils_pb.UUID_DTO.toObject(includeInstance, f),
    stripeCustomerId: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    givenName: jspb.Message.getFieldWithDefault(msg, 4, ""),
    lastName: jspb.Message.getFieldWithDefault(msg, 5, ""),
    email: jspb.Message.getFieldWithDefault(msg, 6, ""),
    picture: jspb.Message.getFieldWithDefault(msg, 7, ""),
    attr: (f = msg.getAttr()) && proto.user.UserAttributeDTO.toObject(includeInstance, f),
    createdOn: (f = msg.getCreatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    updatedOn: (f = msg.getUpdatedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    lastLogin: (f = msg.getLastLogin()) && utils_pb.TimestampDTO.toObject(includeInstance, f),
    archivedOn: (f = msg.getArchivedOn()) && utils_pb.TimestampDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.UserDTO}
 */
proto.user.UserDTO.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.UserDTO;
  return proto.user.UserDTO.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.UserDTO} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.UserDTO}
 */
proto.user.UserDTO.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setStripeCustomerId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setGivenName(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastName(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setPicture(value);
      break;
    case 8:
      var value = new proto.user.UserAttributeDTO;
      reader.readMessage(value,proto.user.UserAttributeDTO.deserializeBinaryFromReader);
      msg.setAttr(value);
      break;
    case 9:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setCreatedOn(value);
      break;
    case 10:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setUpdatedOn(value);
      break;
    case 11:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setLastLogin(value);
      break;
    case 12:
      var value = new utils_pb.TimestampDTO;
      reader.readMessage(value,utils_pb.TimestampDTO.deserializeBinaryFromReader);
      msg.setArchivedOn(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.UserDTO.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.UserDTO.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.UserDTO} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.UserDTO.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
  f = message.getStripeCustomerId();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getGivenName();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getLastName();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getPicture();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getAttr();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      proto.user.UserAttributeDTO.serializeBinaryToWriter
    );
  }
  f = message.getCreatedOn();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getUpdatedOn();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getLastLogin();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
  f = message.getArchivedOn();
  if (f != null) {
    writer.writeMessage(
      12,
      f,
      utils_pb.TimestampDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.user.UserDTO.prototype.getId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.user.UserDTO} returns this
*/
proto.user.UserDTO.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserDTO.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string stripe_customer_id = 2;
 * @return {string}
 */
proto.user.UserDTO.prototype.getStripeCustomerId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.setStripeCustomerId = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.user.UserDTO.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string given_name = 4;
 * @return {string}
 */
proto.user.UserDTO.prototype.getGivenName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.setGivenName = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string last_name = 5;
 * @return {string}
 */
proto.user.UserDTO.prototype.getLastName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.setLastName = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string email = 6;
 * @return {string}
 */
proto.user.UserDTO.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string picture = 7;
 * @return {string}
 */
proto.user.UserDTO.prototype.getPicture = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.setPicture = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional UserAttributeDTO attr = 8;
 * @return {?proto.user.UserAttributeDTO}
 */
proto.user.UserDTO.prototype.getAttr = function() {
  return /** @type{?proto.user.UserAttributeDTO} */ (
    jspb.Message.getWrapperField(this, proto.user.UserAttributeDTO, 8));
};


/**
 * @param {?proto.user.UserAttributeDTO|undefined} value
 * @return {!proto.user.UserDTO} returns this
*/
proto.user.UserDTO.prototype.setAttr = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.clearAttr = function() {
  return this.setAttr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserDTO.prototype.hasAttr = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional utils.TimestampDTO created_on = 9;
 * @return {?proto.utils.TimestampDTO}
 */
proto.user.UserDTO.prototype.getCreatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 9));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.user.UserDTO} returns this
*/
proto.user.UserDTO.prototype.setCreatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.clearCreatedOn = function() {
  return this.setCreatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserDTO.prototype.hasCreatedOn = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional utils.TimestampDTO updated_on = 10;
 * @return {?proto.utils.TimestampDTO}
 */
proto.user.UserDTO.prototype.getUpdatedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 10));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.user.UserDTO} returns this
*/
proto.user.UserDTO.prototype.setUpdatedOn = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.clearUpdatedOn = function() {
  return this.setUpdatedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserDTO.prototype.hasUpdatedOn = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional utils.TimestampDTO last_login = 11;
 * @return {?proto.utils.TimestampDTO}
 */
proto.user.UserDTO.prototype.getLastLogin = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 11));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.user.UserDTO} returns this
*/
proto.user.UserDTO.prototype.setLastLogin = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.clearLastLogin = function() {
  return this.setLastLogin(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserDTO.prototype.hasLastLogin = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * optional utils.TimestampDTO archived_on = 12;
 * @return {?proto.utils.TimestampDTO}
 */
proto.user.UserDTO.prototype.getArchivedOn = function() {
  return /** @type{?proto.utils.TimestampDTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.TimestampDTO, 12));
};


/**
 * @param {?proto.utils.TimestampDTO|undefined} value
 * @return {!proto.user.UserDTO} returns this
*/
proto.user.UserDTO.prototype.setArchivedOn = function(value) {
  return jspb.Message.setWrapperField(this, 12, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.UserDTO} returns this
 */
proto.user.UserDTO.prototype.clearArchivedOn = function() {
  return this.setArchivedOn(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.UserDTO.prototype.hasArchivedOn = function() {
  return jspb.Message.getField(this, 12) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetMeRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetMeRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetMeRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetMeRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetMeRequest}
 */
proto.user.GetMeRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetMeRequest;
  return proto.user.GetMeRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetMeRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetMeRequest}
 */
proto.user.GetMeRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetMeRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetMeRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetMeRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetMeRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetAttributesRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetAttributesRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetAttributesRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAttributesRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetAttributesRequest}
 */
proto.user.GetAttributesRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetAttributesRequest;
  return proto.user.GetAttributesRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetAttributesRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetAttributesRequest}
 */
proto.user.GetAttributesRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetAttributesRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetAttributesRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetAttributesRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetAttributesRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.user.GetMeResponse.repeatedFields_ = [3];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetMeResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetMeResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetMeResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetMeResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.user.UserDTO.toObject(includeInstance, f),
    attr: (f = msg.getAttr()) && proto.user.UserAttributeDTO.toObject(includeInstance, f),
    subscriptionList: jspb.Message.toObjectList(msg.getSubscriptionList(),
    proto.user.UserSubscriptionDTO.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetMeResponse}
 */
proto.user.GetMeResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetMeResponse;
  return proto.user.GetMeResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetMeResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetMeResponse}
 */
proto.user.GetMeResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.UserDTO;
      reader.readMessage(value,proto.user.UserDTO.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    case 2:
      var value = new proto.user.UserAttributeDTO;
      reader.readMessage(value,proto.user.UserAttributeDTO.deserializeBinaryFromReader);
      msg.setAttr(value);
      break;
    case 3:
      var value = new proto.user.UserSubscriptionDTO;
      reader.readMessage(value,proto.user.UserSubscriptionDTO.deserializeBinaryFromReader);
      msg.addSubscription(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetMeResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetMeResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetMeResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetMeResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.user.UserDTO.serializeBinaryToWriter
    );
  }
  f = message.getAttr();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.user.UserAttributeDTO.serializeBinaryToWriter
    );
  }
  f = message.getSubscriptionList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      3,
      f,
      proto.user.UserSubscriptionDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserDTO user = 1;
 * @return {?proto.user.UserDTO}
 */
proto.user.GetMeResponse.prototype.getUser = function() {
  return /** @type{?proto.user.UserDTO} */ (
    jspb.Message.getWrapperField(this, proto.user.UserDTO, 1));
};


/**
 * @param {?proto.user.UserDTO|undefined} value
 * @return {!proto.user.GetMeResponse} returns this
*/
proto.user.GetMeResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetMeResponse} returns this
 */
proto.user.GetMeResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetMeResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional UserAttributeDTO attr = 2;
 * @return {?proto.user.UserAttributeDTO}
 */
proto.user.GetMeResponse.prototype.getAttr = function() {
  return /** @type{?proto.user.UserAttributeDTO} */ (
    jspb.Message.getWrapperField(this, proto.user.UserAttributeDTO, 2));
};


/**
 * @param {?proto.user.UserAttributeDTO|undefined} value
 * @return {!proto.user.GetMeResponse} returns this
*/
proto.user.GetMeResponse.prototype.setAttr = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetMeResponse} returns this
 */
proto.user.GetMeResponse.prototype.clearAttr = function() {
  return this.setAttr(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetMeResponse.prototype.hasAttr = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * repeated UserSubscriptionDTO subscription = 3;
 * @return {!Array<!proto.user.UserSubscriptionDTO>}
 */
proto.user.GetMeResponse.prototype.getSubscriptionList = function() {
  return /** @type{!Array<!proto.user.UserSubscriptionDTO>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.user.UserSubscriptionDTO, 3));
};


/**
 * @param {!Array<!proto.user.UserSubscriptionDTO>} value
 * @return {!proto.user.GetMeResponse} returns this
*/
proto.user.GetMeResponse.prototype.setSubscriptionList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 3, value);
};


/**
 * @param {!proto.user.UserSubscriptionDTO=} opt_value
 * @param {number=} opt_index
 * @return {!proto.user.UserSubscriptionDTO}
 */
proto.user.GetMeResponse.prototype.addSubscription = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 3, opt_value, proto.user.UserSubscriptionDTO, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.user.GetMeResponse} returns this
 */
proto.user.GetMeResponse.prototype.clearSubscriptionList = function() {
  return this.setSubscriptionList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetUserRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetUserRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetUserRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    userId: (f = msg.getUserId()) && utils_pb.UUID_DTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetUserRequest}
 */
proto.user.GetUserRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetUserRequest;
  return proto.user.GetUserRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetUserRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetUserRequest}
 */
proto.user.GetUserRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new utils_pb.UUID_DTO;
      reader.readMessage(value,utils_pb.UUID_DTO.deserializeBinaryFromReader);
      msg.setUserId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetUserRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetUserRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetUserRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUserId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      utils_pb.UUID_DTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional utils.UUID_DTO user_id = 1;
 * @return {?proto.utils.UUID_DTO}
 */
proto.user.GetUserRequest.prototype.getUserId = function() {
  return /** @type{?proto.utils.UUID_DTO} */ (
    jspb.Message.getWrapperField(this, utils_pb.UUID_DTO, 1));
};


/**
 * @param {?proto.utils.UUID_DTO|undefined} value
 * @return {!proto.user.GetUserRequest} returns this
*/
proto.user.GetUserRequest.prototype.setUserId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetUserRequest} returns this
 */
proto.user.GetUserRequest.prototype.clearUserId = function() {
  return this.setUserId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetUserRequest.prototype.hasUserId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.user.GetUserResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.user.GetUserResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.user.GetUserResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    user: (f = msg.getUser()) && proto.user.UserDTO.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.user.GetUserResponse}
 */
proto.user.GetUserResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.user.GetUserResponse;
  return proto.user.GetUserResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.user.GetUserResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.user.GetUserResponse}
 */
proto.user.GetUserResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.user.UserDTO;
      reader.readMessage(value,proto.user.UserDTO.deserializeBinaryFromReader);
      msg.setUser(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.user.GetUserResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.user.GetUserResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.user.GetUserResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.user.GetUserResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUser();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.user.UserDTO.serializeBinaryToWriter
    );
  }
};


/**
 * optional UserDTO user = 1;
 * @return {?proto.user.UserDTO}
 */
proto.user.GetUserResponse.prototype.getUser = function() {
  return /** @type{?proto.user.UserDTO} */ (
    jspb.Message.getWrapperField(this, proto.user.UserDTO, 1));
};


/**
 * @param {?proto.user.UserDTO|undefined} value
 * @return {!proto.user.GetUserResponse} returns this
*/
proto.user.GetUserResponse.prototype.setUser = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.user.GetUserResponse} returns this
 */
proto.user.GetUserResponse.prototype.clearUser = function() {
  return this.setUser(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.user.GetUserResponse.prototype.hasUser = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.user);
