import {convertSubscriptionProductToString, IUser, User, UserAttributes} from "model/user";
import {AuthObj} from "model/auth";
import {ActionType, NewUIErrorV2} from "service/cartaError";
import {EntityKind} from "model/BaseModel";

import {
    LOCAL_STORAGE_ENTITLEMENTS,
    LOCAL_STORAGE_LAST_HEARTBEAT_TIME,
    LOCAL_STORAGE_PRICING_SESSION,
    LOCAL_STORAGE_SIGNUP_PRICING_SESSION,
    LOCAL_STORAGE_SUBSCRIPTION_PRODUCT,
    LOCAL_STORAGE_USER,
    LOCAL_STORAGE_USER_ID
} from "consts";
import {EnumSubscriptionProductDTO} from "proto/stripe_pb";

export function getUser(): User {
  const user = localStorage.getItem(LOCAL_STORAGE_USER);

  if (user == null) {
      throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "User not found in local storage")
  }

  const res: IUser = JSON.parse(user);

  let u = new User();
  u.fromJSON(res)

  return u
}

export function getSubscriptionProduct(): EnumSubscriptionProductDTO  {
    const product = localStorage.getItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT);

    if (product == null) {
        throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "Subscription product not found in local storage")
    }

    const num: number = parseInt(product);

    return num as EnumSubscriptionProductDTO;
}

export function storeSubscriptionProduct(product: EnumSubscriptionProductDTO) {
    localStorage.setItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT, product.toString());
}

export function setUser(user: User) {
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(user));
}

export function getUserId(): string {
  const id = localStorage.getItem(LOCAL_STORAGE_USER_ID);

  if (id == null) {
      throw NewUIErrorV2(ActionType.Authenticate, EntityKind.User, "User ID not found in local storage")
  }

  return id;
}

export function storeAuthObjInLocalStorage(authObj: AuthObj) {
    localStorage.setItem(LOCAL_STORAGE_USER_ID, authObj.userId);
    localStorage.setItem(LOCAL_STORAGE_USER, JSON.stringify(authObj.user));
}

export function removeAuthInfoFromLocalStorage() {
    localStorage.removeItem(LOCAL_STORAGE_USER_ID);
    localStorage.removeItem(LOCAL_STORAGE_USER);
    localStorage.removeItem(LOCAL_STORAGE_PRICING_SESSION);
    localStorage.removeItem(LOCAL_STORAGE_SIGNUP_PRICING_SESSION);
    localStorage.removeItem(LOCAL_STORAGE_ENTITLEMENTS);
    localStorage.removeItem(LOCAL_STORAGE_SUBSCRIPTION_PRODUCT);
}

export function storeHeartBeatTimeInLocalStorage(time: Date) {
    localStorage.setItem(LOCAL_STORAGE_LAST_HEARTBEAT_TIME, time.toISOString());
}

export function getLastHeartBeatTimeFromLocalStorage(): Date | null {
    const time = localStorage.getItem(LOCAL_STORAGE_LAST_HEARTBEAT_TIME);

    if (time == null) {
        return null;
    }

    return new Date(time);
}

export function retrieveUserFromLocalStorage(): User | null {
    const user = localStorage.getItem(LOCAL_STORAGE_USER);

    if (user == null) {
        return null;
    }

    return JSON.parse(user);
}