import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';

export const PostStripeSubscriptionSession = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(location.search);
    const success = searchParams.get('success') === 'true';

    const handleClose = () => {
        navigate('/login');
    };

    return (
        <>
            <Dialog open={true} onClose={handleClose}>
                <DialogTitle>{success ? "Congratulations!" : "Something went wrong"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {success
                            ? "You've signed up successfully! Login to access the app."
                            : "There was an issue while setting up your subscription. Please try Login/Sign Up again."
                        }
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
